/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect } from "react";
import { useLeadSourceDeleteMutation } from "../context/leadSources.api";
import { IBaseError } from "corede-common"
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseLeadSourceEntity } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";

const Delete = (prop: {
  open: boolean;
  item: IBaseLeadSourceEntity;
  onClose: () => void;
}) => {

  const { t } = useTranslation();
  // general
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [leadSourceDelete, { data: leadSourceDeleteData, isLoading: leadSourceDeleteLoading, error: leadSourceDeleteError }] = useLeadSourceDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    leadSourceDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (leadSourceDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.lead.leadSources.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [leadSourceDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadSourceDeleteError as IBaseError,
      currentLanguage
    );
  }, [leadSourceDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("crm.lead.leadSources.delete")}</DialogTitle>
      <DialogContent>
        {t("crm.lead.leadSources.deleteConfirm")} {prop?.item?.name}?
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={leadSourceDeleteLoading}
          onClick={() => {
            handleDelete(prop?.item?._id);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default Delete;
