import { Grid, Typography, } from "@mui/material";




const List = () => {

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">Departments</Typography>
      </Grid>
    </Grid>
  )
};

export default List;


