


export const demoDomainRoutes = {
  base: "demo",
  subdomains: {
    demo: {
      base: "demo",
    },
    // Todo
    // employees: {
    //   base: "employees",
    // },
  },
};


export const baseDemoRoute = (): string => `/${demoDomainRoutes.base}/`;
export const listEmployeesRoute = (): string =>
  `/${demoDomainRoutes.base}/${demoDomainRoutes.subdomains.demo.base}`;
// Todo
// export const listEmployeesRoute = (): string =>
//   `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.employees.base}`;


