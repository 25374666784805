import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from 'react-redux'
import { store } from "context";
import Wrapper from "Wrapper";
// import { logCurrentLanguage } from "localization";

// logCurrentLanguage();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Provider store={store}>
  <Wrapper />
</Provider>);
