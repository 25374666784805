/* eslint-disable react-hooks/exhaustive-deps */
import {
    Grid,
    Stack,
    Skeleton,
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Button,
    TableContainer,
    useMediaQuery,
    CircularProgress,
    SwipeableDrawer,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    usePermissionListQuery, useRoleListQuery, useRoleCreateMutation, useRoleUpdateMutation, useRoleDeleteMutation
} from "apps/crm/domains/02-organizationalChart/subdomains/employees/context/employees.api";
import { MyTab } from "components";
import { AddRounded, DeleteOutlineRounded, SettingsSuggest, Update } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { rolesEmployeesRoute } from "apps/crm/domains/02-organizationalChart/routes/organizationalChart.base.route";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IRolesDrawer {
    open: boolean
    setOpen: (open: boolean) => void
}

interface Permission {
    _id: string;
    action: string;
    subject: string;
    actionScope: string;
    description: string;
}

const groupBySubject = (permissions: Permission[]) => {
    return permissions.reduce((acc, permission) => {
        if (!acc[permission.subject]) {
            acc[permission.subject] = [];
        }
        acc[permission.subject].push(permission);
        return acc;
    }, {} as Record<string, Permission[]>);
};

export const RolesDrawer = (props: IRolesDrawer) => {
    // general
    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [tabKey, setTabKey] = useState(0);
    const [selectedRoleName, setSelectedRoleName] = useState("");
    const [selectedRoleId, setSelectedRoleId] = useState("");
    const [roleName, setRoleName] = useState<string>("");
    const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
    const { data: permissionListData, isLoading: permissionListLoading, error: permissionListError } = usePermissionListQuery({});
    const { data: roleListData, isLoading: roleListLoading, error: roleListError } = useRoleListQuery({});
    const [roleCreate, { data: roleCreateData, isLoading: roleCreateLoading, error: roleCreateError }] = useRoleCreateMutation();
    const [roleUpdate, { data: roleUpdateData, isLoading: roleUpdateLoading, error: roleUpdateError }] = useRoleUpdateMutation();
    const [roleDelete, { data: roleDeleteData, isLoading: roleDeleteLoading, error: roleDeleteError }] = useRoleDeleteMutation();

    const roles = roleListData?.data || [];
    const permissions = permissionListData?.data || [];
    const groupedPermissions = groupBySubject(permissions as Permission[]);

    const handleAddRole = () => {
        roleCreate({
            input: {
                name: roleName,
                permissionIds: selectedPermissionIds
            }
        });
    }

    const handleRoleUpdate = () => {
        roleUpdate({
            input: {
                name: roleName,
                permissionIds: selectedPermissionIds
            },
            filter: {
                _id: selectedRoleId
            },
        })
    }

    const handleRoleDelete = () => {
        roleDelete({
            input: {
                _id: selectedRoleId
            },
        })
    }

    // SUCCESS

    useEffect(() => {
        if (roleCreateData) {
            enqueueSnackbar(t("crm.organizationalChart.employees.roleCreatedSuccess"), { variant: "success" });
            setRoleName(roles[0]?.name ?? "");
            setTabKey(prevKey => prevKey + 1);
        }
    }, [roleCreateData]);

    useEffect(() => {
        if (roleUpdateData) {
            enqueueSnackbar(t("crm.organizationalChart.employees.roleUpdatedSuccess"), { variant: "success" });
            setRoleName(roles[0]?.name ?? "");
            setTabKey(prevKey => prevKey + 1);
        }
    }, [roleUpdateData]);

    useEffect(() => {
        if (roleDeleteData) {
            enqueueSnackbar(t("crm.organizationalChart.employees.roleDeletedSuccess"), { variant: "success" });
            setRoleName(roles[0]?.name ?? "");
            setTabKey(prevKey => prevKey + 1);
        }
    }, [roleDeleteData]);

    // ERRORS

    useEffect(() => {
        if (permissionListError) {
            enqueueSnackbar(t("crm.organizationalChart.employees.errorFetchingPermissions"), { variant: "error" });
        }
    }, [permissionListError]);

    useEffect(() => {
        if (roleListError) {
            enqueueSnackbar(t("crm.organizationalChart.employees.errorFetchingRoles"), { variant: "error" });
        }
    }, [roleListError]);

    useEffect(() => {
        if (roleCreateError) {
            enqueueSnackbar(t("crm.organizationalChart.employees.errorCreatingRole"), { variant: "error" });
        }
    }, [roleCreateError]);

    useEffect(() => {
        if (roleUpdateError) {
            enqueueSnackbar(t("crm.organizationalChart.employees.errorUpdatingRole"), { variant: "error" });
        }
    }, [roleUpdateError]);

    useEffect(() => {
        if (roleDeleteError) {
            enqueueSnackbar(t("crm.organizationalChart.employees.errorDeletingRole"), { variant: "error" });
        }
    }, [roleDeleteError]);

    // INITIAL

    useEffect(() => {
        if (selectedRoleName) {
            setRoleName(selectedRoleName === t("crm.organizationalChart.employees.addRole") ? "" : selectedRoleName);
            setSelectedRoleId(roles.find((role) => role.name === selectedRoleName)?._id ?? "");
            setSelectedPermissionIds(roles.find((role) => role.name === selectedRoleName)?.permissions.map((permission) => permission._id) ?? []);
        }
    }, [selectedRoleName, roles]);

    useEffect(() => {
        setSelectedRoleName(roles[0]?.name ?? "");
    }, [roles]);

    return (
        <SwipeableDrawer
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            anchor='right'
        >
            <Grid item xs={12} p={3}>
                <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h4" gutterBottom>
                        {t("crm.organizationalChart.employees.roles")}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ height: 42, mb: "8px", pl: 2, pr: 2 }}
                        onClick={() => navigate(rolesEmployeesRoute())}
                        disabled={roleDeleteLoading}
                    >
                        {roleDeleteLoading ? <CircularProgress size={"1rem"} /> :
                            <>
                                {t("crm.organizationalChart.employees.advancedOptions")} <SettingsSuggest sx={{ ml: 1 }} />
                            </>
                        }
                    </Button>
                </Stack>
                <Box sx={{ mt: 4, width: "520px" }} />
                <MyTab key={tabKey} labels={[...roles, { name: t("crm.organizationalChart.employees.addRole") }].map((role) => role.name)} setSelectedTab={setSelectedRoleName} />
                <Stack direction="row" justifyContent="space-between">
                    {!(roleListLoading || permissionListLoading || roleCreateLoading || roleUpdateLoading || roleDeleteLoading) ?
                        <Box sx={{ width: "100%" }}>
                            <TableContainer
                                sx={{
                                    borderRadius: 2,
                                    mt: 1
                                }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{
                                                borderRight: "1px solid",
                                                borderColor: "primary.light",
                                            }}>{t("crm.organizationalChart.employees.subject")}</TableCell>
                                            <TableCell>{t("crm.organizationalChart.employees.capabilities")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(groupedPermissions).map((subject) => {
                                            const allSelected = groupedPermissions[subject].every((permission) =>
                                                selectedPermissionIds.includes(permission._id)
                                            );
                                            const someSelected = groupedPermissions[subject].some((permission) =>
                                                selectedPermissionIds.includes(permission._id)
                                            );
                                            return (
                                                <TableRow key={subject}>
                                                    <TableCell sx={{
                                                        borderRight: "1px solid",
                                                        borderColor: "primary.light",
                                                    }}>{subject === 'all' ? t("crm.organizationalChart.employees.allPermissions") : subject}</TableCell>
                                                    <TableCell>
                                                        <Box display="flex" alignItems="center">
                                                            <Checkbox
                                                                size="small"
                                                                checked={allSelected}
                                                                indeterminate={someSelected && !allSelected}
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;
                                                                    const subjectPermissions = groupedPermissions[subject].map(
                                                                        (permission) => permission._id
                                                                    );
                                                                    if (isChecked) {
                                                                        setSelectedPermissionIds((prevSelectedIds) => [
                                                                            ...prevSelectedIds,
                                                                            ...subjectPermissions.filter((id) => !prevSelectedIds.includes(id)),
                                                                        ]);
                                                                    } else {
                                                                        setSelectedPermissionIds((prevSelectedIds) =>
                                                                            prevSelectedIds.filter((id) => !subjectPermissions.includes(id))
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            <Typography>{t("crm.organizationalChart.employees.all")}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <TextField
                                    required
                                    size="small"
                                    placeholder={t("crm.organizationalChart.employees.roleName")}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={roleName}
                                    onChange={(e) => setRoleName(e.target.value)}
                                />
                                {(selectedRoleName === t("crm.organizationalChart.employees.addRole") || selectedRoleName === "") && <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    sx={{ height: 42, mt: "16px", mb: "8px", pl: 1, pr: 2 }}
                                    onClick={handleAddRole}
                                    disabled={roleCreateLoading || !roleName || selectedPermissionIds.length === 0}
                                >
                                    {roleCreateLoading ? <CircularProgress size={"1rem"} /> :
                                        <>
                                            <AddRounded sx={{ mr: 1 }} />{t("crm.organizationalChart.employees.addRole")}
                                        </>
                                    }
                                </Button>}
                                {selectedRoleName !== t("crm.organizationalChart.employees.addRole") && selectedRoleName !== "" && <Stack direction={"row"} gap={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        sx={{ height: 42, mt: "16px", mb: "8px", pl: 2, pr: 2 }}
                                        onClick={handleRoleUpdate}
                                        disabled={roleUpdateLoading || !roleName || selectedPermissionIds.length === 0}
                                    >
                                        {roleCreateLoading ? <CircularProgress size={"1rem"} /> :
                                            <>
                                                <Update sx={{ mr: { xs: 0, md: 1 } }} />{!downMd && t("crm.organizationalChart.employees.updateRole")}
                                            </>
                                        }
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        sx={{ height: 42, mt: "16px", mb: "8px", pl: 2, pr: 2 }}
                                        onClick={handleRoleDelete}
                                        disabled={roleDeleteLoading}
                                    >
                                        {roleDeleteLoading ? <CircularProgress size={"1rem"} /> :
                                            <>
                                                <DeleteOutlineRounded sx={{ mr: { xs: 0, md: 1 } }} />{!downMd && t("crm.organizationalChart.employees.deleteRole")}
                                            </>
                                        }
                                    </Button>
                                </Stack>}
                            </Stack>
                        </Box>
                        : <TableContainer
                            sx={{
                                borderRadius: 2,
                                mt: 1
                            }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{
                                            borderRight: "1px solid",
                                            borderColor: "primary.light",
                                            width: '50%'
                                        }}>{t("crm.organizationalChart.employees.subject")}</TableCell>
                                        <TableCell sx={{ width: '50%' }}>{t("crm.organizationalChart.employees.capabilities")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[1, 2, 3, 4, 5].map((key) => (
                                        <Stack
                                            key={key}
                                            direction="column"
                                            justifyContent="space-between"
                                            width={340}
                                            alignItems={"center"}
                                            p={2}
                                        >
                                            <Skeleton
                                                variant="rectangular"
                                                width={340}
                                                height={30}
                                                sx={{ borderRadius: 2 }}
                                            />
                                        </Stack>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Stack>

                <Stack
                    direction="row"
                    justifyContent={{ xs: "center", md: "flex-start" }}
                    mt={2}
                    mb={2}
                >

                </Stack>

            </Grid >
        </SwipeableDrawer>
    );
}
