
export const customerDomainRoutes = {
  base: "customer",
  subdomains: {
    customers: {
      base: "customers",
    },
    contacts: {
      base: "contacts"
    },
    customerCategories: {
      base: "customer-categories"
    }
  },
};

export const baseCustomerRoute = (): string => `/${customerDomainRoutes.base}/`;
export const listCustomersRoute = (): string => `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customers.base}`
export const listContactsRoute = (): string => `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.contacts.base}`
export const listCustomerCategoriesRoute = (): string => `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customerCategories.base}`