

import { ProductsAndServicesDomainLayout } from "../layouts";
import { ProductsRouter } from "../subdomains/products/routes/ProductsRouter";
import { ServicesRouter } from "../subdomains/services/routes/ServicesRouter";
import { productsAndServicesDomainRoutes } from "./productsAndServices.base.route";
import { Route, Routes } from "react-router-dom";

export const ProductsAndServicesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductsAndServicesDomainLayout />}>
        <Route index element={<ProductsRouter />} />
        <Route
          path={`${productsAndServicesDomainRoutes.subdomains.products.base}/*`}
          element={<ProductsRouter />}
        />
        <Route
          path={`${productsAndServicesDomainRoutes.subdomains.services.base}/*`}
          element={<ServicesRouter />}
        />
        {/* Todo 
         <Route index element={<EmployeesRouter />} />
         <Route
          path={`${customerDomainRoutes.subdomains.employees.base}/*`}
          element={<EmployeesRouter />}
        /> */}
      </Route>
    </Routes>
  );
};
