

const locale = {
  somethingWentWrong: "Something went wrong!",
  loginSuccess: "Login Successfully",
  signinText: "Let's sign in.",
  emailAddress: "Email address",
  password: "Password",
  forgotPassword: "Forgot password?",
  login: "Login",
  continueWith: "or continue with",
  noAccount: "Don't have an account?",
  signUp: "Sign up",
  logoAlt: "logo",
  growYourBusiness: "All You Need to Grow Your",
  business: "Business",
  manageYourBusiness: "Manage your business efficiently, increase your sales, reach potential customers, and empower yourself by joining the community.",
  bgPattern: "background pattern",
  johnDoe: "John Doe",
  ceoCompany: "CEO, Company Name",
  passwordResetSuccess: "Your password has been reset successfully!",
  resetPasswordTitle: "Reset Password",
  enterNewPassword: "Please enter your new password.",
  passwordAgain: "Password Again",
  resetPassword: "Reset Password",
  alreadyHaveAccount: "Already have an account?",
  resetPasswordRequestSuccess: "Request Reset Password Successfully",
  forgotPasswordTitle: "Forgot Password",
  resetPasswordInstructions: "You can reset your password by entering your email address.",
  rememberPassword: "Remember your password?",
  userConfirmedSuccess: "User confirmed successfully!",
  emailConfirmationTitle: "Email Confirmation",
  enterOtp: "Please enter the OTP sent to your email.",
  submit: "Submit",
  didNotReceiveCode: "Didn't receive the code?",
  resendCode: "Resend code",
  resendCodeTitle: "Resend Confirmation Code",
  enterEmailToResend: "Please enter your email address to receive the confirmation code.",
  email: "Email",
  close: "Close",
  send: "Send",
  signupSuccess: "Signup Successfully",
  letsRegister: "Let's register you in.",
  name: "Name",
  surname: "Surname",
  iAccept: "I accept the",
  termsAndConditions: "terms and conditions",
  orContinueWith: "or continue with",
  createPassword: "Create Password",
  createPasswordTitle: "Create Password",
};


export default locale;
