
import dashboardLocale from "../domains/01-dashboard/locales/en";
import organizationalChartLocale from "../domains/02-organizationalChart/locales/en";
import leadLocale from "../domains/03-lead/locales/en";

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  contractss: "Contracts",
  dashboardd: "Dashboard",
  leadd: "Lead",
  customerr: "Customer",
  customer: "Customer",
  customers: "Customers",
  customerGroups: "Customer Groups",
  customerSegments: "Customer Segments",
  customerTags: "Customer Tags",
  marketing: "Marketing",
  campaigns: "Campaigns",
  emails: "Emails",
  sms: "SMS",
  socialMedia: "Social Media",
  preSales: "Pre-Sales",
  proposals: "Proposals",
  estimates: "Estimates",
  sales: "Sales",
  invoices: "Invoices",
  payments: "Payments",
  contracts: "Contracts",
  productsServices: "Products & Services",
  expenses: "Expenses",
  projects: "Projects",
  tasks: "Tasks",
  businessIntelligence: "Business Intelligence",
  calendar: "Calendar",
  reports: "Reports",
  support: "Support",
  accountMenu: "Account Menu",
  profile: "Profile",
  myAccount: "My Account",
  darkMode: "Dark Mode",
  lightMode: "Light Mode",
  languageSelectLabel: "Select Language",
  english: "English",
  turkish: "Turkish",
  settings: "Settings",
  logout: "Logout",
  searchPlaceholder: 'Try searching "insights"',
  mustCreateOrganizationTitle: "Create Organization",
  mustCreateOrganizationContent: "You must create an organization to use the panel.",
  mustCreateOrganizationSuccess: "Organization created successfully.",
  name: "Name",
  cancel: "Cancel",
  create: "Create",
  companyName: "Company Name",
  companyEmail: "Company Email",
  companyPhone: "Company Phone",
  country: "Country",
  city: "City",
  industry: "Industry",
  timezone: "Timezone",
  language: "Language",
  organizationalChartt: "Organizational Chart",
  preSaless: "Pre-Sales",
  saless: "Sales",
  expensess: "Expenses",
  productsAndServicess: "Products & Services",
  projectss: "Projects",
  taskss: "Tasks",
  calendarr: "Calendar",
  reportss: "Reports",
  supportt: "Support (Ticket)",
  companyLogo: "Company Logo",
  uploadLogo: "Upload Logo",
};

export default locale;
