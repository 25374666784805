import { Route, Routes } from "react-router-dom";

import { Import, List, View } from "../pages";

import { LeadLayout } from "../layouts/LeadLayout";
import { commonRoutes } from "routes/route.common";

export const LeadRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LeadLayout />}>
        <Route index element={<List />} />
        <Route path={`/${commonRoutes.import}`} element={<Import />} />
        <Route path="/:id" element={<View />} />
      </Route>
    </Routes>
  );
};

