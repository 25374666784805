/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import { useAppDispatch } from "context";
import { organizationalChartActions } from "../../../context";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { listEmployeesRoute } from "../../../routes/organizationalChart.base.route";

const Update = () => {
  
  // general
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listEmployeesRoute());
  }

  // queries

  // mutations
  
  // constants


  // useEffects.success



  // useEffects.error


  // useEffects.init
  useEffect(() => {
    dispatch(organizationalChartActions.setTitle(t("crm.organizationalChart.employees.update")));
    dispatch(organizationalChartActions.setBackButton(true));
    dispatch(organizationalChartActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {true ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            {/*
              // TODO: add edit views for update fields
            */}

      
          </Stack>
        )}      
      </Stack>
    </Grid>
  );
};

export default Update;
