import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseSalesRoute,
  listInvoicesRoute,
  listPaymentsRoute,
  listReturnsRoute
} from "../routes/sales.base.route";

export const useSalesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("sales"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseSalesRoute(),
    children: [
      {
        title: t("invoices"),
        path: listInvoicesRoute(),
      },
      {
        title: t("payments"),
        path: listPaymentsRoute(),
      },
      {
        title: t("returns"),
        path: listReturnsRoute(),
      },
      // Todo
      // {
      //   title: t("employees"),
      //   path: listEmployeesRoute(),
      // },
    ],
  };
};
