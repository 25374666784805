/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, CircularProgress, Grid, InputLabel, Stack, SwipeableDrawer, Switch, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { leadActions } from "../../../context";
import { useEffect, useState } from "react";
import { convertEnums, getCurrentLanguage } from "localization";
import { useTranslation } from "react-i18next";
import { IGraphqlVariables, Language, unknownError } from "corede-common";
import { ILeadCreateInput, LeadQualification, LeadType } from "corede-common-cocrm";
import { validateCreateLeadInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { useLeadCreateMutation } from "../context/lead.api";
import { useLeadStatusesQuery } from "../../leadStatuses/context/leadStatuses.api";
import { useLeadSourcesQuery } from "../../leadSources/context/leadSources.api";
import { enqueueSnackbar } from "notistack";
import { useUserListQuery } from "apps/auth/context";
import ReactQuill from "react-quill-new";
import 'react-quill/dist/quill.snow.css';
import { LeadStatusCreateDrawer } from "../../leadStatuses/pages";
import { LeadSourceCreateDrawer } from "../../leadSources/pages/LeadSourcesCreateDrawer";

interface ILeadCreateDrawer {
  open: any
  setOpen: any
}

export const LeadCreateDrawer = (props: ILeadCreateDrawer) => {
  // general
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);
  const localizedLeadTypes = convertEnums(LeadType);
  const localizedLeadQualifications = convertEnums(LeadQualification);
  const localizedLanguages = convertEnums(Language);
  const [leadStatusCreateOpen, setLeadStatusCreateOpen] = useState(false);
  const [leadSourceCreateOpen, setLeadSourceCreateOpen] = useState(false);

  // queries
  const { data: leadStatusesData, isLoading: leadStatusesLoading, error: leadStatusesError } = useLeadStatusesQuery({});
  const { data: leadSourcesData, isLoading: leadSourcesLoading, error: leadSourcesError } = useLeadSourcesQuery({});
  const { data: userListData, isLoading: userListLoading, error: userListError } = useUserListQuery({});

  // mutations
  const [leadCreate, { data: leadCreateData, isLoading: leadCreateLoading, error: leadCreateError }] = useLeadCreateMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
    useFormik({
      initialValues: {
        input: {
          fullName: "",
          statusId: "",
          sourceId: "",
          tags: [],
          assignedIds: undefined,
          position: "",
          email: "",
          website: undefined,
          phoneNumber: undefined,
          company: "",
          sector: "",
          type: LeadType.neutral,
          qualification: LeadQualification.unqualified,
          country: "",
          state: "",
          city: "",
          isPublic: undefined,
          isJunk: false,
          note: undefined,
          language: Language.en
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateLeadInput,
      onSubmit: async (values: IGraphqlVariables<ILeadCreateInput>) => {
        setLoading(true);
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values,
          });
        await leadCreate(transformedValues as IGraphqlVariables<ILeadCreateInput>)
        setLoading(false);
      }
    });


  // useEffects.success

  useEffect(() => {
    if (leadCreateData) {
      console.log("leadCreateData", leadCreateData);
      enqueueSnackbar(t("crm.organizationalChart.employees.createUserSuccess"), { variant: 'success' });
      resetForm();
      props.setOpen(false);
    }
  }, [leadCreateData, resetForm]);


  // useEffects.error

  useEffect(() => {
    if (leadCreateError) {
      enqueueSnackbar((leadCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [leadCreateError]);

  useEffect(() => {
    if (leadStatusesError) {
      enqueueSnackbar((leadStatusesError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [leadStatusesError]);

  useEffect(() => {
    if (leadSourcesError) {
      enqueueSnackbar((leadSourcesError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [leadSourcesError]);

  useEffect(() => {
    if (userListError) {
      enqueueSnackbar((userListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [userListError]);


  // useEffects.init
  useEffect(() => {
    dispatch(leadActions.setTitle(t("crm.lead.lead.create")));
    dispatch(leadActions.setBackButton(true));
    dispatch(leadActions.setRightButton(null));
  }, [dispatch]);

  return <SwipeableDrawer
    open={props.open}
    onOpen={() => props.setOpen(true)}
    onClose={() => props.setOpen(false)}
    anchor='right'
  >
    <Grid item xs={12} p={3} minWidth={600}>
      <Typography variant="h4" gutterBottom>
        {t("crm.lead.lead.create")}
      </Typography>
      <Stack direction="column" gap={1} mt={3} >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.fullName")}*</InputLabel>
            <TextField
              fullWidth
              name="input.fullName"
              value={(values as any).input.fullName}
              onChange={(e) => setFieldValue("input.fullName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.fullName && (touched as any)?.input?.fullName)}
              helperText={(touched as any)?.input?.fullName && (errors as any)?.input?.fullName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.status")}*</InputLabel>
            <Autocomplete
              id="statusId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              getOptionLabel={(option) => option.name}
              options={[...(leadStatusesData?.data || []), { _id: "add", name: "Add new status" }]}
              loading={leadStatusesLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={handleBlur}
                  error={!!((errors as any)?.input?.statusId && (touched as any)?.input?.statusId)}
                  helperText={(touched as any)?.input?.statusId && (errors as any)?.input?.statusId}
                />
              )}
              value={leadStatusesData?.data?.find((status) => status._id === (values as any).input.statusId) || null}
              onChange={(e, value) => {
                if (value?._id === "add") {
                  setLeadStatusCreateOpen(true);
                } else {
                  setFieldValue("input.statusId", value?._id ?? "");
                }
              }}
            />

          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.source")}*</InputLabel>
            <Autocomplete
              id="sourceId"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              getOptionLabel={(option) => option.name}
              options={[...(leadSourcesData?.data || []), { _id: "add", name: "Add new source" }]}
              loading={leadSourcesLoading}
              onBlur={handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!((errors as any)?.input?.sourceId && (touched as any)?.input?.sourceId)}
                  helperText={(touched as any)?.input?.sourceId && (errors as any)?.input?.sourceId}
                />
              )}
              value={leadSourcesData?.data?.find((source) => source._id === (values as any).input.sourceId) || null}
              onChange={(e, value) => {
                if (value?._id === "add") {
                  setLeadSourceCreateOpen(true); 
                } else {
                  setFieldValue("input.sourceId", value?._id ?? "");
                }
              }}
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.tags")}*</InputLabel>
            <Autocomplete
              id="tags"
              isOptionEqualToValue={(option, value) => option.name === value.name}
              fullWidth
              getOptionLabel={(option) => option.name}
              options={leadSourcesData?.data || []}
              loading={leadSourcesLoading}
              renderInput={(params) => <TextField {...params} />}
              multiple
              value={
                values.input?.tags && values.input.tags.length > 0
                  ? leadSourcesData?.data?.filter((tag) =>
                    (values as any).input.tags.includes(tag.name)
                  )
                  : []
              }
              onChange={(e, value) => {
                setFieldValue(
                  "input.tags",
                  value?.map((tag) => tag.name) || []
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.assigneds")}</InputLabel>
            <Autocomplete
              id="assignedIds"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              getOptionLabel={(option) => option.name + " " + option.surname}
              options={userListData?.data || []}
              loading={userListLoading}
              renderInput={(params) => <TextField {...params} />}
              multiple
              value={
                values.input?.assignedIds && values.input.assignedIds.length > 0
                  ? userListData?.data?.filter((user) =>
                    (values as any).input.assignedIds.includes(user._id)
                  )
                  : []
              }
              onChange={(e, value) => {
                setFieldValue(
                  "input.assignedIds",
                  value?.map((user) => user._id) || []
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.position")}*</InputLabel>
            <TextField
              fullWidth
              name="input.position"
              value={(values as any).input.position}
              onChange={(e) => setFieldValue("input.position", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.position && (touched as any)?.input?.position)}
              helperText={(touched as any)?.input?.position && (errors as any)?.input?.position}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.email")}*</InputLabel>
            <TextField
              fullWidth
              name="input.email"
              value={(values as any).input.email}
              onChange={(e) => setFieldValue("input.email", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.email && (touched as any)?.input?.email)}
              helperText={(touched as any)?.input?.email && (errors as any)?.input?.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.website")}</InputLabel>
            <TextField
              fullWidth
              name="input.website"
              value={(values as any).input.website}
              onChange={(e) => setFieldValue("input.website", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.website && (touched as any)?.input?.website)}
              helperText={(touched as any)?.input?.website && (errors as any)?.input?.website}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.phoneNumber")}</InputLabel>
            <TextField
              fullWidth
              name="input.phoneNumber"
              value={(values as any).input.phoneNumber}
              onChange={(e) => setFieldValue("input.phoneNumber", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.phoneNumber && (touched as any)?.input?.phoneNumber)}
              helperText={(touched as any)?.input?.phoneNumber && (errors as any)?.input?.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.company")}*</InputLabel>
            <TextField
              fullWidth
              name="input.company"
              value={(values as any).input.company}
              onChange={(e) => setFieldValue("input.company", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.company && (touched as any)?.input?.company)}
              helperText={(touched as any)?.input?.company && (errors as any)?.input?.company}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.sector")}*</InputLabel>
            <TextField
              fullWidth
              name="input.sector"
              value={(values as any).input.sector}
              onChange={(e) => setFieldValue("input.sector", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.sector && (touched as any)?.input?.sector)}
              helperText={(touched as any)?.input?.sector && (errors as any)?.input?.sector}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.type")}*</InputLabel>
            <Autocomplete
              id="type"
              fullWidth
              options={Object.values(LeadType).map((type) => ({
                name: localizedLeadTypes[type as keyof typeof LeadType],
                _id: type,
              }))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} />}
              value={
                values.input?.type
                  ? { name: localizedLeadTypes[values.input?.type as keyof typeof LeadType], _id: values.input?.type }
                  : null
              }
              onChange={(e, value) => {
                setFieldValue("input.type", value?._id);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.qualification")}*</InputLabel>
            <Autocomplete
              id="qualification"
              fullWidth
              options={Object.values(LeadQualification).map((type) => ({
                name: localizedLeadQualifications[type as keyof typeof LeadQualification],
                _id: type,
              }))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} />}
              value={
                values.input?.qualification
                  ? { name: localizedLeadQualifications[values.input?.qualification as keyof typeof LeadQualification], _id: values.input?.qualification }
                  : null
              }
              onChange={(e, value) => {
                setFieldValue("input.qualification", value?._id);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.country")}*</InputLabel>
            <TextField
              fullWidth
              name="input.country"
              value={(values as any).input.country}
              onChange={(e) => setFieldValue("input.country", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.country && (touched as any)?.input?.country)}
              helperText={(touched as any)?.input?.country && (errors as any)?.input?.country}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.state")}*</InputLabel>
            <TextField
              fullWidth
              name="input.state"
              value={(values as any).input.state}
              onChange={(e) => setFieldValue("input.state", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.state && (touched as any)?.input?.state)}
              helperText={(touched as any)?.input?.state && (errors as any)?.input?.state}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.city")}*</InputLabel>
            <TextField
              fullWidth
              name="input.city"
              value={(values as any).input.city}
              onChange={(e) => setFieldValue("input.city", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.city && (touched as any)?.input?.city)}
              helperText={(touched as any)?.input?.city && (errors as any)?.input?.city}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.language")}</InputLabel>
            <Autocomplete
              id="language"
              fullWidth
              options={Object.values(Language).map((type) => ({
                name: localizedLanguages[type as keyof typeof Language],
                _id: type,
              }))}
              value={
                values.input?.language
                  ? { name: localizedLanguages[values.input?.language as keyof typeof Language], _id: values.input?.language }
                  : null
              }
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} />}
              onChange={(e, value) => {
                setFieldValue("input.language", value?._id);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
            <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
              <Switch
                name="input.isPublic"
                checked={(values as any).input.isPublic}
                color="secondary"
                size="medium"
                onChange={(e) => setFieldValue("input.isPublic", e.target.checked)}
              />
              <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.lead.lead.justOwn")}</Typography>
            </Box>
          </Grid>


        </Grid>
        <Grid container spacing={3} pt={1}>
          <Grid item xs={12}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.lead.note")}</InputLabel>
            <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "1px solid", borderColor: "grey.400" }}>
              <ReactQuill
                theme="snow"
                value={(values as any).input.note}
                onChange={(content, delta, source, editor) => setFieldValue("input.note", editor.getHTML())}
                onBlur={() => handleBlur("input.note")}
                placeholder={t("crm.lead.lead.note")}
                style={{ height: 130, boxSizing: "border-box" }}
              />
            </Box>
            {(errors as any).input?.note && (
              <Typography color="error" variant="caption">
                {(errors as any).input?.note}
              </Typography>
            )}
          </Grid>
        </Grid>


        <Grid container spacing={3} pt={1.5}>
          <Grid item xs={12} >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleSubmit()}
              disabled={loading || !isValid || leadCreateLoading || !(values as any).input?.fullName}
            >
              {loading ? <CircularProgress size="1rem" /> : t("crm.lead.lead.create")}
            </Button>
          </Grid>
        </Grid>


      </Stack >

    </Grid >

    <LeadStatusCreateDrawer
      open={leadStatusCreateOpen}
      setOpen={setLeadStatusCreateOpen}
    />

    <LeadSourceCreateDrawer
      open={leadSourceCreateOpen}
      setOpen={setLeadSourceCreateOpen}
    />

  </SwipeableDrawer>
};