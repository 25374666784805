


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseDemoRoute,
} from "../routes/demo.base.route";

export const demoDomainMenuItem: IMenuItem = {
  title: getTranslatedText("demo"),
  path: baseDemoRoute(),
  children: [
    // Todo
    // {
    //   title: getTranslatedText("employees"),
    //   path: listEmployeesRoute(),
    // },
  ],
};
