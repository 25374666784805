import {
  // queries
  IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,
  IRoleListInput, IRoleListRequest, IRoleListResponse, IRoleListResult, roleListQuery,
  IRoleDetailInput, IRoleDetailRequest, IRoleDetailResponse, IRoleDetailResult, roleDetailQuery,
  IUserListInput, IUserListRequest, IUserListResponse, IUserListResult, userListQuery,
  IOrganizationChartDetailInput, IOrganizationChartDetailRequest, IOrganizationChartDetailResponse, IOrganizationChartDetailResult, organizationChartDetailQuery,

  // mutations
  IUserCreateInput, IUserCreateRequest, IUserCreateResponse, IUserCreateResult, userCreateQuery,
  IUserUpdateInput, IUserUpdateRequest, IUserUpdateResponse, IUserUpdateResult, userUpdateQuery, IUserUpdateFilterInput,
  IUserDeleteInput, IUserDeleteRequest, IUserDeleteResponse, IUserDeleteResult, userDeleteQuery,
  IUserRoleUpdateInput, IUserRoleUpdateRequest, IUserRoleUpdateResponse, IUserRoleUpdateResult, userRoleUpdateQuery, IUserRoleUpdateFilterInput,
  IRoleCreateInput, IRoleCreateRequest, IRoleCreateResponse, IRoleCreateResult, roleCreateQuery,
  IRoleUpdateInput, IRoleUpdateRequest, IRoleUpdateResponse, IRoleUpdateResult, roleUpdateQuery, IRoleUpdateFilterInput,
  IRoleDeleteInput, IRoleDeleteRequest, IRoleDeleteResponse, IRoleDeleteResult, roleDeleteQuery,
  IOrganizationChartUpdateInput, IOrganizationChartUpdateRequest, IOrganizationChartUpdateResponse, IOrganizationChartUpdateResult, organizationChartUpdateQuery, IOrganizationChartUpdateFilterInput,




} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { organizationTags } from "./employees.tags";
import { IGraphqlVariables } from "corede-common";


export const organizationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    permissionList: builder.query<
      IPermissionListResult,
      IGraphqlVariables<IPermissionListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPermissionListRequest,
        IPermissionListResponse,
        IPermissionListResult,
        IPermissionListInput
      >({
        query: permissionListQuery,
        providesTags: [organizationTags.permissions],
      })),

    roleList: builder.query<
      IRoleListResult,
      IGraphqlVariables<IRoleListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleListRequest,
        IRoleListResponse,
        IRoleListResult,
        IRoleListInput
      >({
        query: roleListQuery,
        providesTags: [organizationTags.roles],
      })),

    roleDetail: builder.query<
      IRoleDetailResult,
      IGraphqlVariables<IRoleDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleDetailRequest,
        IRoleDetailResponse,
        IRoleDetailResult,
        IRoleDetailInput
      >({
        query: roleDetailQuery,
        providesTags: [organizationTags.roles, organizationTags.role]
      })),

    userList: builder.query<
      IUserListResult,
      IGraphqlVariables<IUserListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserListRequest,
        IUserListResponse,
        IUserListResult,
        IUserListInput
      >({
        query: userListQuery,
        providesTags: [organizationTags.users, organizationTags.user],
      })),

    organizationChartDetail: builder.query<
      IOrganizationChartDetailResult,
      IGraphqlVariables<IOrganizationChartDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationChartDetailRequest,
        IOrganizationChartDetailResponse,
        IOrganizationChartDetailResult,
        IOrganizationChartDetailInput
      >({
        query: organizationChartDetailQuery,
        providesTags: [organizationTags.organization],
      })),

    // mutations

    userCreate: builder.mutation<
      IUserCreateResult,
      IGraphqlVariables<IUserCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserCreateRequest,
        IUserCreateResponse,
        IUserCreateResult,
        IUserCreateInput
      >({
        query: userCreateQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),

    userUpdate: builder.mutation<
      IUserUpdateResult,
      IGraphqlVariables<IUserUpdateInput, IUserUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserUpdateRequest,
        IUserUpdateResponse,
        IUserUpdateResult,
        IUserUpdateInput,
        IUserUpdateFilterInput
      >({
        query: userUpdateQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),

    userDelete: builder.mutation<
      IUserDeleteResult,
      IGraphqlVariables<IUserDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDeleteRequest,
        IUserDeleteResponse,
        IUserDeleteResult,
        IUserDeleteInput
      >({
        query: userDeleteQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),

    userRoleUpdate: builder.mutation<
      IUserRoleUpdateResult,
      IGraphqlVariables<IUserRoleUpdateInput, IUserRoleUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserRoleUpdateRequest,
        IUserRoleUpdateResponse,
        IUserRoleUpdateResult,
        IUserRoleUpdateInput,
        IUserRoleUpdateFilterInput
      >({
        query: userRoleUpdateQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),

    roleCreate: builder.mutation<
      IRoleCreateResult,
      IGraphqlVariables<IRoleCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleCreateRequest,
        IRoleCreateResponse,
        IRoleCreateResult,
        IRoleCreateInput
      >({
        query: roleCreateQuery,
        invalidatesTags: [organizationTags.roles, organizationTags.role],
      })),

    roleUpdate: builder.mutation<
      IRoleUpdateResult,
      IGraphqlVariables<IRoleUpdateInput, IRoleUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleUpdateRequest,
        IRoleUpdateResponse,
        IRoleUpdateResult,
        IRoleUpdateInput,
        IRoleUpdateFilterInput
      >({
        query: roleUpdateQuery,
        invalidatesTags: [organizationTags.roles, organizationTags.role],
      })),

    roleDelete: builder.mutation<
      IRoleDeleteResult,
      IGraphqlVariables<IRoleDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleDeleteRequest,
        IRoleDeleteResponse,
        IRoleDeleteResult,
        IRoleDeleteInput
      >({
        query: roleDeleteQuery,
        invalidatesTags: [organizationTags.roles, organizationTags.role],
      })),

    organizationChartUpdate: builder.mutation<
      IOrganizationChartUpdateResult,
      IGraphqlVariables<IOrganizationChartUpdateInput, IOrganizationChartUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationChartUpdateRequest,
        IOrganizationChartUpdateResponse,
        IOrganizationChartUpdateResult,
        IOrganizationChartUpdateInput,
        IOrganizationChartUpdateFilterInput
      >({
        query: organizationChartUpdateQuery,
        invalidatesTags: [organizationTags.organization],
      }))




  })

});

export const {
  usePermissionListQuery,
  useLazyPermissionListQuery,
  useRoleListQuery,
  useLazyRoleListQuery,
  useRoleDetailQuery,
  useLazyRoleDetailQuery,
  useUserListQuery,
  useLazyUserListQuery,
  useOrganizationChartDetailQuery,
  useLazyOrganizationChartDetailQuery,

  useUserCreateMutation,
  useUserUpdateMutation,
  useUserDeleteMutation,
  useUserRoleUpdateMutation,
  useRoleCreateMutation,
  useRoleUpdateMutation,
  useRoleDeleteMutation,
  useOrganizationChartUpdateMutation


} = organizationApi;
