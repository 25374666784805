
const locale = {
  somethingWentWrong: "Bir şeyler yanlış gitti!",
  loginSuccess: "Başarıyla giriş yapıldı",
  signinText: "Hadi giriş yapalım.",
  emailAddress: "E-posta adresi",
  password: "Şifre",
  forgotPassword: "Şifreni mi unuttun?",
  login: "Giriş Yap",
  continueWith: "veya devam et",
  noAccount: "Hesabınız yok mu?",
  signUp: "Kayıt ol",
  logoAlt: "logo",
  growYourBusiness: "İşinizi Büyütmek için ",
  business: "Her Şey",
  manageYourBusiness: "İşletmenizi verimli bir şekilde yönetin, satışlarınızı artırın, potansiyel müşterilere ulaşın ve topluluğa katılarak kendinizi güçlendirin.",
  bgPattern: "arka plan deseni",
  johnDoe: "John Doe",
  ceoCompany: "CEO, Şirket Test",
  passwordResetSuccess: "Şifreniz başarıyla sıfırlandı!",
  resetPasswordTitle: "Şifreyi Sıfırla",
  enterNewPassword: "Lütfen yeni şifrenizi girin.",
  passwordAgain: "Şifreyi Tekrar Girin",
  resetPassword: "Şifreyi Sıfırla",
  alreadyHaveAccount: "Zaten bir hesabınız var mı?",
  resetPasswordRequestSuccess: "Şifre Sıfırlama Talebi Başarıyla Gönderildi",
  forgotPasswordTitle: "Şifremi Unuttum",
  resetPasswordInstructions: "E-posta adresinizi girerek şifrenizi sıfırlayabilirsiniz.",
  rememberPassword: "Şifrenizi hatırladınız mı?",
  userConfirmedSuccess: "Kullanıcı başarıyla onaylandı!",
  emailConfirmationTitle: "E-posta Onayı",
  enterOtp: "E-postanıza gönderilen OTP'yi girin.",
  submit: "Gönder",
  didNotReceiveCode: "Kodu almadınız mı?",
  resendCode: "Kodu tekrar gönder",
  resendCodeTitle: "Onaylama Kodunu Tekrar Gönder",
  enterEmailToResend: "Onaylama kodunu almak için lütfen e-posta adresinizi girin.",
  email: "E-posta",
  close: "Kapat",
  send: "Gönder",
  signupSuccess: "Başarıyla kayıt olundu",
  letsRegister: "Sizi kaydedelim.",
  name: "İsim",
  surname: "Soyisim",
  iAccept: "Kabul ediyorum",
  termsAndConditions: "şartlar ve koşullar",
  orContinueWith: "veya devam et",
  createPassword: "Şifre Oluştur",
  createPasswordTitle: "Şifre Oluştur",
};

export default locale;
