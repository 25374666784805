


export const demoDomainRoutes = {
  base: "demo",
  subdomains: {
    demo: {
      base: "demo",
    },
  },
};


export const baseDemoRoute = (): string => `/${demoDomainRoutes.base}/`;
export const listEmployeesRoute = (): string =>
  `/${demoDomainRoutes.base}/${demoDomainRoutes.subdomains.demo.base}`;


