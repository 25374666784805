/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownward, ArrowUpward, Close, NoteAdd } from '@mui/icons-material'
import { SwipeableDrawer, Box, Stack, Avatar, Typography, Button, Divider, Grid, IconButton, SvgIcon } from '@mui/material'
import { useNoteCreateMutation, useNoteUpdateMutation, useNoteDeleteMutation, useLazyNoteListQuery } from 'apps/crm/domains/03-lead/subdomains/lead'
import { useLeadStatusesQuery } from 'apps/crm/domains/03-lead/subdomains/leadStatuses'
import { MyTab } from 'components/tab'
import { useNavigate } from 'react-router-dom'
import * as Icons from '@mui/icons-material';
import moment from 'moment'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill-new'
import { useFormik } from 'formik'
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium'
import { enqueueSnackbar } from 'notistack'
import { getCurrentLanguage } from 'localization'
import { Language, unknownError } from 'corede-common'
import { trTR } from '@mui/x-data-grid/locales';
import { useTranslation } from 'react-i18next'

interface ILeadDetailDrawer {
    open: any
    setOpen: any
    selectedLead: any
    setSelectedLead: any
    handleSelect: any
    count: any
}

export const LeadDetailDrawer = (props: ILeadDetailDrawer) => {

    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const apiRef = useGridApiRef();
    const { data: leadStatusesData, isLoading: leadStatusesLoading, error: leadStatusesError } = useLeadStatusesQuery({})
    const [noteListQuery, { data: noteListData, isLoading: noteListLoading, error: noteListError }] = useLazyNoteListQuery()
    const [noteCreate, { isLoading: noteCreateLoading, error: noteCreateError }] = useNoteCreateMutation();
    const [noteUpdate, { isLoading: noteUpdateLoading, error: noteUpdateError }] = useNoteUpdateMutation();
    const [noteDelete, { isLoading: noteDeleteLoading, error: noteDeleteError }] = useNoteDeleteMutation();
    const navigate = useNavigate();
    const [selectedTabName, setSelectedTabName] = useState("Activity");
    const [selectedNote, setSelectedNote] = useState<any>();


    const [rows, setRows] = useState<any[]>(noteListData?.data?.map((note, index) => ({
        id: index + 1,
        _id: note._id,
        title: note.title || '-',
        content: note.content || '-',
    })) || []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
        { field: 'title', headerName: 'Title', width: 300, editable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 90,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: any) => {
                return (
                    <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
                        <IconButton
                            onClick={() => setSelectedNote(params.row)}
                            color="info"
                            sx={{ p: "4px", }}
                        >
                            <Icons.RemoveRedEyeOutlined color="info" fontSize="small" />
                        </IconButton>
                        <IconButton
                            onClick={() => handleNoteDelete(params.row._id)}
                            color="error"
                            sx={{ p: "4px", }}
                        >
                            <Icons.DeleteOutlineRounded color="error" fontSize="small" />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];

    function handleProcessRowUpdate(newRow: any, oldRow: any) {
        if (newRow.name !== oldRow.name) {
            console.log({ filter: { _id: newRow._id }, input: { name: newRow.name } });
        }
        if (newRow.icon !== oldRow.icon) {
            console.log({ filter: { _id: newRow._id }, input: { icon: newRow.icon } });
        }
        if (newRow.color !== oldRow.color) {
            console.log({ filter: { _id: newRow._id }, input: { color: newRow.color } });
        }
        return newRow;
    };


    async function handleNoteDelete(id: string) {
        await noteDelete({ input: { _id: id } }).then(() => {
            enqueueSnackbar(t("Note deleted successfully!"), { variant: 'success' });
        }).catch(() => {
            enqueueSnackbar(t("Note delete failed!"), { variant: 'error' });
        })
    }

    const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } = useFormik({
        initialValues: {
            input: {
                title: selectedNote?.title || "",
                content: selectedNote?.content || "",
                targetId: props.selectedLead?._id,
                targetType: "lead",
            }
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            selectedNote ?
                noteUpdate({
                    ...values,
                    filter: {
                        _id: selectedNote?._id
                    },
                    input: {
                        ...values.input,
                        title: new DOMParser().parseFromString(values.input.content, 'text/html').body.textContent?.substring(0, 20) + "..."
                    }
                }).then(() => {
                    enqueueSnackbar(t("Note updated successfully!"), { variant: 'success' });
                    resetForm();
                    setSelectedNote(null);
                }).catch(() => {
                    enqueueSnackbar(t("Note update failed!"), { variant: 'error' });
                })
                :
                noteCreate({
                    ...values,
                    input: {
                        ...values.input,
                        title: new DOMParser().parseFromString(values.input.content, 'text/html').body.textContent?.substring(0, 20) + "..."

                    }
                }).then(() => {
                    enqueueSnackbar(t("Note created successfully!"), { variant: 'success' });
                    resetForm();
                }).catch(() => {
                    enqueueSnackbar(t("Note creation failed!"), { variant: 'error' });
                })

        }
    })


    useEffect(() => {
        if (props.selectedLead) {
            setFieldValue("input.targetId", props?.selectedLead?._id)
            noteListQuery({
                input: {
                    filter: {
                        targetIds: [props.selectedLead?._id || '']
                    }
                }
            })
        }
    }, [props.selectedLead])

    useEffect(() => {
        if (noteListError) {
            enqueueSnackbar((noteListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteListError]);

    useEffect(() => {
        if (noteUpdateError) {
            enqueueSnackbar((noteUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteUpdateError]);

    useEffect(() => {
        if (noteCreateError) {
            enqueueSnackbar((noteCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteCreateError]);

    useEffect(() => {
        if (noteDeleteError) {
            enqueueSnackbar((noteDeleteError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteDeleteError]);

    useEffect(() => {
        if (noteListData) {
            setRows(noteListData?.data?.map((note, index) => ({
                id: index + 1,
                _id: note._id,
                title: note.title || '-',
                content: note.content || '-',
            })))
        }
    }, [noteListData]);

    useEffect(() => {
        if (leadStatusesError) {
            enqueueSnackbar((leadStatusesError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    })

    return <>
        <SwipeableDrawer
            open={props.open}
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            anchor="right"
            variant='persistent'
            PaperProps={{
                sx: {
                    backgroundColor: 'background.primary',
                    right: '770px',
                    top: '10px',
                    width: "auto",
                    height: "auto",
                    padding: 0,
                    borderRadius: 20,
                    boxShadow: 3,
                    zIndex: 1250,
                }
            }}
        >
            <Stack direction="column" >
                <IconButton size="small" sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} onClick={() => props.handleSelect("back")}>
                    <ArrowUpward />
                </IconButton>
                <Divider sx={{ width: '100%' }} />
                <IconButton size="small" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} onClick={() => props.handleSelect("next")}>
                    <ArrowDownward />
                </IconButton>
            </Stack>
        </SwipeableDrawer>
        <SwipeableDrawer
            open={props.open}
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            anchor="right"
            className='user-detail-nav'
            sx={{ zIndex: 1249 }}
        >
            <Box minWidth={260} width={750} bgcolor={"background.primary"} height={"calc(100dvh)"}>
                <Stack direction="column" alignItems="center">
                    <Box sx={{ py: 1, height: 20, width: '100%', borderBottom: "2px solid", borderColor: "grey.400" }}>
                        <Stack direction="row" alignItems="center" gap={1} px={2} justifyContent={"space-between"}>
                            <Typography fontSize={"small"} fontWeight={"bold"}>
                                {props?.selectedLead?.id} of {props.count} leads
                            </Typography>
                            <Stack direction="row" alignItems="center" gap={0.5} onClick={() => props.setOpen(false)} sx={{ cursor: 'pointer' }}>
                                <Typography fontSize={"small"} fontWeight={"bold"} color={"secondary.main"}>
                                    Close
                                </Typography>
                                <Close sx={{ fontSize: "16px" }} color='secondary' />
                            </Stack>
                        </Stack>
                    </Box>
                    <Grid container width={"100%"}>
                        <Grid item xs={12} md={4} sx={{ height: '100%', borderRight: "2px solid", borderColor: "grey.400", p: 2 }}>
                            <Stack direction="column" gap={1} height={"calc(100dvh - 70px)"}>
                                {/* Personal */}
                                <Stack direction="column" alignItems={"flex-start"} gap={1}>
                                    <Box
                                        sx={{
                                            border: "2px solid",
                                            borderColor: leadStatusesData?.data?.find((status: any) => status._id === props.selectedLead?.status?._id)?.color,
                                            borderRadius: '10px',
                                            py: 0.5,
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            px: 1,
                                        }}
                                    >
                                        <SvgIcon component={(Icons as any)[(leadStatusesData as any)?.data.find((status: any) => status.name === props.selectedLead?.status?.name)?.icon]} fontSize="small" sx={{ mr: 0.5, color: leadStatusesData?.data.find((status) => status.name === props.selectedLead?.status?.name)?.color }} />
                                        <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"} color={leadStatusesData?.data.find((status) => status.name === props.selectedLead?.status?.name)?.color}>
                                            {leadStatusesData?.data.find((status) => status.name === props.selectedLead?.status?.name)?.name}
                                        </Typography>
                                    </Box>

                                    <Typography sx={{ fontWeight: "bold", fontSize: "24px", color: "primary.main" }}>
                                        {props.selectedLead?.fullName}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "primary.main" }}>
                                        {props.selectedLead?.company}
                                    </Typography>
                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ height: 40, fontSize: "13px", borderRadius: 100 }}
                                            onClick={() => navigate("/")}
                                        >
                                            <NoteAdd sx={{ mr: 1 }} /> New Note
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            sx={{
                                                height: 40, width: 40, fontSize: "13px", minWidth: 0, borderRadius: 100
                                            }}
                                        >
                                            <Icons.MoreVert />
                                        </Button>
                                    </Stack>
                                    <Box sx={{ mt: 2, width: '100%', p: 2, bgcolor: "background.fourth", borderRadius: 2, boxSizing: 'border-box' }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "14px", color: "text.secondary" }}>
                                            Proposal Sent
                                        </Typography>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                                            <Typography sx={{ fontWeight: 400, fontSize: "20px", color: "primary.main" }}>
                                                $312,000
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "secondary.main", cursor: "pointer" }}>
                                                View
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Stack>


                                {/* Contact Details */}
                                <Stack direction={"column"} width={"100%"} gap={2} mt={2}>
                                    <Stack direction={"column"} width={"100%"} gap={0.5}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Contact Details
                                        </Typography>
                                        <Divider />
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Avatar alt={props.selectedLead?.fullName} src={props.selectedLead?.avatar?.toString()} sx={{ width: 40, height: 40 }} />
                                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                            {props.selectedLead?.fullName}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Icons.EmailOutlined sx={{ color: "text.secondary" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                                Email Address
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                {props.selectedLead?.email}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Icons.PhoneOutlined sx={{ color: "text.secondary" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                                Phone
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                {props.selectedLead?.phoneNumber}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Icons.PublicOutlined sx={{ color: "text.secondary" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                                Source
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                {props.selectedLead?.website}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>


                                {/* Assigneds */}
                                <Stack direction={"column"} width={"100%"} gap={2} mt={2}>
                                    <Stack direction={"column"} width={"100%"} gap={0.5}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Assigneds
                                        </Typography>
                                        <Divider />
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Avatar alt={props.selectedLead?.fullName} src={"https://loremflickr.com/640/480"} sx={{ width: 40, height: 40 }} />
                                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                            {props.selectedLead?.fullName}
                                        </Typography>
                                    </Stack>
                                </Stack>


                                <Stack direction={"column"} position={"absolute"} bottom={10} left={10} gap={0.5} alignItems={"flex-end"}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                        Lead created at {moment(props.selectedLead?.createdAt).format("DD/MM/YYYY")}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Stack direction={"column"} gap={0} width={"100%"}>
                                {/* Statüses  */}
                                <Stack direction={"column"} gap={1} width={"100%"} sx={{ borderBottom: "2px solid", borderColor: "grey.400", p: 2, boxSizing: "border-box" }}>
                                    <Stack direction="row" spacing={0} sx={{ borderRadius: '16px', overflow: 'hidden', mb: 1 }}>
                                        {leadStatusesData?.data?.map((status, index) => (
                                            <Box
                                                key={status.name}
                                                sx={{
                                                    backgroundColor: status.name === props.selectedLead?.status.name ? status?.color : 'primary.light',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    position: 'relative',
                                                    justifyContent: 'center',
                                                    padding: '4px 16px',
                                                    pl: index !== 0 ? 3 : 1,
                                                    ...(index === 0 && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }),
                                                    ...(index === leadStatusesData?.data?.length - 1 && { borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }),
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        display: index === leadStatusesData?.data?.length - 1 ? 'none' : 'block',
                                                        top: 0,
                                                        right: -6,
                                                        width: 0,
                                                        height: 0,
                                                        borderTop: '12px solid transparent',
                                                        borderBottom: '12px solid transparent',
                                                        borderLeft: `8px solid`,
                                                        borderLeftColor: 'background.secondary',
                                                        zIndex: 2
                                                    }}
                                                />
                                                <SvgIcon component={(Icons as any)[status.icon]} sx={{ fontSize: '16px', color: 'white', mr: 1 }} />
                                                <Typography variant="body2" fontSize={'12px'} sx={{ color: 'white', textTransform: 'capitalize' }}>
                                                    {status.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={6}>
                                            <Stack direction={"row"} gap={1} alignItems={"flex-start"} mt={1}>
                                                <Box sx={{ width: 30, height: 30, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Icons.Menu sx={{ color: "text.secondary", fontSize: "medium" }} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                                                        Active Sequence
                                                    </Typography>
                                                    <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                        Lorem ipsum
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction={"row"} gap={1} alignItems={"flex-start"} mt={1}>
                                                <Box sx={{ width: 30, height: 30, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Icons.WaterDropOutlined sx={{ color: "text.secondary", fontSize: "medium" }} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                                                        Next Drip
                                                    </Typography>
                                                    <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                        Lorem ipsum dolor
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                {/* Tabbar */}
                                <Box sx={{ px: 1 }}>
                                    <MyTab
                                        labels={["Activity", "Notes", "Invoices", "Tasks"]}
                                        setSelectedTab={setSelectedTabName}
                                    />
                                </Box>
                                <Divider sx={{ width: "100%", borderBottomWidth: 2, borderColor: "grey.400" }} />

                                {/* ACTIVITY TAB */}
                                {selectedTabName === "Activity" && <Box sx={{ overflow: "auto" }}>
                                    {/* Latest Activity */}

                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
                                        <Box height={"45%"} borderLeft={"1px dashed"} borderColor={"grey.400"} position={"absolute"} top={60} left={30} zIndex={0} />
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Latest Activity
                                        </Typography>
                                        <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                            <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Icons.EmailRounded sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} mt={0.5} color={"text.primary"}>
                                                        Email delivered: Proposal sent
                                                    </Typography>
                                                    <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                        Proposal has been sent to the client. We'll be in touch soon!
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Typography fontSize={"11px"} fontWeight={400} mt={0.5} color={"text.secondary"}>
                                                1 day ago
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                            <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Icons.KeyboardDoubleArrowRight sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} mt={0.5} color={"text.primary"}>
                                                        Stage request: Request recieved
                                                    </Typography>
                                                    <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                        Proposal has been sent to the client.
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Typography fontSize={"11px"} fontWeight={400} mt={0.5} color={"text.secondary"}>
                                                2 day ago
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    {/* Appointments */}
                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mb: 2, position: "relative" }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Appointments
                                        </Typography>
                                        <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Stack direction={"column"} gap={1}>
                                                        <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                                            Monday
                                                        </Typography>
                                                        <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                                            September 3, 2024
                                                        </Typography>
                                                        <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                                            10 AM | 10.30 AM
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={8} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                                                    <Stack direction={"column"} gap={1}>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                On site estimate with <strong>User One</strong>
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                4517 Bornova, İzmir Türkiye
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Avatar alt={props.selectedLead?.fullName} src={"https://loremflickr.com/480/480"} sx={{ width: 15, height: 15 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                Selçuk Madra
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>

                                    {/* Proposals */}
                                    <Stack direction={"column"} sx={{ px: 2, py: 1, position: "relative" }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Proposals
                                        </Typography>
                                        <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                                            <Grid container>
                                                <Grid item xs={7} >
                                                    <Stack direction={"column"} gap={1}>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                On site estimate with <strong>User One</strong>
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                4517 Bornova, İzmir Türkiye
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Avatar alt={props.selectedLead?.fullName} src={"https://loremflickr.com/480/480"} sx={{ width: 15, height: 15 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                Selçuk Madra
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={5} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                                                    <Stack direction={"column"} gap={1}>
                                                        <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                                            Monday
                                                        </Typography>
                                                        <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                                            September 3, 2024
                                                        </Typography>
                                                        <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                                            10 AM | 10.30 AM
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                </Box>}

                                {/* NOTES TAB */}
                                {selectedTabName === "Notes" && <Box sx={{ overflow: "auto" }}>

                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                                {selectedNote ? "Update Note" : "Add New Note"}
                                            </Typography>
                                            {selectedNote ?
                                                <Stack direction={"row"} gap={1}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ height: 40, fontSize: "13px" }}
                                                        onClick={() => handleSubmit()}
                                                        disabled={noteUpdateLoading}
                                                    >
                                                        <Icons.Update sx={{ mr: 1, fontSize: "16px" }} />
                                                        Update
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        sx={{ height: 40, fontSize: "13px" }}
                                                        onClick={() => {
                                                            setSelectedNote(null)
                                                            resetForm()
                                                        }}
                                                        disabled={noteUpdateLoading}
                                                    >
                                                        <Icons.Cancel sx={{ mr: 1, fontSize: "16px" }} />
                                                        Cancel
                                                    </Button>
                                                </Stack>
                                                : <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ height: 40, fontSize: "13px" }}
                                                    onClick={() => handleSubmit()}
                                                    disabled={noteCreateLoading}
                                                >
                                                    <Icons.AddCircle sx={{ mr: 1, fontSize: "16px" }} />
                                                    Add
                                                </Button>}
                                        </Stack>
                                        <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "2px solid", borderColor: "grey.400" }}>
                                            <ReactQuill
                                                theme="snow"
                                                value={(values as any).input.content}
                                                onChange={(content, delta, source, editor) => {
                                                    setFieldValue("input.content", editor.getHTML())
                                                }}
                                                onBlur={() => handleBlur("input.content")}
                                                placeholder={"Some text here..."}
                                                style={{ height: 130, boxSizing: "border-box" }}
                                            />
                                        </Box>
                                        {(errors as any).input?.content && (
                                            <Typography color="error" variant="caption">
                                                {(errors as any).input?.content}
                                            </Typography>
                                        )}

                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", mt: 2, mb: 1, color: "text.primary" }}>
                                            Note List
                                        </Typography>

                                        <DataGridPremium
                                            localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
                                            apiRef={apiRef}
                                            rows={rows}
                                            columns={columns}
                                            disableRowSelectionOnClick
                                            showColumnVerticalBorder
                                            disableAggregation
                                            disableRowGrouping
                                            hideFooter
                                            processRowUpdate={handleProcessRowUpdate}
                                            loading={leadStatusesLoading || noteCreateLoading || noteUpdateLoading || noteDeleteLoading || noteListLoading}
                                            onCellDoubleClick={(params, event) => {
                                                if (params.field === 'icon') {
                                                    event.stopPropagation();
                                                }
                                                if (params.field === 'color') {
                                                    event.stopPropagation();
                                                }
                                            }}
                                            scrollbarSize={1}
                                            scrollEndThreshold={10}
                                            rowsLoadingMode="server"
                                            sx={[tableStyle, {
                                                backgroundColor: 'background.secondary', minHeight: "280px", maxHeight: "280px",
                                                '&. .MuiDataGrid-topContainer': {
                                                    border: "2px solid #000"
                                                },
                                                '& .MuiDataGrid-row--borderBottom': {
                                                    background: "none !important",

                                                },
                                            }]}
                                        />
                                    </Stack>
                                </Box>
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack >
            </Box >
        </SwipeableDrawer >
    </>
}


const tableStyle = {
    fontSize: "12px",
    borderRadius: "16px",
    border: "2px solid",
    borderColor: "grey.400",

    '& .MuiDataGrid-toolbarContainer > button': {
        fontSize: "12px",
    },
    '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
        fontSize: "12px",
        p: "2px",
        border: "none !important",
    },
    '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
        width: "16px",
        height: "16px",
    },
    '& .MuiDataGrid-footerContainer': {
        height: '20px !important',
        maxHeight: '20px !important',
        m: 0,
    }
};