/* eslint-disable react-hooks/exhaustive-deps */
import { SwipeableDrawer, Box, Stack, Typography, Button, Divider, CircularProgress } from '@mui/material'
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro'
import { TreeViewBaseItem } from '@mui/x-tree-view/models'
import { useOrganizationChartUpdateMutation } from 'apps/crm/domains/02-organizationalChart/subdomains/employees'
import { InfoBox } from 'components/infoBox'
import { unknownError } from 'corede-common'
import { getCurrentLanguage } from 'localization'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IChartEditDrawer {
    open: boolean
    setOpen: (open: boolean) => void
    orgChartData: any
    setConvertedChart: (convertedChart: any) => void
}


export const ChartEditDrawer = (props: IChartEditDrawer) => {

    const { t } = useTranslation();
    const [organizationChartUpdate, { data: organizationChartUpdateData, isLoading: organizationChartUpdateLoading, error: organizationChartUpdateError }] = useOrganizationChartUpdateMutation();
    const currentLanguage = getCurrentLanguage();
    const [items, setItems] = useState<TreeViewBaseItem[]>(() => [transformToTreeViewItems(props.orgChartData ?? {})]);


    function transformToTreeViewItems(node: any) {
        const { user, children } = node;
        const label = `${user?.name || ''} ${user?.surname || ''}`.trim();

        return {
            id: user?._id || Math.random().toString(36).substr(2, 9),
            label: label.length > 0 ? label : t("crm.organizationalChart.employees.undefinedUser"),
            children: children ? children.map((child: any) => transformToTreeViewItems(child)) : [],
        };
    }

    useEffect(() => {
        setItems([transformToTreeViewItems(props.orgChartData ?? {})]);
    }, [props.orgChartData]);

    useEffect(() => {
        if (organizationChartUpdateError) {
            enqueueSnackbar((organizationChartUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    })

    const convertLikeOrgChart = (items: any) => {
        const result = items.map((item: any) => {
            return {
                user: {
                    _id: item?.id,
                },
                children: item?.children ? convertLikeOrgChart(item.children) : [],
            };
        });
        return result;
    };

    const handleItemPositionChange = (params: any) => {
        const { itemId, newPosition } = params;

        const findAndRemoveItem = (items: any, itemId: any) => {
            let itemToRemove = null;
            const updatedItems = items.filter((item: any) => {
                if (item?.id === itemId) {
                    itemToRemove = item;
                    return false;
                }
                if (item.children) {
                    const result = findAndRemoveItem(item.children, itemId);
                    if (result.itemToRemove) {
                        itemToRemove = result.itemToRemove;
                        item.children = result.updatedItems;
                    }
                }
                return true;
            });
            return { updatedItems, itemToRemove };
        };

        const insertItemAtPosition = (items: any, item: any, position: any) => {
            if (position.parentId === null) {
                items.splice(position.index, 0, item);
                return items;
            }
            return items.map((i: any) => {
                if (i?.id === position.parentId) {
                    i.children = i.children || [];
                    i.children.splice(position.index, 0, item);
                } else if (i.children) {
                    i.children = insertItemAtPosition(i.children, item, position);
                }
                return i;
            });
        };

        const { updatedItems, itemToRemove } = findAndRemoveItem(items, itemId);
        const newItems = insertItemAtPosition(updatedItems, itemToRemove, newPosition);

        setItems(newItems); // items'ı güncelle
    };

    useEffect(() => {
        if (organizationChartUpdateData) {
            enqueueSnackbar(t("crm.organizationalChart.employees.chartUpdateSuccess"), { variant: 'success' });
        }
    }, [organizationChartUpdateData]);

    return (
        <SwipeableDrawer
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            anchor="right"
            className="chart-nav"
        >
            <Box py={5} px={3} minWidth={260} bgcolor={"background.primary"} height={"100vh"}>
                <Stack direction="column" gap={1} justifyContent={"space-between"} height={"90%"}>
                    <Stack direction={"column"} gap={1} justifyContent={"flex-start"}>
                        <Typography fontSize={"24px"} fontWeight={600} textAlign={"left"}>
                            {t("crm.organizationalChart.employees.editOrganizationChart")}
                        </Typography>
                        <Divider />
                        <InfoBox content={t("crm.organizationalChart.employees.editOrganizationDetail")} />
                        <Stack spacing={2}>
                            <Box sx={{ minHeight: 352, minWidth: 300 }}>
                                <RichTreeViewPro
                                    items={items}
                                    itemsReordering
                                    sx={{
                                        color: 'text.primary',
                                    }}
                                    experimentalFeatures={{
                                        indentationAtItemLevel: true,
                                        itemsReordering: true,
                                    }}
                                    defaultExpandedItems={[[items[0].id] as any]}
                                    onItemPositionChange={handleItemPositionChange}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ height: 40, mt: 1, fontSize: "13px" }}
                        disabled={organizationChartUpdateLoading}
                        onClick={() => {
                            console.log({ items: convertLikeOrgChart(items) });
                            organizationChartUpdate({
                                input: {
                                    chart: convertLikeOrgChart(items)?.[0]

                                }
                            })
                            props.setOpen(false);
                        }}
                    >
                        {organizationChartUpdateLoading ? <CircularProgress size={"1rem"} /> : t("crm.organizationalChart.employees.save")}
                    </Button>
                </Stack>
            </Box>
        </SwipeableDrawer>
    );
};
