import { createTheme, alpha } from '@mui/material/styles';


const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
};

const PRIMARY = {
    lighter: '#F3F3F3',
    light: '#B7B7B7',
    main: '#111111',
    dark: '#0C0809',
    darker: '#080304',
    contrastText: '#FFFFFF',
};


const SECONDARY = {
    lighter: '#f88b9e',
    light: '#f6657e',
    main: '#F43F5E',
    dark: '#F43F5E',
    darker: '#922538',
    contrastText: '#FFFFFF',
};

const INFO = {
    lighter: '#CFE4FB',
    light: '#70A1E9',
    main: '#194EB7',
    dark: '#0C2C83',
    darker: '#041557',
    contrastText: '#FFFFFF',
};

const MAIN = {
    lighter: '#CFE4FB',
    light: '#70A1E9',
    main: '#194EB7',
    dark: '#0C2C83',
    darker: '#041557',
    contrastText: '#FFFFFF',
};


const SUCCESS = {
    lighter: '#DDF8D1',
    light: '#80D771',
    main: '#1A7C1D',
    dark: '#0D591C',
    darker: '#043B19',
    contrastText: '#ffffff',
};

const WARNING = {
    lighter: '#FCEFC9',
    light: '#EDBE5F',
    main: '#C47301',
    dark: '#8D4600',
    darker: '#5E2700',
    contrastText: GREY[100],
};


const ERROR = {
    lighter: '#F9CFD1',
    light: '#DF6D8A',
    main: '#961650',
    dark: '#6C0B4A',
    darker: '#48043E',
    contrastText: '#FFFFFF',
};


const ALTERNATIVE = {
    lighter: '#bbb',
    light: '#aaa',
    main: '#999',
    dark: '#888',
    darker: '#777',
    contrastText: '#FFFFFF',
};

const ALTERNATIVE2 = {
    lighter: '#bbb',
    light: '#aaa',
    main: '#ccc',
    dark: '#888',
    darker: '#777',
    contrastText: '#FFFFFF',
};


const COMMON = {
    common: {
        black: '#000000',
        white: '#FFFFFF',
    },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    main: MAIN,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    alternative: ALTERNATIVE,
    alternative2: ALTERNATIVE2,
    divider: alpha(GREY[500], 0.2),
    action: {
        hover: alpha(GREY[500], 0.08),
        selected: alpha(GREY[500], 0.16),
        disabled: alpha(GREY[500], 0.8),
        disabledBackground: alpha(GREY[500], 0.24),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
    alternatives: {
        background: {
            default: '#FFFFFF',
        }
    }
};


// export const theme = createTheme(
//     {
//         palette: {
//             ...COMMON,

//             mode: 'light',
//             text: {
//                 primary: GREY[800],
//                 secondary: GREY[600],
//                 disabled: GREY[500],
//             },
//             background: {
//                 paper: '#FFFFFF',
//                 default: '#FFFFFF',
//             },
//             action: {
//                 ...COMMON.action,
//                 active: GREY[600],
//             },
//         },
//         shape: {
//             borderRadius: 8,
//         },
//         typography: {
//             fontFamily: 'Lexend',
//             h1: {
//                 fontSize: '2.5rem',
//                 fontWeight: 700,
//             },
//             h2: {
//                 fontSize: '2rem',
//                 fontWeight: 700,
//             },
//             h3: {
//                 fontSize: '1.75rem',
//                 fontWeight: 700,
//             },
//             h4: {
//                 fontSize: '1.5rem',
//                 fontWeight: 700,
//             },
//             h5: {
//                 fontSize: '1.25rem',
//                 fontWeight: 700,
//             },
//             h6: {
//                 fontSize: '1rem',
//                 fontWeight: 700,
//             },
//             subtitle1: {
//                 fontSize: '1rem',
//                 fontWeight: 400,
//             },
//             subtitle2: {
//                 fontSize: '0.875rem',
//                 fontWeight: 400,
//             },
//             body1: {
//                 fontSize: '1rem',
//                 fontWeight: 400,
//             },
//             body2: {
//                 fontSize: '0.875rem',
//                 fontWeight: 400,
//             },
//             button: {
//                 fontSize: '1rem',
//                 fontWeight: 700,
//             },
//             caption: {
//                 fontSize: '0.75rem',
//                 fontWeight: 400,
//             },
//             overline: {
//                 fontSize: '0.75rem',
//                 fontWeight: 700,
//             },
//         },
//         components: {
//             MuiOutlinedInput: {
//                 styleOverrides: {
//                     root: {
//                         backgroundColor: "#FFFFFF00",
//                         borderRadius: 16,
//                         borderColor: 'red'
//                     },
//                 },
//             },
//             MuiInputBase: {
//                 styleOverrides: {
//                     root: {
//                         backgroundColor: GREY[200],
//                         borderRadius: 16,
//                     },
//                 },
//             },
//             MuiTextField: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 16,
//                         borderColor: GREY[200],
//                     },
//                 },
//             },
//             MuiButton: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 16,
//                         height: 56,
//                         capitalize: false,
//                         textTransform: 'none',
//                         fontSize: '1rem',
//                         boxShadow: 'none',
//                     },
//                 },
//             },
//             MuiCard: {
//                 styleOverrides: {
//                     root: {
//                         boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
//                         padding: 10,
//                         borderRadius: 16,
//                         // maskImage: 'paint(smooth-corners)',
//                         // WebkitMaskImage: 'paint(smooth-corners)',
//                         // '--smooth-corners': 25.89,
//                     },
//                 },
//             },
//             MuiSnackbarContent: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 16,
//                         boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
//                     },
//                 },
//             },
//             MuiTab: {
//                 styleOverrides: {
//                     root: {
//                         textTransform: 'none',
//                     },
//                 },
//             },

//         },
//     },
//     trTR,
// );

const getDesignTokens = (mode: 'light' | 'dark') =>
    mode === 'light' ? {
        // Light mode specific palette
        primary: {
            lighter: '#F3F3F3',
            light: '#959595',
            main: '#111111',
            dark: '#0C0809',
            darker: '#080304',
            contrastText: '#FFFFFF',
        },
        secondary: {
            lighter: '#f88b9e',
            light: '#f6657e',
            main: '#F43F5E',
            dark: '#F43F5E',
            darker: '#922538',
            contrastText: '#FFFFFF',
        },
        background: {
            paper: '#FFFFFF',
            default: '#F1EEEE',
            primary: '#F1EEEE',
            secondary: '#FFFFFF',
            third: '#FFF',
            fourth: '#DDD',
        },
        text: {
            primary: GREY[800],
            secondary: GREY[600],
            disabled: GREY[500],
            white: '#FFFFFF',
        },

    }
        : {
            // Dark mode specific palette
            primary: {
                lighter: '#1E1E1E',
                light: '#5A5A5A',
                main: '#F5F5F5',
                dark: '#D1D1D1',
                darker: '#B5B5B5',
                contrastText: '#111111',
            },
            secondary: {
                lighter: '#f88b9e',
                light: '#f6657e',
                main: '#F43F5E',
                dark: '#F43F5E',
                darker: '#922538',
                contrastText: '#FFFFFF',
            },
            background: {
                paper: GREY[800],
                default: GREY[900],
                primary: GREY[900],
                secondary: GREY[800],
                third: GREY[700],

            },
            text: {
                primary: GREY[100],
                secondary: GREY[300],
                disabled: GREY[500],
                white: '#FFFFFF',
            },

        };


export const theme = (mode: 'light' | 'dark') => createTheme(
    {
        palette: {
            mode,
            ...COMMON,
            ...getDesignTokens(mode),
        },
        shape: {
            borderRadius: 8,
        },
        typography: {
            fontFamily: 'Lexend',
            h1: {
                fontSize: '2.5rem',
                fontWeight: 700,
            },
            h2: {
                fontSize: '2rem',
                fontWeight: 700,
            },
            h3: {
                fontSize: '1.75rem',
                fontWeight: 700,
            },
            h4: {
                fontSize: '1.5rem',
                fontWeight: 700,
            },
            h5: {
                fontSize: '1.25rem',
                fontWeight: 700,
            },
            h6: {
                fontSize: '1rem',
                fontWeight: 700,
            },
            subtitle1: {
                fontSize: '1rem',
                fontWeight: 400,
            },
            subtitle2: {
                fontSize: '0.875rem',
                fontWeight: 400,
            },
            body1: {
                fontSize: '1rem',
                fontWeight: 400,
            },
            body2: {
                fontSize: '0.875rem',
                fontWeight: 400,
            },
            button: {
                fontSize: '1rem',
                fontWeight: 700,
            },
            caption: {
                fontSize: '0.75rem',
                fontWeight: 400,
            },
            overline: {
                fontSize: '0.75rem',
                fontWeight: 700,
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FFFFFF00",
                        borderRadius: 16,
                        borderColor: 'red'
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: GREY[200],
                        borderRadius: 16,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        borderRadius: 16,
                        borderColor: GREY[200],
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&.MuiButton-alternative': {
                            backgroundColor: '#00ff00',
                            color: '#fff',
                        },
                        borderRadius: 16,
                        height: 56,
                        capitalize: false,
                        textTransform: 'none',
                        fontSize: '1rem',
                        boxShadow: 'none',
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
                        padding: 10,
                        borderRadius: 16,
                        // maskImage: 'paint(smooth-corners)',
                        // WebkitMaskImage: 'paint(smooth-corners)',
                        // '--smooth-corners': 25.89,
                    },
                },
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        borderRadius: 16,
                        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: mode === 'dark' ? GREY[100] : GREY[800],
                    }
                },
            },
            MuiCollapse: {
                styleOverrides: {
                    wrapperInner: {
                        // borderLeft: '1px solid',
                        li: {
                            borderLeft: `1px solid #ababab`,
                            ":last-child": {
                                borderLeft: "none",
                                borderRadius: 0,
                                background:
                                    "repeating-linear-gradient(to bottom, transparent 1px,#ababab 1px) 0 0%/ 0.8px 50% no-repeat",
                            },
                        }
                    },

                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: 3
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid',
                        borderColor: mode === 'dark' ? "#5A5A5A" : "#B7B7B7",
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 10,
                        paddingRight: 10,
                    }
                }
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        border: '1px solid',
                        borderColor: mode === 'dark' ? "#5A5A5A" : "#B7B7B7",
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: 20
                    }
                }
            }



        },
    }
);