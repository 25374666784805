import {
  IBaseGraphqlError,
  IRegisterInput,
  IRegisterResult,
  IRegisterResponse,
  IRegisterRequest,
  ILogoutInput,
  ILogoutResult,
  IRequestResetPasswordInput,
  IRequestResetPasswordResult,
  IConfirmRegistrationInput,
  IConfirmRegistrationResult,
  IResendRegistrationByUserConfirmationInput,
  IResendRegistrationByUserConfirmationResult,
  IResetPasswordInput,
  IResetPasswordResult,
  IResetPasswordAfterRequestInput,
  IResetPasswordAfterRequestResult,
  IConfirmRegistrationByUserInput,
  IConfirmRegistrationByUserResult,
  IResendRegistrationByUserConfirmationRequest,
  IResendRegistrationConfirmationInput, logoutQuery,
  requestResetPasswordQuery,
  confirmRegistrationQuery,
  resendRegistrationByUserConfirmationQuery,
  resetPasswordQuery,
  resetPasswordAfterRequestQuery,
  confirmRegistrationByUserQuery,
  resendRegistrationConfirmationQuery,
  ILogoutRequest,
  ILogoutResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse,
  registerQuery,
  IRequestResetPasswordRequest,
  IRequestResetPasswordResponse,
  IResetPasswordAfterRequestResponse,
  IResetPasswordAfterRequestRequest,
  IConfirmRegistrationByUserRequest,
  IConfirmRegistrationByUserResponse,
  IGraphqlVariables,
} from "corede-common";
import {
  IUserListInput,
  IUserListResponse,
  IUserListResult,
  userListQuery,
  IUserDetailOwnInput,
  IUserDetailOwnResponse,
  IUserDetailOwnResult,
  userDetailOwnQuery,
  userUpdateOwnQuery,
  IUserUpdateOwnInput,
  IUserUpdateOwnResult,
  ILoginInput,
  ILoginResponse,
  ILoginResult,
  ILoginRequest,
  loginQuery,

  IOrganizationCreateOwnInput,
  IOrganizationCreateOwnResult,
  IOrganizationCreateOwnResponse,
  IOrganizationCreateOwnRequest,
  organizationCreateOwnQuery,
  IUserDetailResult,
  IUserDetailInput,
  IUserDetailRequest,
  IUserDetailResponse,
  userDetailQuery,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { print } from "graphql";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";

const authApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    userList: builder.query<IUserListResult, IUserListInput>({
      query: (
        variables: IUserListInput & { input: { filter: { role?: string } } }
      ) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(userListQuery),
            variables: {
              input: {
                pagination: {
                  page: variables?.pagination?.page || 1,
                  pageSize: variables?.pagination?.pageSize || 10,
                  sort: variables?.pagination?.sort || undefined,
                },
                filter: {
                  role: "user",
                },
              },
            },
          }),
        };
      },
      transformResponse: (response: IUserListResponse): IUserListResult => {
        return response.data.userList;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      providesTags: ["users"],
    }),

    userDetailOwn: builder.query<IUserDetailOwnResult, IUserDetailOwnInput>({
      query: () => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(userDetailOwnQuery),
          }),
        };
      },
      transformResponse: (response: IUserDetailOwnResponse): IUserDetailOwnResult => {
        return response.data.userDetailOwn;
      },
      transformErrorResponse: (
        response: IBaseGraphqlError
      ): IBaseGraphqlError => {
        return response;
      },
      providesTags: ["user"],
    }),

    userDetail: builder.query<
      IUserDetailResult,
      IGraphqlVariables<IUserDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDetailRequest,
        IUserDetailResponse,
        IUserDetailResult,
        IUserDetailInput
      >({
        query: userDetailQuery,
        providesTags: ["user"],
      })),

    // mutations

    login: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILoginRequest,
        ILoginResponse,
        ILoginResult,
        ILoginInput
      >({
        query: loginQuery,
      }),
    ),

    register: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRegisterRequest,
        IRegisterResponse,
        IRegisterResult,
        IRegisterInput
      >({
        query: registerQuery,
      })
    ),

    logout: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILogoutRequest,
        ILogoutResponse,
        ILogoutResult,
        ILogoutInput
      >({
        query: logoutQuery,
        invalidatesTags: ["users", "user"],
      })
    ),

    requestResetPassword: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRequestResetPasswordRequest,
        IRequestResetPasswordResponse,
        IRequestResetPasswordResult,
        IRequestResetPasswordInput
      >({
        query: requestResetPasswordQuery,
      })
    ),

    confirmRegistration: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IConfirmRegistrationRequest,
        IConfirmRegistrationResponse,
        IConfirmRegistrationResult,
        IConfirmRegistrationInput
      >({
        query: confirmRegistrationQuery,
      })
    ),

    resetPasswordAfterRequest: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResetPasswordAfterRequestRequest,
        IResetPasswordAfterRequestResponse,
        IResetPasswordAfterRequestResult,
        IResetPasswordAfterRequestInput
      >({
        query: resetPasswordAfterRequestQuery,
      })
    ),

    registrationByUser: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IConfirmRegistrationByUserRequest,
        IConfirmRegistrationByUserResponse,
        IConfirmRegistrationByUserResult,
        IConfirmRegistrationByUserInput
      >({
        query: confirmRegistrationByUserQuery
      })
    ),

    organizationCreateOwn: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationCreateOwnRequest,
        IOrganizationCreateOwnResponse,
        IOrganizationCreateOwnResult,
        IOrganizationCreateOwnInput
      >({
        query: organizationCreateOwnQuery
      })
    ),






    resendRegistrationByUserConfirmation: builder.mutation<
      IResendRegistrationByUserConfirmationResult,
      IResendRegistrationByUserConfirmationInput
    >({
      query: (variables: IResendRegistrationByUserConfirmationInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resendRegistrationByUserConfirmationQuery),
            variables: {
              input: {
                email: variables.email,
              },
            },
          }),
        };
      },
      transformResponse: (
        response: IResendRegistrationByUserConfirmationResult
      ) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    resetPassword: builder.mutation<IResetPasswordResult, IResetPasswordInput>({
      query: (variables: IResetPasswordInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resetPasswordQuery),
            variables: {
              input: {
                oldPassword: variables.oldPassword,
                password: variables.password,
              },
            },
          }),
        };
      },
      transformResponse: (response: IResetPasswordResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),





    confirmRegistrationByUser: builder.mutation<
      IConfirmRegistrationByUserResult,
      IConfirmRegistrationByUserInput
    >({
      query: (variables: IConfirmRegistrationByUserInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(confirmRegistrationByUserQuery),
            variables: {
              input: {
                token: variables.token,
                code: variables.code,
                resetPasswordToken: variables.resetPasswordToken,
                password: variables.password,
              },
            },
          }),
        };
      },
      transformResponse: (response: IConfirmRegistrationByUserResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    resendRegistrationByUser: builder.mutation<
      IResendRegistrationByUserConfirmationRequest,
      IResendRegistrationConfirmationInput
    >({
      query: (variables: IResendRegistrationConfirmationInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resendRegistrationConfirmationQuery),
            variables: {
              input: {
                email: variables.email,
              },
            },
          }),
        };
      },
      transformResponse: (
        response: IResendRegistrationByUserConfirmationRequest
      ) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    userUpdateOwn: builder.mutation<IUserUpdateOwnResult, IUserUpdateOwnInput>({
      query: (values: IUserUpdateOwnInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(userUpdateOwnQuery),
            variables: {
              input: values,
            },
          }),
        };
      },
      transformResponse: (response: IUserUpdateOwnResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: ["users", "user"],
    }),
  }),
});

export const {
  useUserListQuery,
  useLazyUserListQuery,
  useUserDetailOwnQuery,
  useLazyUserDetailOwnQuery,
  useUserDetailQuery,
  useLazyUserDetailQuery,

  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useRequestResetPasswordMutation,
  useConfirmRegistrationMutation,
  useResendRegistrationByUserConfirmationMutation,
  useResetPasswordMutation,
  useResetPasswordAfterRequestMutation,
  useConfirmRegistrationByUserMutation,
  useResendRegistrationByUserMutation,
  useUserUpdateOwnMutation,
  useRegistrationByUserMutation,
  useOrganizationCreateOwnMutation
} = authApi;
