/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Grid, IconButton, InputLabel, Stack, SvgIcon, SwipeableDrawer, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState, useRef } from "react";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { ICreateLeadStatusInput } from "corede-common-cocrm";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLeadStatusCreateMutation } from "../context/leadStatuses.api";
import { validateCreateLeadStatusesInput } from "../validations/create.validation";
import * as Icons from '@mui/icons-material';

interface ILeadStatusCreateDrawer {
    open: boolean
    setOpen: (open: boolean) => void
}

export const LeadStatusCreateDrawer = (props: ILeadStatusCreateDrawer) => {
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [visibleIcons, setVisibleIcons] = useState<string[]>([]);
    const iconsContainerRef = useRef<HTMLDivElement>(null);
    const [showSelectBox, setShowSelectBox] = useState("icon");
    const [leadStatusCreate, { data: leadStatusCreateData, isLoading: leadStatusCreateLoading, error: leadStatusCreateError }] = useLeadStatusCreateMutation();

    const filteredIcons = Object.keys(Icons).filter((iconName) =>
        iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        setVisibleIcons(filteredIcons.slice(0, 50));
    }, [searchQuery]);

    const handleShowMoreIcons = () => {
        setVisibleIcons((prevVisibleIcons) => {
            const currentLength = prevVisibleIcons.length;
            const nextIcons = filteredIcons.slice(0, currentLength + 50);
            return nextIcons;
        });
    };

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
        useFormik({
            initialValues: {
                input: {
                    name: '',
                    color: 'primary.lighter',
                    icon: 'CircleOutlined',
                },
            },
            enableReinitialize: true,
            validationSchema: validateCreateLeadStatusesInput,
            onSubmit: async (values: IGraphqlVariables<ICreateLeadStatusInput>) => {
                setLoading(true);
                leadStatusCreate({ input: values.input });
                setLoading(false);
            }
        });

    const handleIconSelect = (iconName: string) => {
        setFieldValue("input.icon", iconName);
    };

    const handleColorSelect = (color: string) => {
        setFieldValue("input.color", color);
    };

    useEffect(() => {
        if (leadStatusCreateData) {
            props.setOpen(false);
            enqueueSnackbar(t("crm.lead.leadStatuses.leadStatusCreateSuccess"), { variant: 'success' });
            resetForm();
        }
    }, [leadStatusCreateData, resetForm]);

    useEffect(() => {
        if (leadStatusCreateError) {
            console.log("leadStatusCreateError", (leadStatusCreateError as any)?.error?.message.en)
            enqueueSnackbar((leadStatusCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [leadStatusCreateError]);

    return (
        <SwipeableDrawer
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            anchor='right'
        >
            <Grid item xs={12} p={3}>
                <Typography variant="h4" gutterBottom>
                    {t("crm.lead.leadStatuses.createStatus")}
                </Typography>
                <Box sx={{ mt: 4, width: 400 }} />
                <Stack direction={"column"} justifyContent={"space-between"} height={"calc(100dvh - 120px)"}>
                    <Stack direction="column" gap={1} mt={1}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.leadStatuses.name")}*</InputLabel>
                                <TextField
                                    fullWidth
                                    name="input.name"
                                    value={(values as any).input.name}
                                    onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                                    helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.leadStatuses.icon")}*</InputLabel>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: "80px",
                                        border: "1px solid",
                                        borderColor: "grey.400",
                                        borderRadius: "16px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setShowSelectBox("icon")}
                                >
                                    <SvgIcon component={(Icons as any)[(values as any).input.icon]} sx={{ color: "primary.main", fontSize: "64px", opacity: 0.7 }} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.lead.leadStatuses.color")}*</InputLabel>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: "80px",
                                        border: "1px solid",
                                        borderColor: "grey.400",
                                        borderRadius: "16px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setShowSelectBox("color")}
                                >
                                    <Box sx={{ width: 64, height: 64, borderRadius: '50%', backgroundColor: (values as any).input.color }} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container width={400} bgcolor={"background."} borderRadius={1} sx={{ overflow: 'auto' }}>
                            <Grid item xs={12} mt={2} sx={{ border: '1px solid', borderColor: 'grey.400', borderRadius: '16px' }}>
                                {showSelectBox === "icon" && <Box sx={{ height: 300, overflow: 'auto', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'flex-start' }} ref={iconsContainerRef}>
                                    <Typography fontSize={"14px"} fontWeight={"bold"} textAlign={"center"} mt={1}>
                                        Select an Icon
                                    </Typography>
                                    <TextField
                                        placeholder="Search for an icon"
                                        variant="outlined"
                                        sx={{
                                            width: '70%',
                                            '& .MuiOutlinedInput-root': {
                                                height: 30,
                                                fontSize: '12px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                fontSize: '12px',
                                            }
                                        }}
                                        margin="normal"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <Box sx={{ maxHeight: 180, ml: 1, overflow: 'auto', mt: 1, display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                                        <Grid container spacing={1}>
                                            {visibleIcons.map((iconName) => {
                                                const IconComponent = (Icons as any)[iconName];
                                                return (
                                                    <Grid item xs={2} key={iconName}>
                                                        <IconButton onClick={() => handleIconSelect(iconName)}>
                                                            <IconComponent />
                                                        </IconButton>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                        {visibleIcons.length < filteredIcons.length && (
                                            <Button onClick={handleShowMoreIcons} sx={{ mt: 2, alignSelf: 'center' }}>
                                                {t("crm.lead.leadStatuses.showMore")}
                                            </Button>
                                        )}
                                    </Box>
                                </Box>}
                                {showSelectBox === "color" && <Box sx={{ height: 300, overflow: 'auto', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Typography fontSize={"14px"} fontWeight={"bold"} textAlign={"center"} mt={1}>
                                        Select Color
                                    </Typography>
                                    <Box sx={{ maxHeight: 240, ml: 1, overflow: 'auto', mt: 1, display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                                        <Grid container >
                                            {colors.map((color) => (
                                                <Grid item xs={2} key={color}>
                                                    <IconButton
                                                        onClick={() => handleColorSelect(color)}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: 32,
                                                                height: 32,
                                                                borderRadius: '50%',
                                                                backgroundColor: color,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Box>}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Grid container spacing={5} pt={1.5}>
                        <Grid item xs={12} >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleSubmit()}
                                disabled={loading || !isValid || leadStatusCreateLoading || !(values as any).input?.name}
                            >
                                {loading ? <CircularProgress size="1rem" /> : t("crm.lead.leadStatuses.create")}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Grid>
        </SwipeableDrawer>
    );
};


export const colors = [
    "primary.lighter",
    "secondary.lighter",
    "success.lighter",
    "error.lighter",
    "warning.lighter",
    "info.lighter",
    "primary.light",
    "secondary.light",
    "success.light",
    "error.light",
    "warning.light",
    "info.light",
    "primary.main",
    "secondary.main",
    "success.main",
    "error.main",
    "warning.main",
    "info.main",
    "primary.dark",
    "secondary.dark",
    "success.dark",
    "error.dark",
    "warning.dark",
    "info.dark",
    "primary.darker",
    "secondary.darker",
    "success.darker",
    "error.darker",
    "warning.darker",
    "info.darker",
];
