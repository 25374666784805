/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Skeleton, Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Button, TableContainer, useMediaQuery, CircularProgress, AccordionActions, FormControlLabel, styled, Chip, } from "@mui/material";
import { useAppDispatch } from "context";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { organizationalChartActions } from "../../../context";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  usePermissionListQuery, useRoleListQuery, useRoleCreateMutation,
  useRoleUpdateMutation, useRoleDeleteMutation
} from "../context/employees.api";
import { MyTab, OrganizationalChartHeader, OutlinedButton } from "components";
import { AddRounded, ArrowForwardIosSharp, DeleteOutlineRounded, ExpandCircleDown, ExpandMore, Update } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";

interface Permission {
  _id: string;
  action: string;
  subject: string;
  actionScope: string;
  description: string;
  domain: string;
  subdomain: string;
}


const groupBySubject = (permissions: Permission[]) => {
  return permissions.reduce((acc, permission) => {
    if (!acc[permission.subject]) {
      acc[permission.subject] = [];
    }
    acc[permission.subject].push(permission);
    return acc;
  }, {} as Record<string, Permission[]>);
};

const Roles = () => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const [tabKey, setTabKey] = useState(0);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [roleName, setRoleName] = useState<string>("");
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [expandAll, setExpandAll] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState<Record<string, boolean>>({});
  const { data: roleListData, isLoading: roleListLoading, error: roleListError } = useRoleListQuery({});
  const [roleCreate, { data: roleCreateData, isLoading: roleCreateLoading, error: roleCreateError }] = useRoleCreateMutation();
  const [roleUpdate, { data: roleUpdateData, isLoading: roleUpdateLoading, error: roleUpdateError }] = useRoleUpdateMutation();
  const [roleDelete, { data: roleDeleteData, isLoading: roleDeleteLoading, error: roleDeleteError }] = useRoleDeleteMutation();
  const { data: permissionListData, isLoading: permissionListLoading, error: permissionListError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["manage", "view", "import", "export"]
      }
    }
  } as any);
  const { data: permissionListAllData, isLoading: permissionListAllLoading, error: permissionListAllError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["import", "export", "list", "create", "update", "delete", "detail"]
      }
    }
  } as any);



  const roles = roleListData?.data || [];
  const permissions = permissionListData?.data || [];
  const permissionsAll = permissionListAllData?.data || [];

  const groupBySubject = (permissions: Permission[]) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.subject]) {
        acc[permission.subject] = [];
      }
      acc[permission.subject].push(permission);
      return acc;
    }, {} as Record<string, Permission[]>);
  };

  const groupByDomain = (permissions: Permission[]) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.domain]) {
        acc[permission.domain] = [];
      }
      acc[permission.domain].push(permission);
      return acc;
    }, {} as Record<string, Permission[]>);
  };

  const mainGroupedPermissions = groupByDomain(permissions as Permission[]);
  const groupedPermissions = groupBySubject(permissions as Permission[]);
  const groupedPermissionsAll = groupBySubject(permissionsAll as Permission[]);

  console.log(mainGroupedPermissions)
  console.log(groupedPermissions)
  console.log(groupedPermissionsAll)


  const handleAddRole = () => {
    roleCreate({
      input: {
        name: roleName,
        permissionIds: selectedPermissionIds
      }
    });
  }

  const handleRoleUpdate = () => {
    roleUpdate({
      input: {
        name: roleName,
        permissionIds: selectedPermissionIds
      },
      filter: {
        _id: selectedRoleId
      },
    })
  }

  const handleRoleDelete = () => {
    roleDelete({
      input: {
        _id: selectedRoleId
      },
    })
  }

  // SUCCESS

  useEffect(() => {
    if (roleCreateData) {
      enqueueSnackbar(t("crm.organizationalChart.employees.roleCreatedSuccess"), { variant: "success" });
      setRoleName(roles[0]?.name ?? "");
      setTabKey(prevKey => prevKey + 1);
    }
  }, [roleCreateData]);

  useEffect(() => {
    if (roleUpdateData) {
      enqueueSnackbar(t("crm.organizationalChart.employees.roleUpdatedSuccess"), { variant: "success" });
      setRoleName(roles[0]?.name ?? "");
      setTabKey(prevKey => prevKey + 1);
    }
  }, [roleUpdateData]);

  useEffect(() => {
    if (roleDeleteData) {
      enqueueSnackbar(t("crm.organizationalChart.employees.roleDeletedSuccess"), { variant: "success" });
      setRoleName(roles[0]?.name ?? "");
      setTabKey(prevKey => prevKey + 1);
    }
  }, [roleDeleteData]);

  // ERRORS

  useEffect(() => {
    if (permissionListError) {
      enqueueSnackbar(t("crm.organizationalChart.employees.errorFetchingPermissions"), { variant: "error" });
    }
  }, [permissionListError]);

  useEffect(() => {
    if (roleListError) {
      enqueueSnackbar(t("crm.organizationalChart.employees.errorFetchingRoles"), { variant: "error" });
    }
  }, [roleListError]);

  useEffect(() => {
    if (roleCreateError) {
      enqueueSnackbar(t("crm.organizationalChart.employees.errorCreatingRole"), { variant: "error" });
    }
  }, [roleCreateError]);

  useEffect(() => {
    if (roleUpdateError) {
      enqueueSnackbar(t("crm.organizationalChart.employees.errorUpdatingRole"), { variant: "error" });
    }
  }, [roleUpdateError]);

  useEffect(() => {
    if (roleDeleteError) {
      enqueueSnackbar(t("crm.organizationalChart.employees.errorDeletingRole"), { variant: "error" });
    }
  }, [roleDeleteError]);

  // INITIAL

  useEffect(() => {
    if (selectedRoleName) {
      setRoleName(selectedRoleName === t("crm.organizationalChart.employees.addRole") ? "" : selectedRoleName);
      setSelectedRoleId(roles.find((role) => role.name === selectedRoleName)?._id ?? "");
      setSelectedPermissionIds(roles.find((role) => role.name === selectedRoleName)?.permissions.map((permission) => permission._id) ?? []);
    }
  }, [selectedRoleName, roles]);

  useEffect(() => {
    setSelectedRoleName(roles[0]?.name ?? "");
  }, [roles]);

  return (
    <Grid item xs={12} p={3}>
      <HeaderBase
        title={t("crm.organizationalChart.employees.roles")}
        showBackButton
      />
      <Box sx={{ mt: 2 }} />
      <MyTab
        key={tabKey}
        labels={[...roles, { name: t("crm.organizationalChart.employees.addRole") }].map((role) => role.name)}
        setSelectedTab={setSelectedRoleName}
      />
      <Stack direction="row" justifyContent="space-between">
        {!(roleListLoading || permissionListLoading || roleCreateLoading || roleUpdateLoading || roleDeleteLoading) ?
          <Box sx={{ width: "100%" }} >
            <Stack direction={"row"} justifyContent={"flex-end"} sx={{ width: "100%", mb: 1, }}>
              <OutlinedButton
                title={expandAll ? "Collapse All" : "Expand All"}
                onClick={() => {
                  setExpandAll(!expandAll);
                  const newExpandedState = Object.keys(mainGroupedPermissions).reduce((acc, domain) => {
                    acc[domain] = !expandAll;
                    return acc;
                  }, {} as Record<string, boolean>);
                  setExpandedAccordions(newExpandedState);
                }}
                leftIcon={expandAll ? <ExpandCircleDown sx={{ mr: 1, transform: "rotate(180deg)", transition: "all 0.3s" }} /> : <ExpandCircleDown sx={{ mr: 1, transition: "all 0.3s" }} />}
              />
            </Stack>
            {Object.keys(mainGroupedPermissions).reverse().map((domain, index) => (
              <Accordion key={domain} expanded={expandedAccordions[domain] || false} className="accordion-domain"
                onChange={() => {
                  setExpandedAccordions(prevState => ({
                    ...prevState,
                    [domain]: !prevState[domain]
                  }));
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel-${domain}-content`}
                  id={`panel-${domain}-header`}
                >
                  <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                    <Typography fontSize={"large"} fontWeight={"bold"}>
                      {domain?.slice(0, 1).toUpperCase() + domain?.slice(1)}
                    </Typography>
                    {!downMd && <Stack direction="row" spacing={1}>
                      {mainGroupedPermissions[domain].reduce((uniqueSubdomains: string[], permission: Permission) => {
                        if (!uniqueSubdomains.includes(permission.subdomain)) {
                          uniqueSubdomains.push(permission.subdomain);
                        }
                        return uniqueSubdomains;
                      }, []).map(subdomain => (
                        <Chip
                          key={subdomain}
                          label={subdomain?.slice(0, 1).toUpperCase() + subdomain?.slice(1)}
                          // size="small"
                          sx={{ fontWeight: 'bold', opacity: 0.7 }}
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </Stack>}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction={"row"} justifyContent={"flex-end"} sx={{ width: "100%", mb: 1 }}>
                    <Stack width={"175px"}>
                      <Typography fontSize={"small"} fontWeight={"bold"}>
                        Organization Level
                      </Typography>
                    </Stack>
                    <Stack width={"175px"}>
                      <Typography fontSize={"small"} fontWeight={"bold"}>
                        Department Level
                      </Typography>
                    </Stack>
                    <Stack width={"175px"}>
                      <Typography fontSize={"small"} fontWeight={"bold"}>
                        User Level
                      </Typography>
                    </Stack>
                  </Stack>
                  {Object.keys(groupedPermissions)
                    .filter((subject) => groupedPermissions[subject][0]?.domain === domain)
                    .map((subject) => (
                      <Accordion key={subject} expanded={false}>
                        <AccordionSummary
                          expandIcon={null}                     >
                          <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                            <Typography fontSize={"medium"} fontWeight={"bold"}>
                              {subject?.slice(0, 1).toUpperCase() + subject?.slice(1)}
                            </Typography>
                            <Stack direction="row" alignItems="center" justifyContent={"flex-end"}>
                              <Stack direction="row" spacing={4}>
                                <Stack width={"150px"}>
                                  {groupedPermissions[subject].filter((permission) => permission.actionScope === 'org').map((permission) => {
                                    const isManage = permission.action === 'manage';
                                    const isView = permission.action === 'view';
                                    const relatedPermissions = isManage
                                      ? ['create', 'delete', 'update']
                                      : isView
                                        ? ['list', 'detail']
                                        : [];

                                    const relatedPermissionIds = groupedPermissionsAll[subject]
                                      ?.filter((p) => relatedPermissions.includes(p.action) && p.actionScope === permission.actionScope)
                                      .map((p) => p._id) || [];

                                    const allRelatedChecked = relatedPermissionIds.every((id) => selectedPermissionIds.includes(id));
                                    const someRelatedChecked = relatedPermissionIds.some((id) => selectedPermissionIds.includes(id));
                                    const checkState = isManage || isView ? (allRelatedChecked ? true : someRelatedChecked ? 'indeterminate' : false) : selectedPermissionIds.includes(permission._id);

                                    return (
                                      <FormControlLabel
                                        key={permission._id}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                        control={
                                          <Checkbox
                                            checked={checkState === true}
                                            indeterminate={checkState === 'indeterminate'}
                                            onChange={() => {
                                              let newSelectedPermissions = [...selectedPermissionIds];

                                              if (checkState === true) {
                                                newSelectedPermissions = newSelectedPermissions.filter((id) => id !== permission._id && !relatedPermissionIds.includes(id));
                                              } else {
                                                newSelectedPermissions = [...new Set([...newSelectedPermissions, permission._id, ...relatedPermissionIds])];
                                              }

                                              setSelectedPermissionIds(newSelectedPermissions);
                                            }}
                                          />
                                        }
                                        label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                        sx={{
                                          ".MuiFormControlLabel-label": {
                                            fontSize: "small",
                                            fontWeight: "bold",
                                          }
                                        }}
                                      />
                                    );
                                  })}
                                </Stack>
                                <Stack width={"150px"}>
                                  {groupedPermissions[subject].filter((permission) => permission.actionScope === 'dep').map((permission) => {
                                    const isManage = permission.action === 'manage';
                                    const isView = permission.action === 'view';
                                    const relatedPermissions = isManage
                                      ? ['create', 'delete', 'update']
                                      : isView
                                        ? ['list', 'detail']
                                        : [];

                                    const relatedPermissionIds = groupedPermissionsAll[subject]
                                      ?.filter((p) => relatedPermissions.includes(p.action) && p.actionScope === permission.actionScope)
                                      .map((p) => p._id) || [];

                                    const allRelatedChecked = relatedPermissionIds.every((id) => selectedPermissionIds.includes(id));
                                    const someRelatedChecked = relatedPermissionIds.some((id) => selectedPermissionIds.includes(id));
                                    const checkState = isManage || isView ? (allRelatedChecked ? true : someRelatedChecked ? 'indeterminate' : false) : selectedPermissionIds.includes(permission._id);

                                    return (
                                      <FormControlLabel
                                        key={permission._id}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                        control={
                                          <Checkbox
                                            checked={checkState === true}
                                            indeterminate={checkState === 'indeterminate'}
                                            onChange={() => {
                                              let newSelectedPermissions = [...selectedPermissionIds];

                                              if (checkState === true) {
                                                newSelectedPermissions = newSelectedPermissions.filter((id) => id !== permission._id && !relatedPermissionIds.includes(id));
                                              } else {
                                                newSelectedPermissions = [...new Set([...newSelectedPermissions, permission._id, ...relatedPermissionIds])];
                                              }

                                              setSelectedPermissionIds(newSelectedPermissions);
                                            }}
                                          />
                                        }
                                        label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                        sx={{
                                          ".MuiFormControlLabel-label": {
                                            fontSize: "small",
                                            fontWeight: "bold",
                                          }
                                        }}
                                      />
                                    );
                                  })}
                                </Stack>
                                <Stack width={"150px"}>
                                  {groupedPermissions[subject].filter((permission) => permission.actionScope === 'own').map((permission) => {
                                    const isManage = permission.action === 'manage';
                                    const isView = permission.action === 'view';
                                    const relatedPermissions = isManage
                                      ? ['create', 'delete', 'update']
                                      : isView
                                        ? ['list', 'detail']
                                        : [];

                                    const relatedPermissionIds = groupedPermissionsAll[subject]
                                      ?.filter((p) => relatedPermissions.includes(p.action) && p.actionScope === permission.actionScope)
                                      .map((p) => p._id) || [];

                                    const allRelatedChecked = relatedPermissionIds.every((id) => selectedPermissionIds.includes(id));
                                    const someRelatedChecked = relatedPermissionIds.some((id) => selectedPermissionIds.includes(id));
                                    const checkState = isManage || isView ? (allRelatedChecked ? true : someRelatedChecked ? 'indeterminate' : false) : selectedPermissionIds.includes(permission._id);

                                    return (
                                      <FormControlLabel
                                        key={permission._id}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                        control={
                                          <Checkbox
                                            checked={checkState === true}
                                            indeterminate={checkState === 'indeterminate'}
                                            onChange={() => {
                                              let newSelectedPermissions = [...selectedPermissionIds];

                                              if (checkState === true) {
                                                newSelectedPermissions = newSelectedPermissions.filter((id) => id !== permission._id && !relatedPermissionIds.includes(id));
                                              } else {
                                                newSelectedPermissions = [...new Set([...newSelectedPermissions, permission._id, ...relatedPermissionIds])];
                                              }

                                              setSelectedPermissionIds(newSelectedPermissions);
                                            }}
                                          />
                                        }
                                        label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                        sx={{
                                          ".MuiFormControlLabel-label": {
                                            fontSize: "small",
                                            fontWeight: "bold",
                                          }
                                        }}
                                      />
                                    );
                                  })}
                                </Stack>
                              </Stack>
                            </Stack>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* Burada subject'e ait alt permission'ları göstereceğiz */}
                          {/* <Stack direction="row" spacing={4}>
                            <Stack width={"150px"}>
                              {groupedPermissionsAll[subject]?.filter((permission) => permission.actionScope === 'org').map((permission) => (
                                <FormControlLabel
                                  key={permission._id}
                                  control={
                                    <Checkbox
                                      checked={selectedPermissionIds.includes(permission._id)}
                                      onChange={() => {
                                        const isSelected = selectedPermissionIds.includes(permission._id);
                                        let newSelectedPermissions = isSelected
                                          ? selectedPermissionIds.filter((id) => id !== permission._id)
                                          : [...selectedPermissionIds, permission._id];

                                        setSelectedPermissionIds(newSelectedPermissions);
                                      }}
                                    />
                                  }
                                  label={`${permission.action} (org)`}
                                />
                              ))}
                            </Stack>
                            <Stack width={"150px"}>
                              {groupedPermissionsAll[subject]?.filter((permission) => permission.actionScope === 'dep').map((permission) => (
                                <FormControlLabel
                                  key={permission._id}
                                  control={
                                    <Checkbox
                                      checked={selectedPermissionIds.includes(permission._id)}
                                      onChange={() => {
                                        const isSelected = selectedPermissionIds.includes(permission._id);
                                        let newSelectedPermissions = isSelected
                                          ? selectedPermissionIds.filter((id) => id !== permission._id)
                                          : [...selectedPermissionIds, permission._id];

                                        setSelectedPermissionIds(newSelectedPermissions);
                                      }}
                                    />
                                  }
                                  label={`${permission.action} (dep)`}
                                />
                              ))}
                            </Stack>
                          </Stack> */}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </AccordionDetails>
              </Accordion>
            ))}











            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <TextField
                required
                size="small"
                placeholder={t("crm.organizationalChart.employees.roleName")}
                variant="outlined"
                fullWidth
                margin="normal"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
              />
              {(selectedRoleName === t("crm.organizationalChart.employees.addRole") || selectedRoleName === "") && <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ height: 42, mt: "16px", mb: "8px", pl: 1, pr: 2 }}
                onClick={handleAddRole}
                disabled={roleCreateLoading || !roleName || selectedPermissionIds.length === 0}
              >
                {roleCreateLoading ? <CircularProgress size={"1rem"} /> :
                  <>
                    <AddRounded sx={{ mr: 1 }} />{t("crm.organizationalChart.employees.addRole")}
                  </>
                }
              </Button>}
              {selectedRoleName !== t("crm.organizationalChart.employees.addRole") && selectedRoleName !== "" && <Stack direction={"row"} gap={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: 42, mt: "16px", mb: "8px", pl: 2, pr: 2 }}
                  onClick={handleRoleUpdate}
                  disabled={roleUpdateLoading || !roleName || selectedPermissionIds.length === 0}
                >
                  {roleCreateLoading ? <CircularProgress size={"1rem"} /> :
                    <>
                      <Update sx={{ mr: { xs: 0, md: 1 } }} />{!downMd && t("crm.organizationalChart.employees.updateRole")}
                    </>
                  }
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ height: 42, mt: "16px", mb: "8px", pl: 2, pr: 2 }}
                  onClick={handleRoleDelete}
                  disabled={roleDeleteLoading}
                >
                  {roleDeleteLoading ? <CircularProgress size={"1rem"} /> :
                    <>
                      <DeleteOutlineRounded sx={{ mr: { xs: 0, md: 1 } }} />{!downMd && t("crm.organizationalChart.employees.deleteRole")}
                    </>
                  }
                </Button>
              </Stack>}
            </Stack>
          </Box>
          : <TableContainer
            sx={{
              borderRadius: 2,
              mt: 1
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    borderRight: "1px solid",
                    borderColor: "primary.light",
                    width: '50%'
                  }}>{t("crm.organizationalChart.employees.subject")}</TableCell>
                  <TableCell sx={{ width: '50%' }}>{t("crm.organizationalChart.employees.capabilities")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3, 4, 5].map((key) => (
                  <Stack
                    key={key}
                    direction="column"
                    justifyContent="space-between"
                    width={340}
                    alignItems={"center"}
                    p={2}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={340}
                      height={30}
                      sx={{ borderRadius: 2 }}
                    />
                  </Stack>
                ))}
              </TableBody>

            </Table>
          </TableContainer>

        }
      </Stack >

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >

      </Stack>

    </Grid >
  );
};

export default Roles;


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));