

import { ReportsDomainLayout } from "../layouts";
import { ReportsRouter } from "../subdomains/reports/routes/ReportsRouter";
import { reportsDomainRoutes } from "./reports.base.route";
import { Route, Routes } from "react-router-dom";

export const ReportsDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ReportsDomainLayout />}>
        <Route index element={<ReportsRouter />} />
        <Route
          path={`${reportsDomainRoutes.subdomains.reports.base}/*`}
          element={<ReportsRouter />}
        />
        {/* Todo 
         <Route index element={<EmployeesRouter />} />
         <Route
          path={`${customerDomainRoutes.subdomains.employees.base}/*`}
          element={<EmployeesRouter />}
        /> */}
      </Route>
    </Routes>
  );
};
