/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, CircularProgress, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getCurrentLanguage } from "localization";
import { useTranslation } from "react-i18next";
import { IGraphqlVariables, unknownError } from "corede-common";
import { IUserCreateInput, UserType } from "corede-common-cocrm";
import { validateCreateUserInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { useRoleListQuery, useUserCreateMutation } from "../context/employees.api";
import { enqueueSnackbar } from "notistack";

const Create = () => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries
  const { data: roleListData, isLoading: roleListLoading, error: roleListError } = useRoleListQuery({});
  const [userCreate, { data: userCreateData, isLoading: userCreateLoading, error: userCreateError }] = useUserCreateMutation();

  // mutations

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
    useFormik({
      initialValues: {
        input: {
          type: UserType.org,
          email: "",
          name: "",
          surname: "",
          roleIds: undefined,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateUserInput,
      onSubmit: async (values: IGraphqlVariables<IUserCreateInput>) => {
        setLoading(true);
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values,
          });
        await userCreate(transformedValues as IGraphqlVariables<IUserCreateInput>)
        setLoading(false);
      }
    });


  // useEffects.success

  useEffect(() => {
    if (userCreateData) {
      console.log("userCreateData", userCreateData);
      enqueueSnackbar(t("crm.organizationalChart.employees.createUserSuccess"), { variant: 'success' });
      resetForm();
    }
  }, [userCreateData, resetForm]);


  // useEffects.error

  useEffect(() => {
    if (userCreateError) {
      console.log("userCreateError", (userCreateError as any)?.error?.message.en)
      enqueueSnackbar((userCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [userCreateError]);

  useEffect(() => {
    if (roleListError) {
      enqueueSnackbar((roleListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [roleListError]);


  return (
    <Grid item xs={12} p={3}>
      <Typography variant="h4" gutterBottom>
        {t("crm.organizationalChart.employees.create")}
      </Typography>
      <Box sx={{ mt: 4, width: 400 }} />
      <Stack direction="column" gap={1} mt={1}>
        <Grid container spacing={5}>
          <Grid item xs={12} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.employees.name")}*</InputLabel>
            <TextField
              fullWidth
              name="input.name"
              value={(values as any).input.name}
              onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
              helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
            />
          </Grid>

        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.employees.surname")}*</InputLabel>
            <TextField
              fullWidth
              name="input.surname"
              value={(values as any).input.surname}
              onChange={(e) => setFieldValue("input.surname", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.surname && (touched as any)?.input?.surname)}
              helperText={(touched as any)?.input?.surname && (errors as any)?.input?.surname}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12} >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.employees.email")}*</InputLabel>
            <TextField
              fullWidth
              name="input.email"
              value={(values as any).input.email}
              onChange={(e) => setFieldValue("input.email", e.target.value)}
              onBlur={handleBlur}
              error={!!((errors as any)?.input?.email && (touched as any)?.input?.email)}
              helperText={(touched as any)?.input?.email && (errors as any)?.input?.email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12}  >
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.organizationalChart.employees.roles")}</InputLabel>
            <Autocomplete
              id="roleIds"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              getOptionLabel={(option) => option.name}
              options={roleListData?.data || []}
              loading={roleListLoading}
              renderInput={(params) => <TextField {...params} />}
              multiple
              value={
                values.input?.roleIds && values.input.roleIds.length > 0
                  ? roleListData?.data?.filter((role) =>
                    (values as any).input.roleIds.includes(role._id)
                  )
                  : []
              }
              onChange={(e, value) => {
                setFieldValue(
                  "input.roleIds",
                  value?.map((role) => role._id) || []
                );
              }}
            />

          </Grid>
        </Grid>

        <Grid container spacing={5} pt={1.5}>
          <Grid item xs={12} >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleSubmit()}
              disabled={loading || !isValid || userCreateLoading || !(values as any).input?.name}
            >
              {loading ? <CircularProgress size="1rem" /> : t("crm.organizationalChart.employees.create")}
            </Button>
          </Grid>
        </Grid>


      </Stack >
    </Grid >
  );
};

export default Create;
