import { ArrowBackIos, ViewKanbanOutlined, ViewTimelineOutlined } from "@mui/icons-material"
import { Stack, Typography, Box, Button, Theme, useMediaQuery, ToggleButton, ToggleButtonGroup, IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"

type IHeaderBase = {
    toggleButton?: {
        value: string
        action: any
    }
    showBackButton?: boolean
    rightButton?: {
        icon?: JSX.Element
        title?: string
        onClick?: () => void
    }
    righButton2?: {
        icon?: JSX.Element
        title?: string
        onClick?: () => void
    }
    title?: string
}


export const HeaderBase = (props: IHeaderBase) => {

    const navigate = useNavigate();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} mb={2}>
        <Stack
            direction={{ xs: "column", md: "row" }}
            gap={2}
            alignItems="center"
        >
            <Stack direction={"row"} alignItems="center">
                {props.showBackButton && (
                    <IconButton
                        onClick={() => navigate(-1)}
                        color="primary"
                        sx={{pl:1}}
                    >
                        <ArrowBackIos
                            fontSize="small"
                            color="primary"
                            sx={{ pl:0.5, mr: -0.5}}
                        />
                    </IconButton>
                )}
                <Typography sx={{ fontSize: { xs: "20px", md: "24px" }, fontWeight: "bold" }}>
                    {props.title}
                </Typography>
            </Stack>
            {props.toggleButton && <Box display="flex" alignItems="center">
                <ToggleButtonGroup
                    value={props?.toggleButton?.value}
                    sx={{ ml: 2 }}
                    size="small"
                    exclusive
                    onChange={(_, value) => props?.toggleButton?.action(value)}
                >
                    <ToggleButton value="list">
                        <ViewTimelineOutlined />
                    </ToggleButton>
                    <ToggleButton value="kanban">
                        <ViewKanbanOutlined />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>}
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={1}>
            {props.rightButton && <Button
                variant="outlined"
                color="primary"
                sx={{ width: { xs: 90, md: "auto" }, height: 40, fontSize: { xs: "11px", md: "13px" } }}
                onClick={() => props.rightButton?.onClick?.()}
            >
                {!downMd && props.rightButton?.icon}
                {props.rightButton?.title}
            </Button>}
            {props.righButton2 && <Button
                variant="outlined"
                color="primary"
                sx={{ width: { xs: 90, md: "auto" }, height: 40, fontSize: { xs: "11px", md: "13px" } }}
                onClick={() => props.righButton2?.onClick?.()}
            >
                {!downMd && props.righButton2?.icon}
                {props.righButton2?.title}
            </Button>}
        </Stack>
    </Stack>
}
