/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, CircularProgress, Stack, IconButton, TextField } from "@mui/material";
import { useFormik } from "formik";
import { resetPasswordAfterRequestValidationSchema } from 'utils/validations';
import { AuthLayout } from "../layouts";
import { useRegistrationByUserMutation } from "../context";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import LoginCo from "assets/images/login-co.png";
import { routes } from "../routes/routes";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useTranslation } from 'react-i18next';

const CreatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registrationByUser, { data, isLoading, error }] = useRegistrationByUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [visibility, setVisibility] = useState<string[]>([]);
  const token = new URLSearchParams(useLocation().search).get('token');
  const resetToken = new URLSearchParams(useLocation().search).get('reset');
  const [otp, setOtp] = useState('');
  const code = new URLSearchParams(useLocation().search).get('code');

  const handleChangeVisibility = (field: string) => {
    if (visibility.includes(field)) {
      setVisibility(visibility.filter(item => item !== field));
    } else {
      setVisibility([...visibility, field]);
    }
  };

  const { values, handleSubmit, setFieldValue, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: resetPasswordAfterRequestValidationSchema,
    initialValues: {
      code: code,
      password: "",
      passwordAgain: "",
    },
    onSubmit: async (values) => {
      try {
        await registrationByUser({
          input: {
            code: values.code,
            token: token,
            resetPasswordToken: resetToken,
            password: values.password
          }
        });
      } catch (error) {
        enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t("auth.passwordResetSuccess"), { variant: 'success' });
      navigate(routes.auth.login);
    }
  }, [data, enqueueSnackbar, navigate]);

  useEffect(() => {
    setFieldValue('code', code);
    setOtp(code ?? '');
  }, [code, setFieldValue]);

  return (
    <AuthLayout>
      <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>
          {/* Optional intro text */}
        </Typography>
        <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>
          <Box display={{ xs: 'flex', md: 'none' }} height={100} />
          <Box
            sx={{
              width: { xs: '100%', md: '80%', lg: '60%' },
              height: "80vh",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              alignItems: 'center',
              gap: 2,
            }}
            px={{ xs: 1, md: 7 }}
          >
            <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pt={2}>
              <Typography fontSize={32} fontWeight={500} textAlign={"center"}>
                {t("auth.createPasswordTitle")}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pb={2}>
              <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
                {t("auth.enterNewPassword")}
              </Typography>
            </Box>

            <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} alignItems={"center"}>
              <form onSubmit={handleSubmit as any} style={{ width: '100%' }}>
                <Box display={"flex"} flexDirection={"column"} gap={1.5} alignItems={"center"}>
                  <MuiOtpInput value={otp} length={6} inputMode="numeric" autoFocus={true} onComplete={() => null} />
                  <TextField
                    placeholder={t("auth.password")}
                    type={visibility.includes("password") ? 'text' : 'password'}
                    value={values.password}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                    error={!!errors.password && touched.password}
                    helperText={(errors.password && touched.password) ? errors.password : ""}
                    onBlur={handleBlur("password")}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <IconButton onClick={() => handleChangeVisibility("password")}>
                          {visibility.includes("password") ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }}
                  />
                  <TextField
                    placeholder={t("auth.passwordAgain")}
                    type={visibility.includes("passwordAgain") ? 'text' : 'password'}
                    value={values.passwordAgain}
                    onChange={(e) => setFieldValue("passwordAgain", e.target.value)}
                    error={!!errors.passwordAgain && touched.passwordAgain}
                    helperText={(errors.passwordAgain && touched.passwordAgain) ? errors.passwordAgain : ""}
                    onBlur={handleBlur("passwordAgain")}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <IconButton onClick={() => handleChangeVisibility("passwordAgain")}>
                          {visibility.includes("passwordAgain") ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit as any}
                    disabled={!!(isLoading || errors.password || errors.passwordAgain)}
                    sx={{ fontSize: 14, height: 56 }}
                  >
                    {(isLoading) ? <CircularProgress size="1rem" /> : t("auth.createPassword")}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>

        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
          {t("auth.alreadyHaveAccount")} <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.login)}> {t("auth.login")}</span>
        </Typography>
      </Stack>
    </AuthLayout>
  );
}

export default CreatePassword;
