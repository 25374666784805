import { Add, PersonAdd, RecentActorsRounded } from "@mui/icons-material"
import { Stack, Typography, Box, AvatarGroup, Avatar, IconButton, Button, Theme, useMediaQuery } from "@mui/material"
import { rolesEmployeesRoute } from "apps/crm/domains/02-organizationalChart/routes/organizationalChart.base.route"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


type Props = {
    userListData: any
    setOpenCreateUserDrawer: any
    setOpenRolesDrawer: any
}

export const OrganizationalChartHeader = (props: Props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
        <Stack
            direction={{ xs: "column", md: "row" }}
            gap={2}
            alignItems="center"
        >
            <Stack direction={"column"} alignItems={{ xs: "center", md: "flex-start" }}>
                <Typography sx={{ fontSize: { xs: "20px", md: "24px" }, fontWeight: "bold" }}>
                    {t("crm.organizationalChart.employees.organizationChart")}
                </Typography>
                <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, fontWeight: "medium" }}>
                    {props.userListData?.count} {(props.userListData as any)?.count > 1 ? t("crm.organizationalChart.employees.employees") : t("crm.organizationalChart.employees.employee")}
                    {/* Employee Employees */}
                </Typography>
            </Stack>
            <Box display="flex" alignItems="center">
                <AvatarGroup
                    max={5}
                    sx={{
                        "& .MuiAvatar-root": {
                            border: "2px solid white",
                            width: 35,
                            height: 35,
                            fontSize: "14px",
                            backgroundColor: "background.main",

                        },
                        "& .MuiAvatarGroup-avatar:nth-of-type(1)": {
                            zIndex: 4,
                        },
                        "& .MuiAvatarGroup-avatar:nth-of-type(2)": {
                            zIndex: 3,
                        },
                        "& .MuiAvatarGroup-avatar:nth-of-type(3)": {
                            zIndex: 2,
                        },
                        "& .MuiAvatarGroup-avatar:nth-of-type(4)": {
                            zIndex: 1,
                        },
                    }}
                >
                    {props.userListData?.data?.map((user: any, index: number) => (
                        <Avatar key={index} alt={user?.name} src={user?.avatar} />
                    ))}
                </AvatarGroup>
                <IconButton
                    sx={{ border: "2px solid", borderColor: "primary.light", width: 35, height: 35, ml: 1, color: "primary.main" }}
                    onClick={() => props.setOpenCreateUserDrawer(true)}
                >
                    <Add />
                </IconButton>
            </Box>
        </Stack>
        <Stack direction="row" alignItems={"center"} gap={1}>
            <Button
                variant="outlined"
                color="primary"
                sx={{ width: { xs: 90, md: "auto" }, height: 40, textAlign: "left", fontSize: { xs: "11px", md: "13px", lineHeight: "15px" } }}
                onClick={() => props.setOpenCreateUserDrawer(true)}
            >
                {!downMd && <PersonAdd sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />}
                {t("crm.organizationalChart.employees.new")} <br /> {t("crm.organizationalChart.employees.employee")}
            </Button>
            <Button
                variant="outlined"
                color="primary"
                sx={{ width: { xs: 90, md: "auto" }, height: 40, fontSize: { xs: "11px", md: "13px" } }}
                onClick={() => navigate(rolesEmployeesRoute())}
            >
                {!downMd && <RecentActorsRounded sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />}
                {t("crm.organizationalChart.employees.roles")}
            </Button>
        </Stack>
    </Stack>
}
