
export const productsAndServicesDomainRoutes = {
  base: "productsAndServices",
  subdomains: {
    products: {
      base: "products",
    },
    services: {
      base: "services",
    },
  },
};


export const baseProductsAndServicesRoute = (): string => `/${productsAndServicesDomainRoutes.base}/`;
export const listProductsRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.products.base}`;
export const listServicesRoute = (): string =>
  `/${productsAndServicesDomainRoutes.base}/${productsAndServicesDomainRoutes.subdomains.services.base}`;
