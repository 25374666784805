import { Tabs, Tab, styled } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

interface IStyledTabs {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface IStyledTab {
    label: string;
}

interface IMyTab {
    labels: string[];
    setSelectedTab?: (role: string) => void;
}

const StyledTabs = styled((props: IStyledTabs) => (
    <Tabs
        {...props}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 60,
        width: '100%',
        // marginTop: -7,
        // marginBottom: 7,
        backgroundColor: '#F43F5E',
    },
});



const StyledTab = styled((props: IStyledTab) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    minWidth: '40px',
    // maxWidth: '120px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 0.4,
    marginRight: theme.spacing(1),
    paddingLeft: 6,
    paddingRight: 6,
    minHeight: 50,
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
        color: theme.palette.secondary.main,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));



export const MyTab = ({ labels, setSelectedTab }: IMyTab) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (setSelectedTab) {
            setSelectedTab(labels[newValue]);
        }
    };


    return <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
    >
        {
            labels.map((label: string) => <StyledTab key={label} label={label} />)
        }
    </StyledTabs>
}
