import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { resources } from "./resouces";
import { Language } from "corede-common";

const savedLanguage = localStorage.getItem('language') as Language || Language.en;

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: savedLanguage,
    fallbackLng: Language.en,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;



export function changeLanguage(lang: Language) {
  localStorage.setItem('language', lang);
  i18n.changeLanguage(lang);

}

export function getCurrentLanguage(): Language {
  return i18n.language as Language;
}

export function getTranslatedText(key: string): string {
  return i18n.t(key);
}

export function convertEnums<T extends object>(enumObj: T): { [key in keyof T]: string } {
  const result: { [key in keyof T]: string } = {} as { [key in keyof T]: string };

  // Enum değerleri üzerinden döngü ile geçer
  for (const key of Object.keys(enumObj) as Array<keyof T>) {
    if (Object.prototype.hasOwnProperty.call(enumObj, key)) {
      // Enum'un değerini alır ve i18next ile lokalize eder
      const enumValue = enumObj[key];
      // Burada ':' yerine '.' kullanarak anahtar zincirini hedefliyoruz
      result[key] = i18n.t(`enums.${enumValue}`);
    }
  }

  return result;
}