
export const preSalesDomainRoutes = {
  base: "preSales",
  subdomains: {
    estimates: {
      base: "estimates",
    },
    proposals: {
      base: "proposals",
    },
  },
};


export const basePreSalesRoute = (): string => `/${preSalesDomainRoutes.base}/`;
export const listEstimatesRoute = (): string =>
  `/${preSalesDomainRoutes.base}/${preSalesDomainRoutes.subdomains.estimates.base}`;
export const listProposalsRoute = (): string =>
  `/${preSalesDomainRoutes.base}/${preSalesDomainRoutes.subdomains.proposals.base}`;
