import crmLocale from "../../apps/crm/locales/tr";
import authLocale from "../../apps/auth/locales/tr";
import leadPlusLocale from "../../apps/leadPlus/locales/tr";
import communityLocale from "../../apps/community/locales/tr";
import marketingLocale from "../../apps/marketing/locales/tr";
import windowLocale from "../../apps/window/locales/tr";

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  enums: {
    neutral: "Nötr",
    cold: "Soğuk",
    warm:  "Ilık",
    hot: "Sıcak",
    unqualified : 'Niteliksiz',
    IQL : 'IQL (Bilgi)',
    MQL : 'MQL (Pazarlama)',
    SQL : 'SQL (Satış)',
    en: "İngilizce",
    fr: "Fransızca",
    de: "Almanca",
    it: "İtalyanca",
    pt: "Portekizce",
    ru: "Rusça",
    es: "İspanyolca",
    tr: "Türkçe",
  },
  aboutUs: "Hakkımızda",
  termsAndConditions: "Şartlar ve Koşullar",
  privacyPolicy: "Gizlilik Politikası",
  cookiesPolicy: "Çerez Politikası",
  resources: "Kaynaklar",
  rowsPerPage: "Sayfa başına satır",
  chartUpdateSuccess: "Grafik başarıyla güncellendi!",

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  fullNameIsRequired: "Ad soyad gereklidir",
  emailIsRequired: "Email gereklidir",
  emailIsInvalid: "Email yanliş girildi",
  nameIsRequired: "İsim zorunludur",
  titleIsRequired: "Başlık zorunludur",
  profileImageIsRequired: "Profil resmi gereklidir",
  descriptionIsRequired: "Açıklama zorunludur",
  phoneNumberIsRequired: "Telefon numarası gereklidir",
  quotaYupIsRequired: "Kota gereklidir",
  dateYupIsRequired: "Tarih gereklidir",
  statusYupIsRequired: "Durum gereklidir",
  locationYupIsRequired: "Konum gereklidir",
  detailsIsRequired: "Detaylar gereklidir",
  surnameIsRequired: "Soyadı gereklidir",
  birthDateIsRequired: "Doğum tarihi gereklidir",
  genderIsRequired: "Cinsiyet gereklidir",
  countryIsRequired: "Ülke gereklidir",
  cityIsRequired: "Şehir gereklidir",
  addressIsRequired: "Adres gereklidir",
  contentIsRequired: "İçerik gereklidir",
  readingTimeIsRequired: "Okuma süresi gereklidir",
  tagsIsRequired: "Etiketler gereklidir",
  referencesIsRequired: "Referanslar gereklidir",
  relatedVideoIsRequired: "İlgili video gereklidir",
  authorIsRequired: "Yazar gereklidir",
  categoryIsRequired: "Kategori gereklidir",
  targetCategoryIsRequired: "Hedef kategori gereklidir",
  languageIsRequired: "Dil gereklidir",
  domainIsRequired: "Alan gereklidir",
  targetIsRequired: "Hedef gereklidir",
  titleIsTooShort25: "Başlık çok kısa, minimum uzunluk 25",
  titleIsTooLong50: "Başlık çok uzun, maksimum uzunluk 50",
  contentIsTooShort50: "İçerik çok kısa, minimum uzunluk 50",
  contentIsTooLong250: "İçerik çok uzun, maksimum uzunluk 250",
  descriptionIsTooShort400: "Açıklama çok kısa, minimum uzunluk 400",
  descriptionIsTooLong4000: "Açıklama çok uzun, maksimum uzunluk 4000",
  quotaMustBePositive: "Kota pozitif olmalıdır",
  quotaMustBeMultipleOf10: "Kota 10'un katı olmalıdır",
  participantsIsRequired: "Katılımcılar gereklidir",
  startDateIsRequired: "Başlangıç tarihi gereklidir",
  durationIsRequired: "Süre gereklidir",
  participationLinkIsRequired: "Katılım linki gereklidir",
  lastApplicationDateIsRequired: "Son başvuru tarihi gereklidir",
  statusIsRequired: "Durum gereklidir",
  locationIsRequired: "Konum gereklidir",
  typeIsRequired: "Tip gereklidir",
  durationMustBeMultipleOf5: "Süre 5'in katı olmalıdır",
  durationMustBePositive: "Süre pozitif olmalıdır",
  stateIsRequired: "İlçe gereklidir",
  positionIsRequired: "Pozisyon gereklidir",
  companyIsRequired: "Şirket adı gereklidir",
  sectorIsRequired: "Bölüm gereklidir",
  qualificationIsRequired: "Derece gereklidir",

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: "İptal",
  save: "Kaydet",
  update: "Güncelle",
  delete: "Sil",
  create: "Oluştur",
  add: "Ekle",
  close: "Kapat",

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: "İşlemler",
  title: "Başlık",
  content: "İçerik",
  category: "Kategori",
  target: "Hedef",
  status: "Durum",
  date: "Tarih",
  location: "Konum",
  details: "Detaylar",
  name: "İsim",
  surname: "Soyisim",
  birthDate: "Doğum Tarihi",
  fullName: "Ad Soyad",
  email: "E-posta",
  subject: "Konu",
  createdAt: "Oluşturulma Tarihi",
  page: "Sayfa",
  news: "Haber",
  offer: "Teklif",
  products: "Ürünler",
  updatedAt: "Güncellenme Tarihi",
  readingTime: "Okuma Süresi",
  tags: "Etiketler",
  references: "Referanslar",
  version: "Versiyon",
  domain: "Alan",
  type: "Tip",
  language: "Dil",
  duration: "Süre",
  startDate: "Başlangıç Tarihi",
  lastApplicationDate: "Son Başvuru Tarihi",
  participationLink: "Katılım Linki",
  question: "Soru",
  answer: "Cevap",

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: "Panel",
  blog: "Blog",
  blogs: "Bloglar",
  categories: "Kategoriler",
  authors: "Yazarlar",
  faq: "SSS",
  faqs: "SSS'ler",
  faqCategory: "SSS Kategorileri",
  form: "Form",
  contactForm: "İletişim Formları",
  subscriptionForm: "Abonelik Formları",
  blogTargetCategories: "Hedef Kategoriler",
  employees: "Çalışanlar",
  departments: "Departmanlar",
  permissions: "Izinler",
  leadStatuses: "Lead Durumları",
  leadSources: "Lead Kaynakları",
  leadForms: "Lead Formları",
  customers: "Muşteriler",
  contacts: "İletişim",
  customerCategories: "Muşteri Kategorileri",
  estimates: "Tahsilatlar",
  proposals: "Teklifler",
  invoices: "Faturalar",
  payments: "Ödemeler",
  contracts: "Sozlesmeler",
  returns: "İadeler",
  services: "Hizmetler",
  reminders: "Hatırlatmalar",
  appointments: "Randevular",



  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: "dk",
};

export default locale;
