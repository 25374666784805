
import { OrganizationalChartDomainLayout } from "../layouts";
import { organizationalChartDomainRoutes } from "./organizationalChart.base.route";
import { Route, Routes } from "react-router-dom";
import { EmployeesRouter } from "../subdomains/employees/routes/EmployeesRouter";
import { DepartmentsRouter } from "../subdomains/departments/routes/DepartmentsRouter";
import { PermissionsRouter } from "../subdomains/permissions/routes/PermissionsRouter";

export const OrganizationalChartDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<OrganizationalChartDomainLayout />}>
        <Route index element={<EmployeesRouter />} />
        <Route
          path={`${organizationalChartDomainRoutes.subdomains.employees.base}/*`}
          element={<EmployeesRouter />}
        />
        <Route
          path={`${organizationalChartDomainRoutes.subdomains.departments.base}/*`}
          element={<DepartmentsRouter />}
        />
        <Route
          path={`${organizationalChartDomainRoutes.subdomains.permissions.base}/*`}
          element={<PermissionsRouter />}
        />
      </Route>
    </Routes>
  );
};
