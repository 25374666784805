

export const salesDomainRoutes = {
  base: "sales",
  subdomains: {
    invoices: {
      base: "invoices",
    },
    payments: {
      base: "payments",
    },
    returns: {
      base: "returns",
    },
    // Todo
    // employees: {
    //   base: "employees",
    // },
  },
};


export const baseSalesRoute = (): string => `/${salesDomainRoutes.base}/`;
export const listInvoicesRoute = (): string => `/${salesDomainRoutes.base}/${salesDomainRoutes.subdomains.invoices.base}`;
export const listPaymentsRoute = (): string => `/${salesDomainRoutes.base}/${salesDomainRoutes.subdomains.payments.base}`;
export const listReturnsRoute = (): string => `/${salesDomainRoutes.base}/${salesDomainRoutes.subdomains.returns.base}`;
// Todo
// export const listEmployeesRoute = (): string =>
//   `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.employees.base}`;

