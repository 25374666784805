const locale = {
  lead: "Lead",
  leads: "Lead'ler",
  create: "Lead Oluştur",
  update: "Lead Güncelle",
  delete: "Sil",
  view: "Lead'i Görüntüle",
  list: "Lead Listesi",
  createdSuccessfully: "Lead başarıyla oluşturuldu",
  updatedSuccessfully: "Lead başarıyla güncellendi",
  deleteConfirm: "Kaydı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  deletedSuccessfully: "Lead başarıyla silindi",
  createInfo: "Bu bölümden yeni kayıt oluşturabilirsiniz.",
  fullName: "Adı Soyadı",
  status: "Durum",
  source: "Kaynak",
  title: "Lead Listesi",
  tags: "Etiketler",
  assigneds: "Atanımlar",
  position: "Pozisyon",
  email: "E-posta",
  website: "Web Sitesi",
  phoneNumber: "Telefon Numarası",
  company: "Şirket",
  sector: "Sektör",
  type: "Tip",
  qualification: "Derece",
  country: "Ülke",
  state: "Eyalet",
  city: "Şehir",
  justOwn: "Sadece Kendim",
  isJunk: "Spam",
  note: "Not",
  language: "Dil",
  listInfo: "Tüm kayıtların listesi.",
  importedError: "İçe aktarım hatası",
  importedSuccessfully: "İçe aktarıldı",
  downloadFormError: "Form indirilemedi",
  downloadFormSuccessfully: "Form indirildi",
  downloadExampleFormSuccessfully: "Örnek form indirildi",
  downloadExampleFormError: "Örnek form indirilemedi",
  emptyState1: "Burada hiçbir lead bulunmadı.",
  emptyState2: "Hemen lead oluşturabilir ya da içeriye aktarabilirsiniz.",
  createLead: "Lead Oluştur",
  importLead: "Lead İçe Aktar",
  importTitle: "Leadleri İçe Aktar",
  downloadExample: "Örnek Formu İndir",
  importText1: "Bu sayfadan leadleri içeriye aktarabilirsiniz.",
  importText2: "Leadlerinizi yayınlayabilmeniz için aşağıdaki örnek formu kullanabilirsiniz.",
  importExport: "İçeri/Dışarı Aktar",
  exportData: "Veriyi Dışarı Aktar",
  importData: "Veriyi İçe Aktar",
  b2b: "B2B",
  b2c: "B2C",
  viewLead: "Görüntüle",
  edit: "Düzenle",
  junk: "Spam",
  unJunk: "Spami Kaldır",
  addNewRole: "Yeni Rol Ekle",
  actions: "İşlemler"
};

export default locale;
