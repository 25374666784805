import employeesLocale from "../subdomains/employees/locales/tr";
import departmentsLocale from "../subdomains/departments/locales/tr";
import permissionsLocale from "../subdomains/permissions/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  employees: {
    ...employeesLocale,
  },
  departments: {
    ...departmentsLocale,
  },
  permissions: {
    ...permissionsLocale
  }

  

};

export default locale;
