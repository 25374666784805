/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, CircularProgress, Stack } from "@mui/material";
import { useFormik } from "formik";
import { forgotPasswordValidationSchema } from 'utils/validations';
import { AuthLayout } from "../layouts";
import { useRequestResetPasswordMutation, setUser } from "../context";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "context";
import { useEffect } from "react";
import { MyField } from "components";
import LoginCo from "assets/images/login-co.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [getRequestResetPassword, { data, isLoading, error }] = useRequestResetPasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { values, handleSubmit, setFieldValue, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: forgotPasswordValidationSchema,
    initialValues: {
      email: ''
    },
    onSubmit: async (values) => {
      try {
        await getRequestResetPassword({
          input: { email: values.email }
        });
      } catch (error) {
        enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t("auth.resetPasswordRequestSuccess"), { variant: 'success' });
      navigate(routes.auth.resetPassword + "?token=" + data.devMetaData?.token);
      dispatch(setUser(data));
    }
  }, [data, enqueueSnackbar, navigate, dispatch]);

  return <AuthLayout>
    <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>
        {/* Optional intro text */}
      </Typography>
      <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>
        <Box display={{ xs: 'flex', md: 'none' }} height={100} />
        <Box
          sx={{
            width: { xs: '100%', md: '80%', lg: '60%' },
            height: "80vh",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'flex-start', md: 'center' },
            alignItems: 'center',
            gap: 2,
          }}
          px={{ xs: 1, md: 7 }}
        >
          <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
          <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pt={2}>
            <Typography fontSize={32} fontWeight={500} textAlign={"center"}>
              {t("auth.forgotPasswordTitle")}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} pb={2}>
            <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
              {t("auth.resetPasswordInstructions")}
            </Typography>
          </Box>

          <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} alignItems={"center"}>
            <form onSubmit={handleSubmit as any} style={{ width: '100%' }}>
              <Box display={"flex"} flexDirection={"column"} gap={1.5} alignItems={"center"} >
                <MyField
                  placeholder={t("auth.emailAddress")}
                  type="email"
                  values={values}
                  setFieldValue={setFieldValue}
                  name="email"
                  errors={errors}
                  handleBlur={handleBlur}
                  touched={touched}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit as any}
                  disabled={isLoading || !values.email || !!errors.email}
                  sx={{ fontSize: 14, height: 56, mt: 2 }}
                >
                  {(isLoading) ? <CircularProgress size="1rem" /> : t("auth.resetPassword")}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>

      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
        {t("auth.rememberPassword")} <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.login)}> {t("auth.login")}</span>
      </Typography>
    </Stack>
  </AuthLayout>
}

export default ForgotPassword;
