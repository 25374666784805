import employeesLocale from "../subdomains/employees/locales/en";
import departmentsLocale from "../subdomains/departments/locales/en";
import permissionsLocale from "../subdomains/permissions/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  employees: {
    ...employeesLocale,
  },
  departments: {
    ...departmentsLocale,
  },
  permissions: {
    ...permissionsLocale
  }
};

export default locale;
