import React, { useState, useEffect } from 'react';
import { Box, Button, SwipeableDrawer, Typography, Divider, Stack, IconButton, Modal, Grid, TextField, SvgIcon } from '@mui/material';
import { DataGridPremium, GridRowModesModel, GridRowModes, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { useLeadSourceCreateMutation, useLeadSourceDeleteMutation, useLeadSourcesQuery, useLeadSourceUpdateMutation } from 'apps/crm/domains/03-lead/subdomains/leadSources/context/leadSources.api';
import { Add, Cancel, DeleteOutlineRounded, Save } from '@mui/icons-material';
import * as Icons from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';

interface ILeadDrawer {
    open: any;
    setOpen: any;
}

export const LeadSourcesDrawer = (props: ILeadDrawer) => {

    const apiRef = useGridApiRef();
    const { data: leadSourcesData, isLoading: leadSourcesLoading } = useLeadSourcesQuery({
        input: {
            pagination: {
                sort: {
                    createdAt: 1
                }
            }
        }
    });
    const [leadSourceUpdate, { isLoading: leadSourceUpdateLoading, error: leadSourceUpdateError }] = useLeadSourceUpdateMutation();
    const [leadSourceCreate, { isLoading: leadSourceCreateLoading, error: leadSourceCreateError }] = useLeadSourceCreateMutation();
    const [leadSourceDelete, { isLoading: leadSourceDeleteLoading, error: leadSourceDeleteError }] = useLeadSourceDeleteMutation();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [selectedIcon, setSelectedIcon] = useState<any>('');
    const [addSourceAvailable, setAddSourceAvailable] = useState(true);
    const [openSelectIconset, setOpenSelectIconset] = useState(false);
    const [openColorModal, setOpenColorModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [searchQuery, setSearchQuery] = useState('');

    const [rows, setRows] = useState<any[]>(leadSourcesData?.data?.map((source, index) => ({
        id: index + 1,
        _id: source._id,
        name: source.name || '-',
        icon: source.icon || '-',
        color: source.color || '-',
    })) || []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
        {
            field: 'name', headerName: 'Name', width: 100, editable: true,
            renderEditCell: (params) => {
                return (
                    <TextField
                        size="small"
                        value={params.value || ''}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            setSelectedName(newValue);
                            apiRef.current.setEditCellValue({ ...params, value: newValue });
                        }}
                        onBlur={() => apiRef.current.stopCellEditMode({ id: params.id, field: params.field })}
                    />
                );
            },
        },
        {
            field: 'icon', headerName: 'Icon', width: 70, editable: true,
            renderCell: (params) => {
                return (
                    <SvgIcon component={(Icons as any)[params.value]} fontSize="small" sx={{ mt: 2, ml: 0.5 }} />
                )
            },
            renderEditCell: () => null
        },
        {
            field: 'color', headerName: 'Color', width: 70, editable: true,
            renderCell: (params) => {
                return (
                    <Box sx={{ mt: 2, ml: 0.5, width: 20, height: 20, borderRadius: '50%', backgroundColor: params.value ?? 'background.primary' }} />
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 90,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: any) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                return isInEditMode ? (
                    <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 1 }}>
                        <IconButton
                            onClick={() => handleSaveRow(params.id)}
                            color="primary"
                            sx={{ p: "4px" }}
                        >
                            <Save color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton
                            onClick={() => handleCancelRow(params.id)}
                            color="error"
                            sx={{ p: "4px", }}
                        >
                            <Cancel color="error" fontSize="small" />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
                        <IconButton
                            onClick={() => handleDeleteRow(params?.row?._id)}
                            color="error"
                            sx={{ p: "4px", }}
                        >
                            <DeleteOutlineRounded color="error" fontSize="small" />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];

    const handleAddRow = () => {
        const id = rows.length + 1;
        setRows((oldRows) => [...oldRows, { id, name: '', icon: '', color: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit },
        }));
        setAddSourceAvailable(false);

        setTimeout(() => {
            apiRef.current.setCellFocus(id, 'name');
        });
    };

    const handleCancelRow = (id: number) => {
        setRows((oldRows) => oldRows.filter((row) => row.id !== id));
        setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.View } }));
        setAddSourceAvailable(true);
    }


    const handleSaveRow = (id: number) => {
        const row = rows.find((r) => r.id === id);

        if (row.isNew) {
            leadSourceCreate({
                input: {
                    name: selectedName,
                    icon: selectedIcon,
                    color: selectedColor
                }
            })
        }

        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.View },
        }));
        setAddSourceAvailable(true);
    }

    const handleIconSelect = (iconName: string) => {
        const updatedRow = {
            ...selectedRow,
            icon: iconName,
        };

        apiRef.current.updateRows([updatedRow]);
        handleProcessRowUpdate(updatedRow, selectedRow);
        setSelectedIcon(iconName);
        setOpenSelectIconset(false);
    };

    const handleColorSelect = (color: string) => {
        const updatedRow = {
            ...selectedRow,
            color: color,
        };

        apiRef.current.updateRows([updatedRow]);
        handleProcessRowUpdate(updatedRow, selectedRow);
        setSelectedColor(color);
        setOpenColorModal(false);
    };

    const handleDeleteRow = (id: string) => {
        leadSourceDelete({ input: { _id: id } });
    }

    const filteredIcons = Object.keys(Icons).filter((iconName) =>
        iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    console.log(addSourceAvailable, 'addSourceAvailable');
    function handleProcessRowUpdate(newRow: any, oldRow: any) {
        if (newRow.name !== oldRow.name && addSourceAvailable) {
            leadSourceUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
        }
        if (newRow.icon !== oldRow.icon && addSourceAvailable) {
            leadSourceUpdate({ filter: { _id: newRow._id }, input: { icon: newRow.icon } });
        }
        if (newRow.color !== oldRow.color && addSourceAvailable) {
            leadSourceUpdate({ filter: { _id: newRow._id }, input: { color: newRow.color } });
        }
        return newRow;
    };

    useEffect(() => {
        if (leadSourcesData && leadSourcesData.data) {
            setRows(leadSourcesData.data.map((source, index) => ({
                id: index + 1,
                name: source.name || '-',
                icon: source.icon || '-',
                color: source.color || '-',
            })));
        }
    }, [leadSourcesData]);

    useEffect(() => {
        if (leadSourceCreateError) {
            enqueueSnackbar('Lead Source Creation Failed', { variant: 'error' });
        }
    }, [leadSourceCreateError])

    useEffect(() => {
        if (leadSourceUpdateError) {
            enqueueSnackbar('Lead Source Update Failed', { variant: 'error' });
        }
    }, [leadSourceUpdateError])

    useEffect(() => {
        if (leadSourceDeleteError) {
            enqueueSnackbar('Lead Source Deletion Failed', { variant: 'error' });
        }
    }, [leadSourceDeleteError])

    return (
        <SwipeableDrawer
            open={props.open}
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            anchor="right"
            className='user-detail-nav'
        >
            <Box py={5} px={3} minWidth={260} width={400} bgcolor={"background.primary"} height={"calc(100vh - 80px)"}>
                <Typography sx={{ fontWeight: "bold", fontSize: "24px", color: "primary.main" }}>
                    Lead Sources
                </Typography>
                <Divider />
                <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
                    <DataGridPremium
                        apiRef={apiRef}
                        rows={rows}
                        columns={columns}
                        disableRowSelectionOnClick
                        showColumnVerticalBorder
                        disableAggregation
                        disableRowGrouping
                        hideFooter
                        processRowUpdate={handleProcessRowUpdate}
                        loading={leadSourcesLoading || leadSourceUpdateLoading || leadSourceCreateLoading || leadSourceDeleteLoading}
                        onCellDoubleClick={(params, event) => {
                            if (params.field === 'icon') {
                                setOpenSelectIconset(true);
                                setSelectedRow(params.row);
                                event.stopPropagation();
                            }
                            if (params.field === 'color') {
                                setOpenColorModal(true);
                                setSelectedRow(params.row);
                                event.stopPropagation();
                            }
                        }}
                        scrollbarSize={1}
                        scrollEndThreshold={10}
                        rowsLoadingMode="server"
                        sx={[tableStyle, {
                            backgroundColor: 'background.secondary', minHeight: "350px",
                            '&. .MuiDataGrid-topContainer': {
                                border: "2px solid #000"
                            },
                            '& .MuiDataGrid-row--borderBottom': {
                                background: "none !important",

                            },
                        }]}
                    />
                </Box>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Add />}
                    disabled={!addSourceAvailable}
                    onClick={handleAddRow}
                    sx={{ marginTop: 2, width: '100%' }}
                >
                    Add Source
                </Button>
            </Box>

            <Modal
                open={openSelectIconset}
                onClose={() => setOpenSelectIconset(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Select an Icon
                    </Typography>
                    <TextField
                        label="Search Icons"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Box sx={{ maxHeight: 400, overflow: 'auto', mt: 2 }}>
                        <Grid container spacing={2}>
                            {filteredIcons.map((iconName) => {
                                const IconComponent = (Icons as any)[iconName];
                                return (
                                    <Grid item xs={2} key={iconName}>
                                        <IconButton onClick={() => handleIconSelect(iconName)}>
                                            <IconComponent />
                                        </IconButton>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openColorModal}
                onClose={() => setOpenColorModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Select Color
                    </Typography>
                    <Box sx={{ maxHeight: 400, overflow: 'auto', mt: 2 }}>
                        <Grid container spacing={2}>
                            {colors.map((color) => (
                                <Grid item xs={2} key={color}>
                                    <IconButton
                                        size='large'
                                        onClick={() => handleColorSelect(color)}
                                        sx={{ backgroundColor: color }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </SwipeableDrawer>
    );
};

const tableStyle = {
    fontSize: "12px",
    borderRadius: "16px",

    '& .MuiDataGrid-toolbarContainer > button': {
        fontSize: "12px",
    },
    '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
        fontSize: "12px",
        p: "2px",
        border: "none !important",
    },
    '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
        width: "16px",
        height: "16px",
    },
    '& .MuiDataGrid-footerContainer': {
        height: '20px !important',
        maxHeight: '20px !important',
        m: 0,
    }
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const colors = [
    "primary.lighter",
    "secondary.lighter",
    "success.lighter",
    "error.lighter",
    "warning.lighter",
    "info.lighter",
    "primary.light",
    "secondary.light",
    "success.light",
    "error.light",
    "warning.light",
    "info.light",
    "primary.main",
    "secondary.main",
    "success.main",
    "error.main",
    "warning.main",
    "info.main",
    "primary.dark",
    "secondary.dark",
    "success.dark",
    "error.dark",
    "warning.dark",
    "info.dark",
    "primary.darker",
    "secondary.darker",
    "success.darker",
    "error.darker",
    "warning.darker",
    "info.darker",
]