

import { commonRoutes } from "routes/route.common";

export const organizationalChartDomainRoutes = {
  base: "organizational-chart",
  subdomains: {
    employees: {
      base: "employees",
    },
    departments: {
      base: "departments",
    },
    permissions: {
      base: "permissions",
    }
    // add subdomain route names here
  },
};

// Organization routes
export const baseOrganizationalChartRoute = (): string => `/${organizationalChartDomainRoutes.base}/`;
export const listEmployeesRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.employees.base}`;
export const viewEmployeesRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.employees.base}/${id}`;
export const createEmployeesRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.employees.base}/${commonRoutes.create}`;
export const updateEmployeesRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.employees.base}/${id}/${commonRoutes.update}`;
export const rolesEmployeesRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.employees.base}/${commonRoutes.roles}`;
export const userListEmployeesRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.employees.base}/${commonRoutes.userList}`;
export const listDepartmentsRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.departments.base}`;
export const listPermissionsRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.permissions.base}`

// add subdomain routes here
