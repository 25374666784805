const locale = {
  leadSources: "Lead Sources",
  title: "Lead Sources",
  create: "Create Source",
  delete: "Delete Source",
  deleteConfirm: "Are you sure you want to delete this source: ",
  deletedSuccessfully: "Lead source deleted successfully",
  createSource: "Create Source",
  leadSourceCreateSuccess: "Lead source created successfully",
  name: "Source Name",
  color: "Source Color",
  icon: "Source Icon",
  showMore: "Show More",
};

export default locale;
