


export const dashboardDomainRoutes = {
  base: "dashboard",
};

// Organization routes
export const baseOrganizationRoute = (): string => `/${dashboardDomainRoutes.base}`;



// add subdomain routes here
