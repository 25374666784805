import {
    // queries
    // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

    ILeadListInput, ILeadListRequest, ILeadListResponse, ILeadListResult, leadListQuery,
    ILeadDetailInput, ILeadDetailRequest, ILeadDetailResponse, ILeadDetailResult, leadDetailQuery,
    INoteListInput, INoteListRequest, INoteListResponse, INoteListResult, noteListQuery,

    // mutations
    ILeadCreateInput, ILeadCreateRequest, ILeadCreateResponse, ILeadCreateResult, leadCreateQuery,
    ILeadUpdateInput, ILeadUpdateRequest, ILeadUpdateResponse, ILeadUpdateResult, leadUpdateQuery, ILeadUpdateFilterInput,
    ILeadDeleteInput, ILeadDeleteRequest, ILeadDeleteResponse, ILeadDeleteResult, leadDeleteQuery,


    //TODO move to note subdomain

    INoteCreateInput, INoteCreateRequest, INoteCreateResponse, INoteCreateResult, noteCreateQuery,
    INoteUpdateInput, INoteUpdateRequest, INoteUpdateResponse, INoteUpdateResult, noteUpdateQuery, INoteUpdateFilterInput,
    INoteDeleteInput, INoteDeleteRequest, INoteDeleteResponse, INoteDeleteResult, noteDeleteQuery,


} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { leadTags } from "./lead.tags";
import { IGraphqlVariables } from "corede-common";


export const organizationApi = commonApi.injectEndpoints({
    endpoints: (builder) => ({





        // leadTags: builder.query<
        //     ILeadTagsResult,
        //     IGraphqlVariables<ILeadTagsFilterInput>
        // >(
        //     EndpointQueryBuilder.BuildGraphqlQuery<
        //         ILeadTagsRequest,
        //         ILeadTagsResponse,
        //         ILeadTagsResult,
        //         ILeadTagsFilterInput,
        //     >({
        //         query: leadTagsQuery,
        //         providesTags: [leadTags.leadTags],
        //     })),


        leadList: builder.query<
            ILeadListResult,
            IGraphqlVariables<ILeadListInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                ILeadListRequest,
                ILeadListResponse,
                ILeadListResult,
                ILeadListInput
            >({
                query: leadListQuery,
                providesTags: [leadTags.lead, leadTags.leads],
            })),


        leadDetail: builder.query<
            ILeadDetailResult,
            IGraphqlVariables<ILeadDetailInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                ILeadDetailRequest,
                ILeadDetailResponse,
                ILeadDetailResult,
                ILeadDetailInput
            >({
                query: leadDetailQuery,
                providesTags: [leadTags.lead, leadTags.leads],
            })),


        noteList: builder.query<
            INoteListResult,
            IGraphqlVariables<INoteListInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                INoteListRequest,
                INoteListResponse,
                INoteListResult,
                INoteListInput
            >({
                query: noteListQuery,
                providesTags: [leadTags.note, leadTags.notes],
            })),



        // mutations

        leadCreate: builder.mutation<
            ILeadCreateResult,
            IGraphqlVariables<ILeadCreateInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                ILeadCreateRequest,
                ILeadCreateResponse,
                ILeadCreateResult,
                ILeadCreateInput
            >({
                query: leadCreateQuery,
                invalidatesTags: [leadTags.lead, leadTags.leads],
            })),

        leadUpdate: builder.mutation<
            ILeadUpdateResult,
            IGraphqlVariables<ILeadUpdateInput, ILeadUpdateFilterInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                ILeadUpdateRequest,
                ILeadUpdateResponse,
                ILeadUpdateResult,
                ILeadUpdateInput,
                ILeadUpdateFilterInput
            >({
                query: leadUpdateQuery,
                invalidatesTags: [leadTags.lead, leadTags.leads],
            })),

        leadDelete: builder.mutation<
            ILeadDeleteResult,
            IGraphqlVariables<ILeadDeleteInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                ILeadDeleteRequest,
                ILeadDeleteResponse,
                ILeadDeleteResult,
                ILeadDeleteInput
            >({
                query: leadDeleteQuery,
                invalidatesTags: [leadTags.lead, leadTags.leads],
            })),




        noteCreate: builder.mutation<
            INoteCreateResult,
            IGraphqlVariables<INoteCreateInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                INoteCreateRequest,
                INoteCreateResponse,
                INoteCreateResult,
                INoteCreateInput
            >({
                query: noteCreateQuery,
                invalidatesTags: [leadTags.lead, leadTags.note, leadTags.notes],
            })),

        noteUpdate: builder.mutation<
            INoteUpdateResult,
            IGraphqlVariables<INoteUpdateInput, INoteUpdateFilterInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                INoteUpdateRequest,
                INoteUpdateResponse,
                INoteUpdateResult,
                INoteUpdateInput,
                INoteUpdateFilterInput
            >({
                query: noteUpdateQuery,
                invalidatesTags: [leadTags.lead, leadTags.note, leadTags.notes],
            })),

        noteDelete: builder.mutation<
            INoteDeleteResult,
            IGraphqlVariables<INoteDeleteInput>
        >(
            EndpointQueryBuilder.BuildGraphqlQuery<
                INoteDeleteRequest,
                INoteDeleteResponse,
                INoteDeleteResult,
                INoteDeleteInput
            >({
                query: noteDeleteQuery,
                invalidatesTags: [leadTags.lead, leadTags.note, leadTags.notes],
            })),




    })

});

export const {
    useLeadListQuery,
    useLazyLeadListQuery,
    useLeadDetailQuery,
    useLazyLeadDetailQuery,

    useNoteListQuery,
    useLazyNoteListQuery,

    useLeadCreateMutation,
    useLeadUpdateMutation,
    useLeadDeleteMutation,

    useNoteCreateMutation,
    useNoteUpdateMutation,
    useNoteDeleteMutation,

} = organizationApi;
