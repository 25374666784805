import { Button } from '@mui/material'

type Props = {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
    title?: string
    leftIcon?: JSX.Element
    rightIcon?: JSX.Element
    color?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning"
}

export const OutlinedButton = (props: Props) => {

    return <Button
        variant="outlined"
        color={props?.color ?? "primary"}
        sx={{ height: 40, fontSize: { xs: "11px", md: "13px" } }}
        onClick={(e) => props?.onClick?.(e)}
    >
        {props?.leftIcon}
        {props?.title}
        {props?.rightIcon}
    </Button>
}

