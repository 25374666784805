

export const routes = {
    auth: {
        login: "/",
        signup: "/signup",
        forgotPassword: "/forgot-password",
        forgotPasswordSuccessfull: "/forgot-password-successfull",
        confirmation: "/confirmation",
        createPassword: "/create-password",
        resetPassword: "/reset-password",
    },
}