import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Add, FileUpload, } from "@mui/icons-material";
import { LeadSourcesDrawer } from "../../leadSources";
import { LeadStatusesDrawer } from "../../leadStatuses";
import { useLeadListQuery } from "../context/lead.api";
import { useLeadStatusesQuery } from "../../leadStatuses/context/leadStatuses.api";
import { useLeadSourcesQuery } from "../../leadSources/context/leadSources.api";
import { enqueueSnackbar } from "notistack";
import { KanbanView } from "./KanbanView";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import { LeadDetailDrawer } from "./DetailDrawer";
import { LeadCreateDrawer } from "./CreateDrawer";
import ListView from "./ListView";
import { importLeadRoute } from "../../../routes/lead.base.route";
import { useNavigate } from "react-router-dom";


const List = () => {

  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [openLeadStatusesDrawer, setOpenLeadStatusesDrawer] = useState(false);
  const [openLeadSourcesDrawer, setOpenLeadSourcesDrawer] = useState(false);
  const [openLeadCreateDrawer, setOpenLeadCreateDrawer] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>();
  const [leadListType, setLeadListType] = useState("list");


  // queries 
  const { data: leadStatusesData, isLoading: leadStatusesLoading, error: leadStatusesError } = useLeadStatusesQuery({});
  const { data: leadSourcesData, isLoading: leadSourcesLoading, error: leadSourcesError } = useLeadSourcesQuery({});
  const { data: leadListData } = useLeadListQuery({});


  function handleSelectLead(direction: 'next' | 'back') {
    if (!selectedLead || !leadListData?.data?.length) return;

    const currentIndex = leadListData.data.findIndex(lead => lead._id === selectedLead._id);

    let newIndex = currentIndex;
    if (direction === 'next') {
      newIndex = currentIndex + 1;
    } else if (direction === 'back') {
      newIndex = currentIndex - 1;
    }

    // Ensure the new index is within the valid range
    if (newIndex >= 0 && newIndex < leadListData.data.length) {
      const newSelectedLead = { ...leadListData.data[newIndex], id: newIndex + 1 }; // index bilgisini ekle
      setSelectedLead(newSelectedLead);
    }
  }

  // success useEffects


  //error useEffects

  useEffect(() => {
    if (leadStatusesError) {
      enqueueSnackbar("Error", { variant: "error" });
    }
  }, [leadStatusesError]);

  useEffect(() => {
    if (leadSourcesError) {
      enqueueSnackbar("Error", { variant: "error" });
    }
  }, [leadSourcesError]);





  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.lead.title`)}
        toggleButton={(leadListData as any)?.data?.length > 0 ? {
          value: leadListType,
          action: (value: any) => setLeadListType(value),
        } : undefined}
        rightButton={(leadListData as any)?.data?.length > 0 ? {
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.lead.create`),
          onClick: () => setOpenLeadCreateDrawer(true),
        } : undefined}
        righButton2={(leadListData as any)?.data?.length > 0 ? {
          icon: <FileUpload sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          onClick: () => navigate(importLeadRoute()),
          title: t(`crm.lead.lead.importLead`),
        } : undefined}
      />

      {leadListType === "list" ?
        <ListView
          leadSourcesData={leadSourcesData}
          leadStatusesData={leadStatusesData}
          setOpenUserDrawer={setOpenUserDrawer}
          setSelectedLead={setSelectedLead}
          leadStatusesLoading={leadStatusesLoading}
          leadSourcesLoading={leadSourcesLoading}
          setOpenLeadCreateDrawer={setOpenLeadCreateDrawer}
          selectedLead={selectedLead}
        />
        :
        <KanbanView
          statuses={leadStatusesData?.data}
          leads={leadListData?.data}
          setOpenLeadStatusesDrawer={setOpenLeadStatusesDrawer}
          setSelectedLead={setSelectedLead}
          setOpenUserDrawer={setOpenUserDrawer}
        />
      }

      <LeadDetailDrawer
        open={openUserDrawer}
        setOpen={setOpenUserDrawer}
        selectedLead={selectedLead}
        setSelectedLead={setSelectedLead}
        handleSelect={handleSelectLead}
        count={leadListData?.count}
      />

      <LeadStatusesDrawer
        open={openLeadStatusesDrawer}
        setOpen={setOpenLeadStatusesDrawer}
      />

      <LeadSourcesDrawer
        open={openLeadSourcesDrawer}
        setOpen={setOpenLeadSourcesDrawer}
      />

      <LeadCreateDrawer
        open={openLeadCreateDrawer}
        setOpen={setOpenLeadCreateDrawer}
      />
    </Grid >
  );
};

export default List;



