

import { ProjectsDomainLayout } from "../layouts";
import { ProjectsRouter } from "../subdomains/projects/routes/ProjectsRouter";
import { projectsDomainRoutes } from "./projects.base.route";
import { Route, Routes } from "react-router-dom";

export const ProjectsDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectsDomainLayout />}>
        <Route index element={<ProjectsRouter />} />
        <Route
          path={`${projectsDomainRoutes.subdomains.projects.base}/*`}
          element={<ProjectsRouter />}
        />
        {/* Todo 
         <Route index element={<EmployeesRouter />} />
         <Route
          path={`${customerDomainRoutes.subdomains.employees.base}/*`}
          element={<EmployeesRouter />}
        /> */}
      </Route>
    </Routes>
  );
};
