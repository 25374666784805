import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseCustomerRoute,
  listCustomersRoute,
  listContactsRoute,
  listCustomerCategoriesRoute
} from "../routes/customer.base.route";

export const useCustomerDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("customer"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseCustomerRoute(),
    children: [
      {
        title: t("customers"),
        path: listCustomersRoute(),
      },
      {
        title: t("contacts"),
        path: listContactsRoute(),
      },
      {
        title: t("customerCategories"),
        path: listCustomerCategoriesRoute(),
      },
      // Todo
      // {
      //   title: t("employees"),
      //   path: listEmployeesRoute(),
      // },
    ],
  };
};
