

import { TasksDomainLayout } from "../layouts";
import { TasksRouter } from "../subdomains/tasks/routes/TasksRouter";
import { tasksDomainRoutes } from "./tasks.base.route";
import { Route, Routes } from "react-router-dom";

export const TasksDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TasksDomainLayout />}>
        <Route index element={<TasksRouter />} />
        <Route
          path={`${tasksDomainRoutes.subdomains.tasks.base}/*`}
          element={<TasksRouter />}
        />
        {/* Todo 
         <Route index element={<EmployeesRouter />} />
         <Route
          path={`${customerDomainRoutes.subdomains.employees.base}/*`}
          element={<EmployeesRouter />}
        /> */}
      </Route>
    </Routes>
  );
};
