/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography, Menu, MenuItem, ListItemIcon, ListItemText, Box, Chip, IconButton, SvgIcon, useMediaQuery, Button, ToggleButton, ToggleButtonGroup, Tooltip, Autocomplete, TextField, Select } from '@mui/material';
import {
    DataGridPremium, GridCellModes, GridCellModesModel, GridCellParams, GridColDef, GridColumnMenu, GridColumnMenuItemProps, GridColumnMenuProps, GridFilterPanel, GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton
} from '@mui/x-data-grid-premium';
import { Add, FileUpload, FileDownload, Publish, ArticleOutlined, DeleteOutlineRounded, EditNoteRounded, VisibilityOutlined } from '@mui/icons-material';
import { OutlinedButton } from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IBaseLeadEntity, ILeadListInput, ILeadSourcesResult, ILeadStatusesResult, LeadQualification, LeadType } from 'corede-common-cocrm';
import { createLeadRoute, importLeadRoute, updateLeadRoute } from '../../../routes/lead.base.route';
import * as Icons from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { BASE_URL } from 'utils';
import { getAccessToken } from 'utils/getUserInfo';
import { useLazyLeadListQuery, useLeadListQuery, useLeadUpdateMutation } from '../context/lead.api';
import { IGraphqlVariables, Language, unknownError } from 'corede-common';
import { convertEnums, getCurrentLanguage } from 'localization';
import { DeleteDialog } from './DeleteDialog';
import { trTR } from '@mui/x-data-grid/locales';
import { useTranslation } from 'react-i18next';
import { LeadSourceCreateDrawer } from '../../leadSources/pages/LeadSourcesCreateDrawer';
import { LeadStatusCreateDrawer } from '../../leadStatuses/pages';

interface IListView {
    leadStatusesData: ILeadStatusesResult | undefined
    leadSourcesData: ILeadSourcesResult | undefined
    setOpenUserDrawer: (value: boolean) => void | undefined
    setSelectedLead: (value: any) => void | undefined
    selectedLead: IBaseLeadEntity | undefined
    leadStatusesLoading: boolean
    leadSourcesLoading: boolean
    setOpenLeadCreateDrawer: (value: boolean) => void | undefined
}

const ListView = (props: IListView) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const localizedLeadTypes = convertEnums(LeadType);
    const localizedLeadQualifications = convertEnums(LeadQualification);
    const localizedLanguages = convertEnums(Language);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [b2Type, setB2Type] = useState<any>('b2b');
    const [isJunk, setIsJunk] = useState<boolean>(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [leadStatusCreateOpen, setLeadStatusCreateOpen] = useState(false);
    const [leadSourceCreateOpen, setLeadSourceCreateOpen] = useState(false);
    const [leadListFilter, setLeadListFilter] = useState<IGraphqlVariables<ILeadListInput>>({
        input: {
            filter: {
                email: undefined,
                organizationId: undefined,
                role: undefined,
                status: undefined,
                createdById: undefined
            }
        }
    })


    const [leadUpdate, { isLoading: leadUpdateLoading, error: leadUpdateError }] = useLeadUpdateMutation();
    const { data: leadListData, isLoading: leadListLoading, isFetching: leadListFetching, error: leadListError } = useLeadListQuery(leadListFilter);
    const [leadListLazy, { data: leadListLazyData, isLoading: leadListLazyLoading, isFetching: leadListLazyFetching, error: leadListLazyError }] = useLazyLeadListQuery();

    console.log(selectedStatus)
    const columnsList: GridColDef<any>[] = [
        { field: 'id', headerName: '#', width: 40, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
        { field: 'fullName', headerName: t("crm.lead.lead.fullName"), width: 120, editable: true },
        {
            field: 'position', headerName: t("crm.lead.lead.position"), width: 120, editable: true,
        },
        {
            field: 'email', headerName: t("crm.lead.lead.email"), width: 130, editable: true,
        },
        { field: 'phoneNumber', headerName: t("crm.lead.lead.phoneNumber"), width: 120, editable: true, filterable: false },
        {
            field: 'status', headerName: t("crm.lead.lead.status"), width: 120,
            editable: true,
            type: 'singleSelect',
            valueOptions: props.leadStatusesData?.data.map((status) => status._id),
            renderEditCell: (params: any) => {
                const currentValue = params.value?._id || params.row.status?._id || '';
                return (
                    <Select
                        value={currentValue}
                        label={t("crm.lead.lead.status")}
                        onChange={(e) => {
                            params.api.setEditCellValue({
                                ...params,
                                value: { ...params.value, _id: e.target.value }
                            });
                        }}
                        sx={{ width: "100%" }}
                    >
                        {props.leadStatusesData?.data.map((status) => (
                            <MenuItem key={status._id} value={status._id}>
                                <Stack direction="row" alignItems={"center"}>
                                    <SvgIcon component={(Icons as any)[status.icon]} sx={{ fontSize: "13px", mr: 0.5, color: status.color }} />
                                    <Typography fontSize={"13px"} fontWeight={"bold"} color={status.color}>   {status.name}   </Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                );
            },
            renderCell: (params: any) => {
                return <Box
                    sx={{
                        border: "1px solid",
                        borderColor: props.leadStatusesData?.data.find((status) => status._id === params?.value?._id)?.color,
                        borderRadius: '30px',
                        py: 0.5,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        px: 1,
                    }}
                >
                    <SvgIcon component={(Icons as any)[props.leadStatusesData?.data.find((status) => status._id === params?.value?._id)?.icon ?? '']} fontSize="small" sx={{ mr: 0.5, color: props.leadStatusesData?.data.find((status) => status._id === params?.value?._id)?.color }} />
                    <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"} color={params?.value?.color}>
                        {params?.value?.name}
                    </Typography>
                </Box>
            },
            renderHeader: (params) => (
                <Stack direction="row" alignItems={"center"}>
                    <span style={{ fontWeight: 500 }}>{params.colDef.headerName}</span>
                    <IconButton
                        size="small"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            setLeadStatusCreateOpen(true)
                        }}
                    >
                        <Add fontSize="small" />
                    </IconButton>
                </Stack>
            ),
        },
        {
            field: 'source', headerName: t("crm.lead.lead.source"), width: 120, editable: true, filterable: false,
            type: 'singleSelect',
            align: 'center',
            valueOptions: props.leadSourcesData?.data.map((source) => (source.name)),
            renderEditCell: (params: any) => {
                const currentValue = params.value?._id || params.row.source?._id || '';
                return (
                    <Select
                        value={currentValue}
                        label={t("crm.lead.lead.source")}
                        onChange={(e) => {
                            params.api.setEditCellValue({
                                ...params,
                                value: { ...params.value, _id: e.target.value }
                            });
                        }}
                        sx={{ width: "100%" }}
                    >
                        {props.leadSourcesData?.data.map((source) => (
                            <MenuItem key={source._id} value={source._id}>
                                <Typography fontSize={"13px"} fontWeight={"bold"}>
                                    {source.name}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                );
            },
            renderCell: (params: any) => {
                return <Box
                    sx={{
                        border: "1px solid",
                        borderColor: props.leadSourcesData?.data.find((source) => source.name === params.value?.name)?.color,
                        borderRadius: '30px',
                        py: 0.5,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        px: 1,
                    }}
                >
                    <SvgIcon component={(Icons as any)[(props.leadSourcesData as any)?.data.find((source: any) => source.name === params.value?.name)?.icon]} fontSize="small" sx={{ mr: 0.5, color: props.leadSourcesData?.data.find((source) => source.name === params.value?.name)?.color }} />
                    <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"} color={props.leadSourcesData?.data.find((source) => source.name === params.value?.name)?.color}>
                        {props.leadSourcesData?.data.find((source) => source.name === params.value?.name)?.name}
                    </Typography>
                </Box>
            },
            renderHeader: (params) => (
                <Stack direction="row" alignItems={"center"}>
                    <span style={{ fontWeight: 500 }}>{params.colDef.headerName}</span>
                    <IconButton
                        size="small"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            setLeadSourceCreateOpen(true)
                        }}
                    >
                        <Add fontSize="small" />
                    </IconButton>
                </Stack>
            ),
        },
        { field: 'website', headerName: t("crm.lead.lead.website"), width: 160, editable: true, filterable: false },
        { field: 'company', headerName: t("crm.lead.lead.company"), width: 120, editable: true },
        { field: 'sector', headerName: t("crm.lead.lead.sector"), width: 120, editable: true },
        {
            field: 'type',
            headerName: t("crm.lead.lead.type"),
            width: 120,
            editable: true,
            renderEditCell: (params: any) => {
                const currentValue = params.value || params.row.type || '';
                return (
                    <Select
                        value={currentValue}
                        label={t("crm.lead.lead.type")}
                        onChange={(e) => {
                            params.api.setEditCellValue({
                                ...params,
                                value: e.target.value
                            });
                        }}
                        sx={{ width: "100%" }}
                    >
                        {Object.values(LeadType).map((type) => (
                            <MenuItem key={type} value={type}>
                                <Typography fontSize={"13px"} fontWeight={"bold"}>
                                    {localizedLeadTypes[type as keyof typeof LeadType]}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                );
            },
            renderCell: (params: any) => (
                <Chip
                    label={localizedLeadTypes[params.value as keyof typeof LeadType]}
                    color={getStatusChipColor(params.value)}
                    variant="outlined"
                    sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                    }}
                />
            ),
        },
        {
            field: 'qualification', headerName: t("crm.lead.lead.qualification"), width: 120,
            editable: true,
            renderEditCell: (params: any) => {
                const currentValue = params.value || params.row.qualification || '';
                return (
                    <Select
                        value={currentValue}
                        label={t("crm.lead.lead.qualification")}
                        onChange={(e) => {
                            params.api.setEditCellValue({
                                ...params,
                                value: e.target.value
                            })
                        }}
                        sx={{ width: "100%" }}
                    >
                        {Object.values(LeadQualification).map((type) => (
                            <MenuItem key={type} value={type}>
                                <Typography fontSize={"13px"} fontWeight={"bold"}>
                                    {localizedLeadQualifications[type as keyof typeof LeadQualification]}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                )
            },
            renderCell: (params: any) => (
                <Typography fontSize={"12px"}>
                    {localizedLeadQualifications[params.value as keyof typeof LeadQualification]}
                </Typography>
            )
        },
        { field: 'country', headerName: t("crm.lead.lead.country"), width: 120, editable: true },
        { field: 'state', headerName: t("crm.lead.lead.state"), width: 120, editable: true },
        { field: 'city', headerName: t("crm.lead.lead.city"), width: 120, editable: true },
        {
            field: 'language', headerName: t("crm.lead.lead.language"), width: 100, editable: true,
            type: 'singleSelect',
            valueOptions: Object.values(Language).map((type) => ({
                label: localizedLanguages[type as keyof typeof Language]
            })),
            renderCell: (params: any) => (
                <Typography fontSize={"12px"}>
                    {localizedLanguages[params.value as keyof typeof Language]}
                </Typography>
            )
        },
        { field: 'leadValue', headerName: t("crm.lead.lead.leadValue"), width: 150, editable: true, filterable: false },
        { field: 'department', headerName: t("crm.lead.lead.department"), width: 150 },
        {
            field: 'actions',
            headerName: t("crm.lead.lead.actions"),
            width: downMd ? 50 : 120,
            sortable: false,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: any) => (
                <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 1 }}>
                    <Tooltip title={t("crm.lead.lead.view")}>
                        <IconButton
                            onClick={() => {
                                props.setSelectedLead(params.row);
                                props.setOpenUserDrawer(true);
                            }}
                            color="primary"
                            sx={{ p: "4px" }}
                        >
                            <VisibilityOutlined color="primary" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("crm.lead.lead.edit")}>
                        <IconButton
                            onClick={() => {
                                props.setSelectedLead(params.row);
                                navigate(updateLeadRoute(params?.row?._id));
                            }}
                            color="info"
                            sx={{ p: "4px", }}
                        >
                            <EditNoteRounded color="info" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {isJunk ?
                        <Tooltip title={t("crm.lead.lead.junk")}>
                            <IconButton
                                onClick={() => {
                                    leadUpdate({ filter: { _id: params.row?._id }, input: { isJunk: true } });
                                }}
                                color="error"
                                sx={{ p: "4px", }}
                            >
                                <DeleteOutlineRounded color="warning" fontSize="small" />
                            </IconButton>
                        </Tooltip> :
                        <Tooltip title={t("crm.lead.lead.unJunk")}>
                            <IconButton
                                onClick={() => {
                                    leadUpdate({ filter: { _id: params.row?._id }, input: { isJunk: false } });
                                }}
                                color="error"
                                sx={{ p: "4px", }}
                            >
                                <Icons.AssignmentReturnOutlined color="warning" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title={t("crm.lead.lead.delete")}>
                        <IconButton
                            onClick={() => {
                                props.setSelectedLead(params.row);
                                setOpenDeleteDialog(true);
                            }}
                            color="error"
                            sx={{ p: "4px", }}
                        >
                            <Icons.DeleteForeverOutlined color="error" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack >
            ),
        },
    ];

    const rows = leadListData?.data.map((lead, index) => ({
        id: index + 1,
        _id: lead._id,
        fullName: lead.fullName || '-',
        position: lead.position || '-',
        email: lead.email || '-',
        website: lead.website || '-',
        phoneNumber: lead.phoneNumber || '-',
        company: lead.company || '-',
        sector: lead.sector || '-',
        status: lead.status || null,
        source: lead.source || null,
        type: lead.type || '-',
        qualification: lead.qualification || '-',
        country: lead.country || '-',
        state: lead.state || '-',
        city: lead.city || '-',
        language: lead.language || '-',
        leadValue: lead.leadValue || '-',
        organization: lead.organization._id || '-',
        department: lead.department || '-'
    })) || [];

    const rowsLazy = leadListLazyData?.data.map((lead, index) => ({
        id: index + 1,
        _id: lead._id,
        fullName: lead.fullName || '-',
        position: lead.position || '-',
        email: lead.email || '-',
        website: lead.website || '-',
        phoneNumber: lead.phoneNumber || '-',
        company: lead.company || '-',
        sector: lead.sector || '-',
        status: lead.status || null,
        source: lead.source || null,
        type: lead.type || '-',
        qualification: lead.qualification || '-',
        country: lead.country || '-',
        state: lead.state || '-',
        city: lead.city || '-',
        language: lead.language || '-',
        leadValue: lead.leadValue || '-',
        organization: lead.organization._id || '-',
        department: lead.department || '-'
    })) || [];


    console.log("Rows Data:", rows); // DataGrid'e gönderilen tüm satırları kontrol edin
    const CustomToolbar = () => {

        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleImportClick = () => {
            if (inputFileRef.current) {
                inputFileRef.current.click();
            }
        };


        return (
            <GridToolbarContainer>
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarDensitySelector />
                    <Button
                        aria-controls={open ? 'import-export-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        startIcon={<Icons.ImportExport />}
                    >
                        {t("crm.lead.lead.importExport")}
                    </Button>
                    <Menu
                        id="import-export-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'import-export-button',
                        }}
                    >
                        <MenuItem onClick={() => handleDownloadForm()}>
                            <ListItemIcon>
                                <FileDownload fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t("crm.lead.lead.exportData")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => handleImportClick()}>
                            <ListItemIcon>
                                <Publish fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t("crm.lead.lead.importData")}</ListItemText>
                        </MenuItem>
                        <input
                            type="file"
                            hidden
                            ref={inputFileRef}
                            onChange={(e) => {
                                handleImportForm(e.target.files![0]);
                            }}
                        />

                        <MenuItem onClick={() => handleDownloadExampleForm()}>
                            <ListItemIcon>
                                <ArticleOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t("crm.lead.lead.downloadExample")}</ListItemText>
                        </MenuItem>
                    </Menu>
                    <GridToolbarFilterButton />
                    <Box display="flex" alignItems="center">
                        <ToggleButtonGroup
                            value={b2Type}
                            sx={{ ml: 2 }}
                            size="small"
                            exclusive
                            color='info'
                            onChange={(_, value) => setB2Type(value)}
                        >
                            <ToggleButton value="b2b">
                                <Typography fontWeight={"bold"} fontSize={"12px"}>{t("crm.lead.lead.b2b")}</Typography>
                            </ToggleButton>
                            <ToggleButton value="b2c">
                                <Typography fontWeight={"bold"} fontSize={"12px"}>{t("crm.lead.lead.b2c")}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <ToggleButtonGroup
                            value={isJunk}
                            sx={{ ml: 2 }}
                            color='warning'
                            size="small"
                            exclusive
                            onChange={() => setIsJunk(!isJunk)}
                        >
                            <ToggleButton value={false}>
                                <DeleteOutlineRounded fontSize="small" sx={{ mr: 1 }} />
                                <Typography fontWeight={"bold"} fontSize={"12px"}>{t("crm.lead.lead.isJunk")}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </GridToolbarContainer>
            </GridToolbarContainer>
        );
    }

    function handleFilterChange(newFilterModel: any) {
        setFilterModel(newFilterModel);
        setLeadListFilter((prevFilter) => {
            const newFilter = newFilterModel.items.reduce((acc: any, item: any) => {
                if (item.field && item.value) {
                    acc[item.field] = item.value;
                }
                return acc;
            }, {});

            if (JSON.stringify(prevFilter?.input?.filter) === JSON.stringify(newFilter)) {
                return prevFilter;
            }

            return {
                input: {
                    filter: {
                        ...prevFilter?.input?.filter,
                        ...newFilter,
                    },
                },
            };
        });
    };

    function handleProcessRowUpdate(newRow: any, oldRow: any) {
        if (newRow.fullName !== oldRow.fullName) {
            leadUpdate({ filter: { _id: newRow._id }, input: { fullName: newRow.fullName } });
        }
        if (newRow.position !== oldRow.position) {
            leadUpdate({ filter: { _id: newRow._id }, input: { position: newRow.position } });
        }
        if (newRow.email !== oldRow.email) {
            leadUpdate({ filter: { _id: newRow._id }, input: { email: newRow.email } });
        }
        if (newRow.website !== oldRow.website) {
            leadUpdate({ filter: { _id: newRow._id }, input: { website: newRow.website } });
        }
        if (newRow.phoneNumber !== oldRow.phoneNumber) {
            leadUpdate({ filter: { _id: newRow._id }, input: { phoneNumber: newRow.phoneNumber } });
        }
        if (newRow.company !== oldRow.company) {
            leadUpdate({ filter: { _id: newRow._id }, input: { company: newRow.company } });
        }
        if (newRow.sector !== oldRow.sector) {
            leadUpdate({ filter: { _id: newRow._id }, input: { sector: newRow.sector } });
        }
        if (newRow.type !== oldRow.type) {
            leadUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type } });
        }
        if (newRow.qualification !== oldRow.qualification) {
            leadUpdate({ filter: { _id: newRow._id }, input: { qualification: newRow.qualification } });
        }
        if (newRow.country !== oldRow.country) {
            leadUpdate({ filter: { _id: newRow._id }, input: { country: newRow.country } });
        }
        if (newRow.state !== oldRow.state) {
            leadUpdate({ filter: { _id: newRow._id }, input: { state: newRow.state } });
        }
        if (newRow.city !== oldRow.city) {
            leadUpdate({ filter: { _id: newRow._id }, input: { city: newRow.city } });
        }
        if (newRow.language !== oldRow.language) {
            leadUpdate({ filter: { _id: newRow._id }, input: { language: newRow.language } });
        }
        if (newRow.status !== oldRow.status) {
            leadUpdate({ filter: { _id: newRow._id }, input: { statusId: newRow.status?._id } });
        }
        if (newRow.source !== oldRow.source) {
            leadUpdate({ filter: { _id: newRow._id }, input: { sourceId: newRow.source?._id } });
        }
        return newRow;
    };

    function handleDownloadExampleForm() {

        let data = JSON.stringify({
            "filter": {}
        });

        let config = {
            method: 'post',
            url: `${BASE_URL}/leads/import/example`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getAccessToken()}`,
            },
            data: data,
            responseType: 'blob'
        };

        axios.request(config as any)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `lead-form-example-import.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                enqueueSnackbar(t("crm.lead.lead.downloadExampleFormSuccessfully"), { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(t("crm.lead.lead.downloadExampleFormError"), { variant: "error" });
            });



    };

    function handleDownloadForm() {

        let data = JSON.stringify({
            "filter": leadListFilter.input?.filter
        });

        let config = {
            method: 'post',
            url: `${BASE_URL}/leads/export`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getAccessToken()}`,
            },
            data: data,
            responseType: 'blob'
        };

        axios.request(config as any)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `lead-forms-${moment().format("DD-MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                enqueueSnackbar(t("crm.lead.lead.downloadFormSuccessfully"), { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(t("crm.lead.lead.downloadFormError"), { variant: "error" });
            });



    };

    function handleImportForm(file: File) {

        let formData = new FormData();
        formData.append("file", file);

        let config = {
            method: 'post',
            url: `${BASE_URL}/leads/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${getAccessToken()}`,
            },
            data: formData,
        };

        axios.request(config as any)
            .then((response) => {
                enqueueSnackbar(t("crm.lead.lead.importedSuccessfully"), { variant: "success" });
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((error) => {
                enqueueSnackbar(t("crm.lead.lead.importedError"), { variant: "error" });
            });

    };

    // const CustomSkeletonOverlay = () => (
    //     <GridOverlay>
    //         <Stack width="100%" height="100%" alignItems="center" justifyContent="flex-start" mt={2}>
    //             {[...Array(10)].map((_, index) => (
    //                 <Skeleton key={index} variant="rectangular" width="90%" height={20} sx={{ mb: 1, borderRadius: 2 }} />
    //             ))}
    //         </Stack>
    //     </GridOverlay>
    // );

    useEffect(() => {
        leadListLazy({
            input: {
                filter: {
                    isJunk: true
                }
            }
        })
    }, [isJunk])

    useEffect(() => {
        if (leadUpdateError) {
            enqueueSnackbar((leadUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [leadUpdateError]);

    useEffect(() => {
        if (leadListError) {
            enqueueSnackbar((leadListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [leadListError]);

    const [cellModesModel, setCellModesModel] = useState<
        GridCellModesModel
    >({});

    const handleCellClick = useCallback((params: GridCellParams) => {
        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View }
                            }),
                            {}
                        )
                    }),
                    {}
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {}
                    ),
                    [params.field]: { mode: GridCellModes.Edit }
                }
            };
        });
    }, []);

    const handleCellModesModelChange = useCallback((newModel: any) => {
        setCellModesModel(newModel);
    }, []);

    return <Stack direction="row" justifyContent="space-between" mt={2} height={"100%"}>
        {leadListData?.data?.length !== 0 ?
            <DataGridPremium
                localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
                // experimentalFeatures={{ newEditingApi: true }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            website: false,
                            company: false,
                            sector: false,
                            country: false,
                            state: false,
                            city: false,
                            language: false,
                            leadValue: false,
                            department: false,
                        },
                    },
                }}
                showCellVerticalBorder
                showColumnVerticalBorder
                rows={!isJunk ? rowsLazy : rows}
                columns={columnsList}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableAggregation
                disableRowGrouping
                loading={leadListLoading || props.leadStatusesLoading || leadUpdateLoading || props.leadSourcesLoading || leadListFetching}
                scrollbarSize={1}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                pagination
                paginationModel={
                    {
                        pageSize: 5,
                        page: 0
                    }
                }
                scrollEndThreshold={10}
                rowsLoadingMode="server"
                slots={{
                    toolbar: CustomToolbar,
                    filterPanel: CustomFilterPanel,
                    columnMenu: CustomColumnMenu,
                    // loadingOverlay: CustomSkeletonOverlay,
                }}
                filterModel={filterModel}
                onFilterModelChange={handleFilterChange}
                filterDebounceMs={1000}
                processRowUpdate={handleProcessRowUpdate}
                pinnedColumns={{ left: ["id"], right: ["actions"] }}
                sx={tableStyle}
            />
            : <Stack
                alignItems="center"
                justifyContent="center"
                bgcolor={"background.default"}
                gap={1}
                borderRadius={3}
                width={"100%"}
                height={"100%"}
            >
                <Icons.ContentPasteOffOutlined sx={{ opacity: 0.2, fontSize: "100px" }} />
                <Typography variant="body2">{t("crm.lead.lead.emptyState1")}</Typography>
                <Typography variant="body2">{t("crm.lead.lead.emptyState2")}</Typography>
                <Stack direction="row" spacing={1} mt={2}>
                    <OutlinedButton
                        title={t("crm.lead.lead.createLead")}
                        onClick={() => props.setOpenLeadCreateDrawer(true)}
                        leftIcon={<Add sx={{ mr: 1 }} fontSize="small" />}
                    />
                    <OutlinedButton
                        title={t("crm.lead.lead.importLead")}
                        leftIcon={<FileUpload sx={{ mr: 1 }} fontSize="small" />}
                        onClick={() => navigate(importLeadRoute())}
                    />
                </Stack>
            </Stack>
        }


        <LeadStatusCreateDrawer
            open={leadStatusCreateOpen}
            setOpen={setLeadStatusCreateOpen}
        />

        <LeadSourceCreateDrawer
            open={leadSourceCreateOpen}
            setOpen={setLeadSourceCreateOpen}
        />

        <DeleteDialog
            open={openDeleteDialog}
            item={props.selectedLead}
            onClose={() => setOpenDeleteDialog(false)}
            key={props.selectedLead?._id}
        />
    </Stack>
}

export default ListView



const CustomUserItem = (props: Readonly<GridColumnMenuItemProps>) => {
    const { myCustomHandler, myCustomValue } = props;
    return (
        <MenuItem onClick={myCustomHandler}>
            <ListItemIcon>
                <Icons.SettingsApplications fontSize="small" />
            </ListItemIcon>
            <ListItemText>{myCustomValue}</ListItemText>
        </MenuItem>
    );
}

const CustomFilterPanel = (props: any) => {
    return (
        <GridFilterPanel
            logicOperators={[]}
        />
    );
}

const CustomColumnMenu = (props: Readonly<GridColumnMenuProps>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { colDef } = props;
    if (colDef.field === 'roles') {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuUserItem: CustomUserItem,
                }}
                slotProps={{
                    columnMenuUserItem: {
                        displayOrder: 15,
                        myCustomValue: t("crm.lead.lead.addNewRole"),
                        myCustomHandler: () => navigate(createLeadRoute())
                    },
                }}
            />
        )
    } else {
        return <GridColumnMenu {...props} />
    }
}



const tableStyle = {
    fontSize: "12px",
    '& .MuiDataGrid-footerContainer': {
        height: '20px !important',
        maxHeight: '20px !important',
        m: 0,
    },
    '& .MuiDataGrid-toolbarContainer > button': {
        fontSize: "12px",
    },
    '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
        fontSize: "12px",
        p: "2px"
    },
    '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
        width: "16px",
        height: "16px",
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}




function getStatusChipColor(status: any) {
    switch (status) {
        case 'cold':
            return 'info';
        case 'warm':
            return 'warning';
        case 'hot':
            return 'error';
        default:
            return 'default';
    }
};
