

import { SupportDomainLayout } from "../layouts";
import { SupportRouter } from "../subdomains/support/routes/SupportRouter";
import { supportDomainRoutes } from "./support.base.route";
import { Route, Routes } from "react-router-dom";

export const SupportDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SupportDomainLayout />}>
        <Route index element={<SupportRouter />} />
        <Route
          path={`${supportDomainRoutes.subdomains.support.base}/*`}
          element={<SupportRouter />}
        />
        {/* Todo 
         <Route index element={<EmployeesRouter />} />
         <Route
          path={`${customerDomainRoutes.subdomains.employees.base}/*`}
          element={<EmployeesRouter />}
        /> */}
      </Route>
    </Routes>
  );
};
