

import { commonRoutes } from "routes/route.common";

export const leadDomainRoutes = {
  base: "lead",
  subdomains: {
    lead: {
      base: "leads",
    },
    forms: {
      base: "forms",
    },
    statuses: {
      base: "statuses"
    },
    sources: {
      base: "sources"
    }
  },
};


export const baseLeadRoute = (): string => `/${leadDomainRoutes.base}/`;
export const listLeadRoute = (): string => `/${leadDomainRoutes.base}`;
export const viewLeadRoute = (id: string): string => `/${leadDomainRoutes.base}/${id}`;
export const createLeadRoute = (): string =>
  `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.lead.base}/${commonRoutes.create}`;
export const updateLeadRoute = (id: string): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.lead.base}/${id}/${commonRoutes.update}`;
export const importLeadRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.lead.base}/${commonRoutes.import}`

export const listFormsRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.forms.base}`
export const listStatusesRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.statuses.base}`;
export const listSourcesRoute = (): string => `/${leadDomainRoutes.base}/${leadDomainRoutes.subdomains.sources.base}`;


