/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Skeleton, ListItemIcon, ListItemText, MenuItem, IconButton, Chip, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import { useAppDispatch } from "context";
import { organizationalChartActions } from "../../../context";
import { createEmployeesRoute, rolesEmployeesRoute } from "../../../routes/organizationalChart.base.route";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGridPremium, GridColumnMenu, GridColumnMenuItemProps, GridColumnMenuProps, GridFilterPanel, GridToolbar } from "@mui/x-data-grid-premium";
import { useUserListQuery, useUserUpdateMutation, useUserDeleteMutation } from "../context/employees.api";
import { IUserListInput } from "corede-common-cocrm";
import { IGraphqlVariables, Language, unknownError } from "corede-common";
import { DeleteOutlined, SettingsApplications, Visibility } from "@mui/icons-material";
import { UserDetailDrawer } from "./UserDetailDrawer";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { trTR } from '@mui/x-data-grid/locales';

const getStatusChipColor = (status: any) => {
  switch (status) {
    case 'pending':
      return 'info';
    case 'active':
      return 'secondary';
    default:
      return 'default';
  }
};

function CustomUserItem(props: Readonly<GridColumnMenuItemProps>) {
  const { myCustomHandler, myCustomValue } = props;
  return (
    <MenuItem onClick={myCustomHandler}>
      <ListItemIcon>
        <SettingsApplications fontSize="small" />
      </ListItemIcon>
      <ListItemText>{myCustomValue}</ListItemText>
    </MenuItem>
  );
}

const UserList = () => {
  // general
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();
  const [openUserDetail, setOpenUserDetail] = useState(false);
  const [openUserDelete, setOpenUserDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [userListFilter, setUserListFilter] = useState<IGraphqlVariables<IUserListInput>>({
    input: {
      filter: {
        email: undefined,
        organizationId: undefined,
        role: undefined,
        status: undefined,
        createdById: undefined
      }
    }
  });

  function CustomFilterPanel(props: any) {
    return (
      <GridFilterPanel
        logicOperators={[]}
      />
    );
  }

  function CustomColumnMenu(props: Readonly<GridColumnMenuProps>) {
    const { colDef } = props;
    if (colDef.field === 'roles') {
      return (
        <GridColumnMenu
          {...props}
          slots={{
            // Add new item
            columnMenuUserItem: CustomUserItem,
          }}
          slotProps={{
            columnMenuUserItem: {
              displayOrder: 15,
              myCustomValue: t('crm.organizationalChart.employees.addNewRole'),
              myCustomHandler: () => navigate(rolesEmployeesRoute()),
            },
          }}
        />
      )
    } else {
      return <GridColumnMenu {...props} />;
    }
  }

  // queries 
  const { data: userListData, isLoading: userListLoading, error: userListError } = useUserListQuery(userListFilter);
  const [userUpdate] = useUserUpdateMutation();
  const [userDelete, { data: userDeleteData, error: userDeleteError }] = useUserDeleteMutation();

  const columns = [
    { field: 'id', headerName: t('crm.organizationalChart.employees.id'), width: 60, sortable: true, filterable: false, dragging: false, filtered: false, numeric: true },
    { field: 'name', headerName: t('crm.organizationalChart.employees.name'), width: 110, editable: true, filterable: false },
    { field: 'surname', headerName: t('crm.organizationalChart.employees.surname'), width: 110, editable: true, filterable: false },
    { field: 'roles', headerName: t('crm.organizationalChart.employees.roles'), width: 170, editable: true, disableGrouping: true },
    { field: 'email', headerName: t('crm.organizationalChart.employees.emailAddress'), width: 180, filterable: true },
    {
      field: 'status', headerName: t('crm.organizationalChart.employees.status'), width: 120, filterable: true,
      renderCell: (params: any) => (
        <Chip
          label={params.value}
          color={getStatusChipColor(params.value)}
          variant="outlined"
          sx={{
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        />
      ),
    },
    { field: 'emailVerified', headerName: t('crm.organizationalChart.employees.emailVerified'), width: 150, filterable: false },
    { field: 'phone', headerName: t('crm.organizationalChart.employees.phoneNumber'), width: 180, filterable: false },
    { field: 'country', headerName: t('crm.organizationalChart.employees.country'), width: 150, filterable: false },
    { field: 'city', headerName: t('crm.organizationalChart.employees.city'), width: 150, filterable: false },
    { field: 'lastLoginDate', headerName: t('crm.organizationalChart.employees.lastLoginDate'), width: 150, filterable: false },
    { field: 'createdAt', headerName: t('crm.organizationalChart.employees.createdAt'), width: 180, filterable: false },
    {
      field: 'actions',
      headerName: t('crm.organizationalChart.employees.actions'),
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              setSelectedUser(params.row);
              setOpenUserDetail(true);
            }}
            color="primary"
            aria-label="view"
          >
            <Visibility color="primary" fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setSelectedUser(params.row);
              setOpenUserDelete(true);
            }}
            color="error"
            aria-label="delete"
          >
            <DeleteOutlined color="error" fontSize="small" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const rows = userListData ? userListData?.data.map((user, index) => ({
    _id: user._id,
    id: index + 1,
    name: user.name,
    surname: user.surname,
    roles: user.roles && user.roles.length ? user.roles.map(role => role.name).join(', ') : '-',
    email: user.email,
    status: user.status,
    phone: (user as any).phoneNumber || '-',
    lastLoginDate: (user as any).lastLoginDate ? new Date((user as any).lastLoginDate).toLocaleDateString() : '-',
    emailVerified: (user as any).emailVerified ? t('crm.organizationalChart.employees.yes') : t('crm.organizationalChart.employees.no'),
    country: user.country || '-',
    city: user.city || '-',
    createdAt: new Date(user.createdAt).toLocaleDateString(),
  })) : [];

  const handleFilterChange = (newFilterModel: any) => {
    console.log('Filter Model:', newFilterModel);
    setFilterModel(newFilterModel);

    setUserListFilter((prevFilter) => {
      const newFilter = newFilterModel.items.reduce((acc: any, item: any) => {
        if (item.field && item.value) {
          acc[item.field] = item.value;
        }
        return acc;
      }, {});

      if (JSON.stringify(prevFilter?.input?.filter) === JSON.stringify(newFilter)) {
        return prevFilter;
      }

      return {
        input: {
          filter: {
            ...prevFilter?.input?.filter,
            ...newFilter,
          },
        },
      };
    });
  };

  const handleProcessRowUpdate = (newRow: any, oldRow: any) => {
    if (newRow.name !== oldRow.name) {
      userUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    }
    if (newRow.surname !== oldRow.surname) {
      userUpdate({ filter: { _id: newRow._id }, input: { surname: newRow.surname } });
    }
    return newRow;
  };

  function handleSelectUser(direction: 'next' | 'back') {
    if (!selectedUser || !userListData?.data?.length) return;

    const currentIndex = userListData.data.findIndex(lead => lead._id === selectedUser._id);
    console.log({ currentIndex }, { selectedUser });

    let newIndex = currentIndex;
    if (direction === 'next') {
      newIndex = currentIndex + 1;
    } else if (direction === 'back') {
      newIndex = currentIndex - 1;
    }

    if (newIndex >= 0 && newIndex < userListData.data.length) {
      const newSelectedLead = { ...userListData.data[newIndex], id: newIndex + 1 };
      setSelectedUser(newSelectedLead);
    }
  }


  useEffect(() => {
    if (userDeleteData) {
      enqueueSnackbar(t('crm.organizationalChart.employees.userDeleteSuccess'), { variant: 'success' });
    }
  }, [userDeleteData])

  useEffect(() => {
    if (userDeleteError) {
      enqueueSnackbar(t('crm.organizationalChart.employees.userDeleteError'), { variant: 'error' });
    }
  }, [userDeleteError])

  useEffect(() => {
    if (userListError) {
      enqueueSnackbar((userListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [userListError])

  useEffect(() => {
    dispatch(
      organizationalChartActions.setRightButton({
        title: t(`crm.organizationalChart.employees.create`),
        path: createEmployeesRoute(),
        icon: null,
      })
    );
  }, [dispatch]);


  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <Stack direction="row" justifyContent="space-between" height={"100%"}>
        {true ?
          <DataGridPremium
            localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
            rows={rows}
            columns={columns}
            checkboxSelection={false}
            disableRowSelectionOnClick
            disableAggregation
            disableRowGrouping
            loading={userListLoading}
            rowsLoadingMode="server"
            slots={{
              toolbar: GridToolbar,
              filterPanel: CustomFilterPanel,
              columnMenu: CustomColumnMenu
            }}
            filterModel={filterModel}
            onFilterModelChange={handleFilterChange}
            filterDebounceMs={1000}
            processRowUpdate={handleProcessRowUpdate}
            pinnedColumns={{ left: ["id"], right: ["actions"] }}
          />
          : [1, 2, 3].map((key) => (
            <Stack
              key={key}
              direction="row"
              justifyContent="space-between"
              width={340}
              alignItems={"center"}
              p={2}
            >
              <Skeleton
                variant="rectangular"
                width={340}
                height={60}
                sx={{ borderRadius: 2 }}
              />
            </Stack>
          ))}
      </Stack>

      <UserDetailDrawer
        open={openUserDetail}
        setOpen={setOpenUserDetail}
        selectedUser={selectedUser}
        handleSelect={handleSelectUser}
      />

      <Dialog open={openUserDelete} onClose={() => setOpenUserDelete(false)}>
        <DialogTitle>Add Container</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('crm.organizationalChart.employees.deleteMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenUserDelete(false)}
          >
            {t('crm.organizationalChart.employees.cancel')}
          </Button>
          <Button
            onClick={() => {
              userDelete({ input: { _id: selectedUser?._id } });
              setOpenUserDelete(false);
            }}
            variant="contained"
            color="error"
          >
            {t('crm.organizationalChart.employees.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid >
  );
};

export default UserList;
