/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, CircularProgress, Stack, IconButton, Checkbox, TextField, Dialog, DialogContent, DialogActions } from "@mui/material";
import { useFormik } from "formik";
import { registerValidationSchema } from 'utils/validations';
import { AuthLayout } from "../layouts";
import { useRegisterMutation, setUser } from "../context";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "context";
import { useEffect, useState } from "react";
import { Iconify } from "components";
import LoginCo from "assets/images/login-co.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useTranslation } from 'react-i18next';

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getRegister, { data, isLoading, error }] = useRegisterMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [visibility, setVisibility] = useState<string[]>([]);
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState<boolean>(false);

  const handleChangeVisibility = (field: string) => {
    if (visibility.includes(field)) {
      setVisibility(visibility.filter(item => item !== field));
    } else {
      setVisibility([...visibility, field]);
    }
  };

  const { values, handleSubmit, setFieldValue, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: registerValidationSchema,
    initialValues: {
      name: undefined,
      surname: undefined,
      email: "",
      password: "",
      passwordAgain: "",
      role: "user",
      referrerCode: undefined,
      isChecked: false
    },
    onSubmit: async (values) => {
      try {
        await getRegister({
          input: {
            name: values.name,
            surname: values.surname,
            email: values.email,
            password: values.password,
            role: values.role,
            referrerCode: values.referrerCode
          }
        });
      } catch (error) {
        enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t("auth.signupSuccess"), { variant: 'success' });
      dispatch(setUser(data));
      navigate(routes.auth.confirmation + "?token=" + data?.devMetaData?.token + "&code=" + data?.devMetaData?.code);
    }
  }, [data, dispatch, enqueueSnackbar]);

  return (
    <AuthLayout>
      <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>

        </Typography>
        <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>
          <Box display={{ xs: 'flex', md: 'none' }} height={100} />
          <Box
            sx={{
              width: { xs: '100%', md: '80%', lg: '60%' },
              mt: 2,
              minHeight: "80vh",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              alignItems: 'center',
              gap: 2,
            }}
            px={{ xs: 1, md: 7 }}
          >
            <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
            <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} py={2}>
              <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
                {t("auth.letsRegister")}
              </Typography>
            </Box>

            <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} alignItems={"center"}>
              <form onSubmit={handleSubmit as any} style={{ width: '100%' }}>
                <Box display={"flex"} flexDirection={"column"} gap={2.7} alignItems={"center"}>
                  <Stack direction={"row"} gap={3} width={"100%"} justifyContent={"space-between"}>
                    <TextField
                      placeholder={t("auth.name")}
                      type="text"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      error={!!errors.name && touched.name}
                      helperText={(errors.name && touched.name) ? errors.name : ""}
                      onBlur={handleBlur("name")}
                      fullWidth
                    />
                    <TextField
                      placeholder={t("auth.surname")}
                      type="text"
                      value={values.surname}
                      onChange={(e) => setFieldValue("surname", e.target.value)}
                      error={!!errors.surname && touched.surname}
                      helperText={(errors.surname && touched.surname) ? errors.surname : ""}
                      onBlur={handleBlur("surname")}
                      fullWidth
                    />
                  </Stack>
                  <TextField
                    placeholder={t("auth.emailAddress")}
                    type="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    error={!!errors.email && touched.email}
                    helperText={(errors.email && touched.email) ? errors.email : ""}
                    onBlur={handleBlur("email")}
                    fullWidth
                  />
                  <TextField
                    placeholder={t("auth.password")}
                    type={visibility.includes("password") ? 'text' : 'password'}
                    value={values.password}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                    error={!!errors.password && touched.password}
                    helperText={(errors.password && touched.password) ? errors.password : ""}
                    onBlur={handleBlur("password")}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <IconButton onClick={() => handleChangeVisibility("password")}>
                          {visibility.includes("password") ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }}
                  />
                  <TextField
                    placeholder={t("auth.passwordAgain")}
                    type={visibility.includes("passwordAgain") ? 'text' : 'password'}
                    value={values.passwordAgain}
                    onChange={(e) => setFieldValue("passwordAgain", e.target.value)}
                    error={!!errors.passwordAgain && touched.passwordAgain}
                    helperText={(errors.passwordAgain && touched.passwordAgain) ? errors.passwordAgain : ""}
                    onBlur={handleBlur("passwordAgain")}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <IconButton onClick={() => handleChangeVisibility("passwordAgain")}>
                          {visibility.includes("passwordAgain") ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    }}
                  />
                  <Stack direction={"row"} width={"100%"} alignItems={"center"} onClick={() => setFieldValue("isChecked", !values.isChecked)}>
                    <Checkbox
                      checked={values.isChecked}
                      onChange={() => setFieldValue("isChecked", !values.isChecked)}
                      color="secondary"
                    />
                    <Typography fontSize={14} fontWeight={400} textAlign={"center"} py={1} sx={{ cursor: "pointer" }}>
                      {t("auth.iAccept")}
                    </Typography>
                    <Typography fontSize={14} fontWeight={400} textAlign={"center"} py={1} sx={{ cursor: "pointer", ml: 1 }}
                      onClick={(e: any) => {
                        setOpenTermsAndConditions(true);
                        e.stopPropagation();
                      }}
                    >
                      {t("auth.termsAndConditions")}
                    </Typography>
                  </Stack>
                  {errors.isChecked && touched.isChecked && (
                    <Typography fontSize={12} marginTop={-2} fontWeight={400} color={"error"}>
                      {errors.isChecked}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit as any}
                    disabled={!!(isLoading || !values.isChecked || errors.email || errors.password || errors.passwordAgain)}
                    sx={{ fontSize: 14, height: 56 }}
                  >
                    {(isLoading) ? <CircularProgress size="1rem" /> : t("auth.signUp")}
                  </Button>
                </Box>
              </form>
            </Box>
            <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} width={"100%"}>
              <Box bgcolor={"#aaa"} width={"80%"} height={"1px"} sx={{ borderRadius: 1 }} />
              <Typography width="100%" fontSize={14} fontWeight={400} color={"GrayText"} textAlign={"center"}>
                {t("auth.orContinueWith")}
              </Typography>
              <Box bgcolor={"#aaa"} width={"80%"} height={"1px"} sx={{ borderRadius: 1 }} />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} justifyContent={"center"} width={"100%"}>
              <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
                <Iconify icon={"ant-design:google-outlined"} width={24} sx={{ color: "#fff" }} />
              </IconButton>
              <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
                <Iconify icon={"ic:baseline-apple"} width={24} sx={{ color: "#fff" }} />
              </IconButton>
              <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
                <Iconify icon={"ri:linkedin-fill"} width={24} sx={{ color: "#fff" }} />
              </IconButton>
            </Stack>
          </Box>
        </Grid>

        <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
          {t("auth.alreadyHaveAccount")} <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.login)}>{t("auth.login")}</span>
        </Typography>
      </Stack>

      <Dialog open={openTermsAndConditions} onClose={() => setOpenTermsAndConditions(false)}>
        <DialogContent>
          {/* Terms and Conditions Content */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTermsAndConditions(false)}>{t("auth.close")}</Button>
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}

export default Signup;
