
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useAppSelector, useAppDispatch } from "context";
import Router from 'Router';
import { theme } from 'theme';
import { SnackbarProvider } from 'components';
import { useEffect } from 'react';
import { setThemeMode } from 'context';

const Wrapper = () => {

  const dispatch = useAppDispatch();
  const { themeMode } = useAppSelector(state => state.theme);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      dispatch(setThemeMode(theme as "light" | "dark"));
    }
  }, [dispatch]);


  return (
    <ThemeProvider theme={theme(themeMode === "light" ? "light" : "dark")}>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router />
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Wrapper


