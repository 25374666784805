import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateUpdateLeadInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      fullName: ValidationHelper.RequiredString(
        getTranslatedText("fullNameIsRequired")),
      statusId: ValidationHelper.RequiredString(
        getTranslatedText("statusIsRequired")),
      sourceId: ValidationHelper.RequiredString(
        getTranslatedText("sourceIsRequired")),
      tags: ValidationHelper.RequiredArray(
        getTranslatedText("tagsIsRequired")),
      position: ValidationHelper.RequiredString(
        getTranslatedText("positionIsRequired")),
      email: ValidationHelper.RequiredEmail(
        getTranslatedText("emailIsInvalid"),
        getTranslatedText("emailIsRequired")),
      company: ValidationHelper.RequiredString(
        getTranslatedText("companyIsRequired")),
      sector: ValidationHelper.RequiredString(
        getTranslatedText("sectorIsRequired")),
      country: ValidationHelper.RequiredString(
        getTranslatedText("countryIsRequired")),
      assignedIds: ValidationHelper.NotRequiredArray(),
      website: ValidationHelper.NotRequiredString(),
      phoneNumber: ValidationHelper.NotRequiredString(),
      type: ValidationHelper.NotRequiredString(),
      qualification: ValidationHelper.NotRequiredString(),
      state: ValidationHelper.NotRequiredString(),
      city: ValidationHelper.NotRequiredString(),
      isPublic: ValidationHelper.NotRequiredBoolean(),
      isJunk: ValidationHelper.NotRequiredBoolean(),
      note: ValidationHelper.NotRequiredString(),
      language: ValidationHelper.NotRequiredString(),
    }),
  });