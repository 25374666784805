import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseOrganizationRoute,
} from "../routes/dashboard.base.route";
 
export const useDashboardDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("dashboard"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseOrganizationRoute(),
  };
};
