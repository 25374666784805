


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseOrganizationalChartRoute,
  listDepartmentsRoute,
  listEmployeesRoute,
  listPermissionsRoute,
} from "../routes/organizationalChart.base.route";

import { useTranslation } from 'react-i18next';

export const useOrganizationalChartDomainMenuItem = () => {
  const { t } = useTranslation();

  return {
    title: t("organizationalChart"),
    path: baseOrganizationalChartRoute(),
    children: [
      {
        title: t("employees"),
        path: listEmployeesRoute(),
      },
      {
        title: t("departments"),
        path: listDepartmentsRoute(),
      },
      {
        title: t("permissions"),
        path: listPermissionsRoute(),
      },
    ],
  };
};

