const locale = {
  leadStatuses: "Leadstatuses",
  title: "Lead Statüleri",
  create: "Statü Oluştur",
  delete: "Statü Sil",
  deleteConfirm: "Statüyü silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Statü başarıyla silindi!",
  createStatus: "Statü Ekle",
  leadStatusCreateSuccess: "Statü başarıyla eklendi!",
  name: "Statü Adı",
  color: "Statü Rengi",
  icon: "Statü Simgesi",
  showMore: "Daha fazla",
};

export default locale;
