/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "context";
import { organizationalChartActions } from "../../../context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { listEmployeesRoute } from "../../../routes/organizationalChart.base.route";
import { useUserDetailQuery } from "apps/auth/context";
import { ExpandMore } from "@mui/icons-material";
import { usePermissionListQuery } from "../context/employees.api";

interface Permission {
  _id: string;
  action: string;
  subject: string;
  actionScope: string;
  description: string;
}



const View = () => {
  // general
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listEmployeesRoute());
  }
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<string>("");
  const [selectedRoleName, setSelectedRoleName] = useState<string>("");
  const [roleName, setRoleName] = useState<string>("");




  // queries
  const { data: userData, isLoading: userLoading, isFetching: userFetching, error: userError } = useUserDetailQuery({
    input: {
      _id: id || ""
    }
  });
  const { data: permissionListData, isLoading: permissionListLoading, error: permissionListError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["manage", "view", "import", "export"]
      }
    }
  } as any);
  const { data: permissionListAllData, isLoading: permissionListAllLoading, error: permissionListAllError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["import", "export", "list", "create", "update", "delete", "detail"]
      }
    }
  } as any);

  console.log(userData?.roles)

  const roles = userData?.roles || [];
  const permissions = permissionListData?.data || [];
  const permissionsAll = permissionListAllData?.data || [];

  const groupBySubject = (permissions: Permission[]) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.subject]) {
        acc[permission.subject] = [];
      }
      acc[permission.subject].push(permission);
      return acc;
    }, {} as Record<string, Permission[]>);
  };


  const groupedPermissions = groupBySubject(permissions as Permission[]);
  const groupedPermissionsAll = groupBySubject(permissionsAll as Permission[]);
  console.log(groupedPermissions)
  console.log(groupedPermissionsAll)



  useEffect(() => {
    if (selectedRoleName) {
      setRoleName(selectedRoleName === t("crm.organizationalChart.employees.addRole") ? "" : selectedRoleName);
      setSelectedRoleId(roles.find((role) => role.name === selectedRoleName)?._id ?? "");
      setSelectedPermissionIds(roles.find((role) => role.name === selectedRoleName)?.permissions.map((permission) => permission._id) ?? []);
    }
  }, [selectedRoleName, roles]);

  useEffect(() => {
    setSelectedRoleName(roles[0]?.name ?? "");
  }, [roles]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} p={5} mt={2}>
        {false ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            {Object.keys(groupedPermissions).map((subject) => (
              <Accordion key={subject}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                    <Typography fontSize={"large"} fontWeight={"bold"}>{subject?.slice(0, 1).toUpperCase() + subject?.slice(1)}</Typography>
                    <Stack direction="row" alignItems="center" justifyContent={"flex-end"}>
                      <Stack direction="row" spacing={4}>
                        <Stack width={"150px"}>
                          {groupedPermissions[subject].filter((permission) => permission.actionScope === 'org').map((permission) => {
                            const isManage = permission.action === 'manage';
                            const isView = permission.action === 'view';
                            const relatedPermissions = isManage
                              ? ['create', 'delete', 'update']
                              : isView
                                ? ['list', 'detail']
                                : [];

                            const relatedPermissionIds = groupedPermissionsAll[subject]
                              ?.filter((p) => relatedPermissions.includes(p.action) && p.actionScope === permission.actionScope)
                              .map((p) => p._id) || [];

                            const allRelatedChecked = relatedPermissionIds.every((id) => selectedPermissionIds.includes(id));
                            const someRelatedChecked = relatedPermissionIds.some((id) => selectedPermissionIds.includes(id));
                            const checkState = isManage || isView ? (allRelatedChecked ? true : someRelatedChecked ? 'indeterminate' : false) : selectedPermissionIds.includes(permission._id);

                            return (
                              <FormControlLabel
                                key={permission._id}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                control={
                                  <Checkbox
                                    checked={checkState === true}
                                    indeterminate={checkState === 'indeterminate'}
                                    onChange={() => {
                                      let newSelectedPermissions = [...selectedPermissionIds];

                                      if (checkState === true) {
                                        newSelectedPermissions = newSelectedPermissions.filter((id) => id !== permission._id && !relatedPermissionIds.includes(id));
                                      } else {
                                        newSelectedPermissions = [...new Set([...newSelectedPermissions, permission._id, ...relatedPermissionIds])];
                                      }

                                      setSelectedPermissionIds(newSelectedPermissions);
                                    }}
                                  />
                                }
                                label={`${permission.action} (org)`}
                              />
                            );
                          })}
                        </Stack>
                        <Stack width={"150px"}>
                          {groupedPermissions[subject].filter((permission) => permission.actionScope === 'dep').map((permission) => {
                            const isManage = permission.action === 'manage';
                            const isView = permission.action === 'view';
                            const relatedPermissions = isManage
                              ? ['create', 'delete', 'update']
                              : isView
                                ? ['list', 'detail']
                                : [];

                            const relatedPermissionIds = groupedPermissionsAll[subject]
                              ?.filter((p) => relatedPermissions.includes(p.action) && p.actionScope === permission.actionScope)
                              .map((p) => p._id) || [];

                            const allRelatedChecked = relatedPermissionIds.every((id) => selectedPermissionIds.includes(id));
                            const someRelatedChecked = relatedPermissionIds.some((id) => selectedPermissionIds.includes(id));
                            const checkState = isManage || isView ? (allRelatedChecked ? true : someRelatedChecked ? 'indeterminate' : false) : selectedPermissionIds.includes(permission._id);

                            return (
                              <FormControlLabel
                                key={permission._id}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                control={
                                  <Checkbox
                                    checked={checkState === true}
                                    indeterminate={checkState === 'indeterminate'}
                                    onChange={() => {
                                      let newSelectedPermissions = [...selectedPermissionIds];

                                      if (checkState === true) {
                                        newSelectedPermissions = newSelectedPermissions.filter((id) => id !== permission._id && !relatedPermissionIds.includes(id));
                                      } else {
                                        newSelectedPermissions = [...new Set([...newSelectedPermissions, permission._id, ...relatedPermissionIds])];
                                      }

                                      setSelectedPermissionIds(newSelectedPermissions);
                                    }}
                                  />
                                }
                                label={`${permission.action} (dep)`}
                              />
                            );
                          })}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="row" spacing={4}>
                    <Stack width={"150px"}>
                      {groupedPermissionsAll[subject]?.filter((permission) => permission.actionScope === 'org').map((permission) => (
                        <FormControlLabel
                          key={permission._id}
                          control={
                            <Checkbox
                              checked={selectedPermissionIds.includes(permission._id)}
                              onChange={() => {
                                const isSelected = selectedPermissionIds.includes(permission._id);
                                let newSelectedPermissions = isSelected
                                  ? selectedPermissionIds.filter((id) => id !== permission._id)
                                  : [...selectedPermissionIds, permission._id];

                                setSelectedPermissionIds(newSelectedPermissions);
                              }}
                            />
                          }
                          label={`${permission.action} (org)`}
                        />
                      ))}
                    </Stack>
                    <Stack width={"150px"}>
                      {groupedPermissionsAll[subject]?.filter((permission) => permission.actionScope === 'dep').map((permission) => (
                        <FormControlLabel
                          key={permission._id}
                          control={
                            <Checkbox
                              checked={selectedPermissionIds.includes(permission._id)}
                              onChange={() => {
                                const isSelected = selectedPermissionIds.includes(permission._id);
                                let newSelectedPermissions = isSelected
                                  ? selectedPermissionIds.filter((id) => id !== permission._id)
                                  : [...selectedPermissionIds, permission._id];

                                setSelectedPermissionIds(newSelectedPermissions);
                              }}
                            />
                          }
                          label={`${permission.action} (dep)`}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}

          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
