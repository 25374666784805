const locale = {
  organization: "Organizasyon",
  organizations: "Organizasyonlar",
  organizationChart: "Organizasyon Şeması",
  update: "Güncelle",
  delete: "Sil",
  view: "Görüntüle",
  list: "Liste",
  createdSuccessfully: "Organizasyon başarıyla oluşturuldu",
  updatedSuccessfully: "Organizasyon başarıyla güncellendi",
  deleteConfirm: "Silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Organizasyon başarıyla silindi",
  info: "Bu bölümden ekibinize kullanıcılar ekleyebilirsiniz.",
  name: "Ad",
  email: "E-posta",
  surname: "Soyad",
  viewProfile: "Profili Görüntüle",
  roles: "Roller",
  editChart: "Şemayı Düzenle",
  save: "Kaydet",
  editOrganizationChart: "Organizasyon Şemasını Düzenle",
  editOrganizationDetail: "Kullanıcıları istediğiniz konuma sürükleyip bırakın.",
  createRole: "Rol Oluştur",
  userList: "Kullanıcı Listesi",
  rolesInfo: "Roller oluşturabilir ve bunlara izinler atayabilirsiniz.",
  createUserSuccess: "Kullanıcı başarıyla oluşturuldu",
  chart: "Şema",
  employees: "Çalışanlar",
  employee: "Çalışan",
  create: "Oluştur",
  new: "Yeni",
  roleName: "Rol Adı",
  addRole: "Ekle",
  updateRole: "Güncelle",
  deleteRole: "Sil",
  roleCreatedSuccess: "Rol başarıyla oluşturuldu",
  roleUpdatedSuccess: "Rol başarıyla güncellendi",
  roleDeletedSuccess: "Rol başarıyla silindi",
  errorFetchingPermissions: "İzinler getirilirken hata oluştu",
  errorFetchingRoles: "Roller getirilirken hata oluştu",
  errorCreatingRole: "Rol oluşturulurken hata oluştu",
  errorUpdatingRole: "Rol güncellenirken hata oluştu",
  errorDeletingRole: "Rol silinirken hata oluştu",
  subject: "Konu",
  capabilities: "Yetkinlikler",
  allPermissions: "Tüm İzinler",
  all: "Tümü",
  advancedOptions: "Gelişmiş Seçenekler",
  id: "Kimlik",
  emailAddress: "E-posta Adresi",
  status: "Durum",
  emailVerified: "E-posta Doğrulandı",
  phoneNumber: "Telefon Numarası",
  country: "Ülke",
  city: "Şehir",
  lastLoginDate: "Son Giriş Tarihi",
  createdAt: "Oluşturulma Tarihi",
  actions: "İşlemler",
  addNewRole: "Yeni Rol Ekle",
  yes: "Evet",
  no: "Hayır",
  details: "Detaylar",
  jobTitle: "İş Ünvanı",
  deleteMessage: "Bu kullanıcıyı silmek istediğinize emin misiniz?",
  cancel: "İptal",
  userDeleteSuccess: "Kullanıcı başarıyla silindi!",
  userDeleteError: "Kullanıcı silinirken bir hata oluştu",
  updateChartSuccessfully: "Organizasyon şeması başarıyla güncellendi!",
  address: "Adres",
  birthDate: "Doğum Tarihi",
  gender: "Cinsiyet",
  language: "Dil",
  description: "Açıklama",
  rolesAndPermissions: "Roller & İzinler",
  ownPermissionsAlert: "Kendi izinlerinizi değiştiremezsiniz.",
  zoom: "Yakınlaştır",
  tree: "Ağaç Görünümü",
  listView: "Liste Görünümü",
  createSuccess: "Organizasyon başarıyla oluşturuldu",
  updateSuccess: "Organizasyon başarıyla güncellendi",
  updateError: "Organizasyon güncellenirken hata oluştu",
  fetchError: "Organizasyon getirilirken hata oluştu",
  fetchSuccess: "Organizasyon başarıyla getirildi",
  undefinedUser: "Tanımsız Kullanıcı",
  chartUpdateSuccess: "Şema başarıyla güncellendi!",
  actionScope: "Eylem Kapsamı",
  action: "Eylem",
  userDetail: "Kullanıcı Detayı",
};

export default locale;
