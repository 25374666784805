/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Grid, Stack } from "@mui/material";
import { useAppDispatch } from "context";
import { leadActions } from "../../../context";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { listLeadRoute } from "../../../routes/lead.base.route";

const View = () => {
  // general
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id)
  if (!id) {
    navigate(listLeadRoute());
  }

  useEffect(() => {
    dispatch(leadActions.setTitle(t("crm.lead.lead.view")));
    dispatch(leadActions.setBackButton(true));
    dispatch(leadActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {false ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            {/*
              // TODO: add field views
            */}
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
