

import { ExpensesDomainLayout } from "../layouts";
import { ExpensesRouter } from "../subdomains/expenses/routes/ExpensesRouter";
import { expensesDomainRoutes } from "./expenses.base.route";
import { Route, Routes } from "react-router-dom";

export const ExpensesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ExpensesDomainLayout />}>
        <Route index element={<ExpensesRouter />} />
        <Route
          path={`${expensesDomainRoutes.subdomains.expenses.base}/*`}
          element={<ExpensesDomainRouter />}
        />
        {/* Todo 
         <Route index element={<EmployeesRouter />} />
         <Route
          path={`${customerDomainRoutes.subdomains.employees.base}/*`}
          element={<EmployeesRouter />}
        /> */}
      </Route>
    </Routes>
  );
};
