import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Box, Stack, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

export function Slider() {
    const { t } = useTranslation();

    return (
        <Swiper
            pagination={{
                dynamicBullets: true,
            }}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            loop={true}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
        >
            {[1, 2, 3].map((item, index) => (
                <SwiperSlide key={index}>
                    <Box bgcolor={"secondary.main"} p={4} borderRadius={4} mr={1} ml={1}>
                        <Typography fontSize={16} fontWeight={400} textAlign={"left"} color={"#fff"}>
                            {t("auth.manageYourBusiness")}
                        </Typography>
                        <Stack direction={"row"} mt={2} alignItems={"center"}>
                            <img src="https://picsum.photos/100/100" alt={t("auth.logoAlt")} style={{ width:"50px", height: "50px", borderRadius: "50px" }} />
                            <Stack direction={"column"} ml={1} alignItems={"flex-start"}>
                                <Typography fontSize={14} fontWeight={600} color={"#fff"}>
                                    {t("auth.johnDoe")}
                                </Typography>
                                <Typography fontSize={12} fontWeight={400} color={"#fff"}>
                                    {t("auth.ceoCompany")}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
