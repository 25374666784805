const locale = {
  lead: "Lead",
  leads: "Leads",
  create: "Create Lead",
  update: "Update Lead",
  delete: "Delete",
  view: "View Lead",
  list: "List Leads",
  createdSuccessfully: "Lead created successfully",
  updatedSuccessfully: "Lead updated successfully",
  deleteConfirm: "Are you sure you want to delete? This action cannot be undone.",
  deletedSuccessfully: "Lead deleted successfully",
  createInfo: "You can create new leads here.",
  fullName: "Full Name",
  status: "Status",
  source: "Source",
  tags: "Tags",
  assigneds: "Assigneds",
  position: "Position",
  email: "Email",
  website: "Website",
  phoneNumber: "Phone Number",
  company: "Company",
  sector: "Sector",
  type: "Type",
  qualification: "Qualification",
  country: "Country",
  state: "State",
  city: "City",
  justOwn: "Just Own",
  isJunk: "Is Junk",
  note: "Note",
  language: "Language",
  listInfo: "List of all leads.",
  importedError: "Imported error",
  importedSuccessfully: "Imported successfully",
  downloadFormError: "Download form error",
  downloadFormSuccessfully: "Download form successfully",
  downloadExampleFormSuccessfully: "Download example form successfully",
  downloadExampleFormError: "Download example form error",
  title: "List Leads",
  emptyState1: "No leads found here.",
  emptyState2: "You can create or import leads immediately.",
  createLead: "Create Lead",
  importLead: "Import Lead",
  importTitle: "Import Leads",
  downloadExample: "Download Example",
  importText1: "You can import leads from this page.",
  importText2: "You can use the sample form below to publish your leads.",
  importExport: "Import/Export",
  exportData: "Export Data",
  importData: "Import Data",
  b2b: "B2B",
  b2c: "B2C",
  viewLead: "View",
  edit: "Edit",
  junk: "Junk",
  unJunk: "Un-Junk",
  addNewRole: "Add New Role",
  actions: "Actions"
};

export default locale;
