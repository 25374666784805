import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseLeadRoute,
  listFormsRoute,
  listLeadRoute,
  listStatusesRoute,
  listSourcesRoute
} from "../routes/lead.base.route";

export const useLeadDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("Lead"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseLeadRoute(),
    children: [
      {
        title: t("Leads"),
        path: listLeadRoute(),
      },
      {
        title: t("leadForms"),
        path: listFormsRoute(),
      },
      {
        title: t("leadStatuses"),
        path: listStatusesRoute(),
      },
      {
        title: t("leadSources"),
        path: listSourcesRoute(),
      }
      // TODO: import subdomain menu items
    ],
  };
};
